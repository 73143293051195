import React, { useMemo } from 'react'
import { getById, getImgByTitle } from '@apps/utils/contentful'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const Toast = ({ type, text, className, imgSize, textClassName, testId }) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      check: getImgByTitle(commonElements, 'check')?.url,
      error: getImgByTitle(commonElements, 'error')?.url
    }
  }, [pageContent, selectedLanguage])

  const ImageType = {
    success: content?.check,
    danger: content?.error
  }

  return (
    <div
      className={`px-6 py-3 text-lg text-white rounded-lg flex flex-row items-start ${className}`}
      data-testid={testId}
    >
      <img
        className={`mr-3 fill-current`}
        alt="Toast image"
        width={imgSize}
        height={imgSize}
        src={ImageType[type]}
      />
      <p className={`${textClassName}`}>{text}</p>
    </div>
  )
}

Toast.propTypes = {
  type: PropTypes.oneOf(['success', 'danger']),
  text: PropTypes.string.isRequired,
  testId: PropTypes.string
}

Toast.defaultProps = {
  type: 'success',
  className: '',
  imgSize: 24
}

export default Toast
