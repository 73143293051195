import React, { useMemo } from 'react'
import { getById, getImgByTitle, getBySlug } from '@apps/utils/contentful'
import { useSelector } from 'react-redux'
import Heading1 from '@apps/components/Typography/Heading1'
import BodyLarge from '@apps/components/Typography/BodyLarge'
import Body from '@apps/components/Typography/Body'
import Button from '@apps/components/Button'

const ErrorSection = ({ errorNum = null }) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    if (!pageContent) return {}
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    const { shortTextFields } = commonElements
    return {
      errorImage: getImgByTitle(commonElements, 'errorImage')?.url,
      errorHeading: getBySlug(shortTextFields, 'errorHeading')?.value,
      errorBody: getBySlug(shortTextFields, 'errorBody')?.value,
      errorCode: getBySlug(shortTextFields, 'errorCode')?.value,
      errorCTATitle: getBySlug(shortTextFields, 'errorCTATitle')?.value,
      errorCTALink: getBySlug(shortTextFields, 'errorCTALink')?.value
    }
  }, [pageContent, selectedLanguage])

  const { errorImage, errorHeading, errorBody, errorCode, errorCTATitle, errorCTALink } = content

  return (
    <div>
      <div className="flex flex-col max-w-6xl px-4 pb-12 m-auto space-y-6">
        <Heading1 className="mt-8">{errorHeading}</Heading1>
        <div className="flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 pb-8">
          <div className="space-y-2 pr-6">
            <BodyLarge>{errorBody}</BodyLarge>
            {errorNum && (
              <Body>
                {errorCode} {errorNum}
              </Body>
            )}
          </div>
          <div>
            <a href={`https://www.${errorCTALink}`}>
              <Button className="w-max" label={`${errorCTATitle} ${errorCTALink}`} testId="" />
            </a>
          </div>
        </div>
        <div style={{ width: '645px', maxWidth: '100%' }}>
          <img className="w-full" src={errorImage} />
        </div>
      </div>
    </div>
  )
}

export default ErrorSection
