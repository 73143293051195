import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import useSite from '@engine/hooks/useSite'
import Sites from '@sites'
import ConsumerActivationSiteMounts from '@sites/consumer/siteMounts'
import DealerActivationSiteMounts from '@sites/dealer/siteMounts'

// setup our initial state for our context
const initialState = {}

// create our context
const mounts = createContext(initialState)

// extract the provider
const { Provider } = mounts

const MountsProvider = ({ children }) => {
  const site = useSite()
  let siteMounts

  // TODO: consumer and dealer are fine to switch on now, when site -> application are
  // TODO: one-to-one, but when adding WSC, etc, better parsing will be needed

  switch (site) {
    case Sites.consumer:
      siteMounts = ConsumerActivationSiteMounts
      break
    case Sites.dealer:
      siteMounts = DealerActivationSiteMounts
      break
    default:
      siteMounts = undefined
      break
  }

  return <Provider value={siteMounts}>{children}</Provider>
}

MountsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

// expose the Provider HOC and context
export { mounts, MountsProvider }
