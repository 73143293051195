import { configureStore } from '@reduxjs/toolkit'

import commonReducer from "./features/CommonSlice"
import plansReducer from "./features/PlansSlice"
import accountSetupReducer from "./features/AccountSetupSlice"
import simSelectionReducer from "./features/SimSelectionSlice"
import payAndReviewReducer from "./features/PayAndReviewSlice"
import phoneNumberReducer from "./features/PhoneNumberSlice"

export default configureStore({
  reducer: {
    common: commonReducer,
    plansPage: plansReducer,
    accountSetupPage: accountSetupReducer,
    simSelectionPage: simSelectionReducer,
    payAndReviewPage: payAndReviewReducer,
    phoneNumberPage: phoneNumberReducer
  }
})
