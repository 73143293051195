import Contentfully from './clients/ContentfullyClient'
import Tracker from "@telus/contentful-tracking"

import config from '@platformConfig/index'

const { contentful } = config

const APPLICATION_NAME = "commerce-public-mobile-mvne-web"
const tracker = new Tracker(contentful?.spaceId, APPLICATION_NAME)

const findProv = (provinceList, selectedProv) => {
  return provinceList.find(pr => pr.toUpperCase() === selectedProv?.toUpperCase())
}

const transformPlan = (items, selectedProv) => {
  let updatedPlans = []
  let altDisplayFeaturedPlan = false
  if (items && items.length === 1) {
    const { plans, displayFeaturedPlan } = items[0]
    if (displayFeaturedPlan) {
      altDisplayFeaturedPlan = displayFeaturedPlan
    }

    const filteredPlans = plans.filter(plan => !plan.province || findProv(plan.province, selectedProv))
    
    if (filteredPlans) {
      updatedPlans = filteredPlans
        .sort((a, b) => a.value - b.value)
        .map((item) => {
          const {
            id,
            guid,
            name,
            sluggedName,
            subtitle,
            description,
            value,
            features,
            options,
            display,
            network,
            portal,
            duration = '30',
            ltpSavings: longTermPlanSavings = '',
            promo: { description: promoText } = ''
          } = item
          const formattedOptions = options.map((option) => option.id)

          return {
            id,
            guid,
            name,
            sluggedName,
            subtitle,
            description,
            value,
            features,
            options: formattedOptions,
            display,
            network,
            portal,
            promo: promoText,
            duration,
            longTermPlanSavings
          }
        })
    }
  }
  return { plans: updatedPlans, displayFeaturedPlan: altDisplayFeaturedPlan }
}

const transformConsumerPages = (content = {}) => {
  const { pages = [] } = content
  return {
    landingPage: pages.find((item) => item.id === 'landingPage'),
    accountSetupPage: pages.find((item) => item.id === 'accountSetupPageConsumer'),
    phoneNumberPage: pages.find((item) => item.id === 'phoneNumberPageConsumer'),
    plansPage: pages.find((item) => item.id === 'plansPageConsumer'),
    paymentPage: pages.find((item) => item.id === 'paymentPageConsumer'),
    commonElements: pages.find((item) => item.id === 'commonElements'),
    haveSimPage: pages.find((item) => item.id === 'haveSimPage'),
    transactionSummaryPage: pages.find((item) => item.id === 'transactionSummaryPage')
  }
}

const transformDealerPages = (content = {}) => {
  const { pages = [] } = content
  return {
    landingPage: pages.find((item) => item.id === 'landingPage'),
    accountSetupPage: pages.find((item) => item.id === 'accountSetupPageDealer'),
    phoneNumberPage: pages.find((item) => item.id === 'phoneNumberPageDealer'),
    plansPage: pages.find((item) => item.id === 'plansPageDealer'),
    paymentPage: pages.find((item) => item.id === 'paymentPageDealer'),
    commonElements: pages.find((item) => item.id === 'commonElements'),
    haveSimPage: pages.find((item) => item.id === 'haveSimPage'),
    transactionSummaryPage: pages.find((item) => item.id === 'transactionSummaryPage')
  }
}

export default {
  getSplashDealer: async () => {
    const response = await Contentfully.getModels({
      content_type: 'featureFlags',
      locale: '*',
      'fields.id': 'Activation - Dealer'
    })
    tracker?.fullyTracker(response)
    return response.items['en-CA'][0].showSplashing
  },
  getSplashConsumer: async () => {
    const response = await Contentfully.getModels({
      content_type: 'featureFlags',
      locale: '*',
      'fields.id': 'Activation - Consumer'
    })
    tracker?.fullyTracker(response)
    return response.items['en-CA'][0].showSplashing
  },
  getPaymentGateway: async () => {
    const response = await Contentfully.getModels({
      content_type: 'featureFlags',
      locale: '*',
      'fields.id': 'Activation - Consumer'
    })
    tracker?.fullyTracker(response)
    return response.items['en-CA'][0].paymentGateway
  },
  getPaymentConfigCustomer: async () => {
    const response = await Contentfully.getModels({
      content_type: 'featureFlags',
      locale: '*',
      'fields.id': 'Activation - Consumer'
    })
    tracker?.fullyTracker(response)
    return response.items['en-CA'][0]
  },
  getPaymentConfigDealer: async () => {
    const response = await Contentfully.getModels({
      content_type: 'featureFlags',
      locale: '*',
      'fields.id': 'Activation - Dealer'
    })
    tracker?.fullyTracker(response)
    return response.items['en-CA'][0]
  },
  getKPREPlans: async (language, selectedProv) => {
    const response = await Contentfully.getModels({
      content_type: 'planList',
      locale: '*',
      'fields.id': 'kpre-activation'
    })
    const {
      items: { 'en-CA': en, 'fr-CA': fr }
    } = response
    tracker?.fullyTracker(response)
    return {
      en: transformPlan(en, selectedProv),
      fr: transformPlan(fr, selectedProv)
    }
  },

  getPageContent: async (page, language) => {
    const response = await Contentfully.getModels({
      content_type: 'pageContent',
      locale: `${language}-CA`,
      'fields.id': page
    })
    tracker?.fullyTracker(response)
    return response
  },

  getConsumerPageContent: async () => {
    const response = await Contentfully.getModels({
      content_type: 'consumerPageContent',
      locale: '*',
      'fields.id': 'main'
    })

    const {
      items: { 'en-CA': en = [undefined], 'fr-CA': fr = [undefined] }
    } = response

    tracker?.fullyTracker(response)

    return {
      en: transformConsumerPages(en[0]),
      fr: transformConsumerPages(fr[0])
    }
  },

  getDealerPageContent: async () => {
    const response = await Contentfully.getModels({
      content_type: 'dealerPageContent',
      locale: '*',
      'fields.id': 'main'
    })

    const {
      items: { 'en-CA': en = [undefined], 'fr-CA': fr = [undefined] }
    } = response

    tracker?.fullyTracker(response)

    return {
      en: transformDealerPages(en[0]),
      fr: transformDealerPages(fr[0])
    }
  },
  getRAFSettings: async () => {
    const { items: [kpreRAFSettings = {}] = [] } = await Contentfully.getModels({
      content_type: 'rafSettings',
      'fields.id': 'kpre-rewards'
    }) || {}

    const {
      launchDate, maxUsageAllowed, shouldConsiderLaunchDate
    } = kpreRAFSettings
    tracker?.fullyTracker({items: [kpreRAFSettings]})

    return {
      launchDate, maxUsageAllowed, shouldConsiderLaunchDate
    }
  }
}
