/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { PLAN_DURATION } from '@apps/utils/constants'

export const PLANS_STATE = {
  ALL_PLANS: 'allPlans',
  LOCALIZED_PLAN_ITEM: 'localizedPlanItem',
  PLAN: 'plan',
  PROMO: 'promo',
  SELECTED_PLAN_OPTION: 'selectPlanOption',
  PRE_SELECTED_PLAN: 'preSelectedPlan'
}

export const defaultPlan = {
  id: undefined,
  name: 'name',
  subtitle: 'subtitle',
  value: 0,
  duration: 30,
  features: ['item', 'item2', 'item3'],
  options: []
}

export const SelectedPlan = (state) => {
  return state?.allPlans?.find(
    (item) => item && state[PLANS_STATE.PLAN] && item?.id === state[PLANS_STATE.PLAN]?.id
  )
}

export const plansPage = createSlice({
  name: 'plansPage',
  initialState: {
    [PLANS_STATE.ALL_PLANS]: [],
    [PLANS_STATE.LOCALIZED_PLAN_ITEM]: {},
    [PLANS_STATE.PLAN]: defaultPlan, 
    [PLANS_STATE.PROMO]: null,
    [PLANS_STATE.SELECTED_PLAN_OPTION]: PLAN_DURATION.SLOT_30,
    [PLANS_STATE.PRE_SELECTED_PLAN]: false
  },
  reducers: {
    loadAllPlans: (state, action) => {
      state.allPlans = action.payload
    },
    loadLocalizedPlanItem: (state, action) => {
      state.localizedPlanItem = action.payload
    },
    setPlanOption: (state, action) => {
      state.selectedPlanOption = action.payload
    },
    updatePlansState: (state, action) => {
      const newState = Object.assign(state, action.payload)
      state = {
        ...newState
      }
    }
  }
})

// this is for dispatch
export const {
  loadAllPlans,
  loadLocalizedPlanItem,
  setPlanOption,
  updatePlansState
} = plansPage.actions

// this is for configureStore
export default plansPage.reducer
