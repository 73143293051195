export const setPageAnalytics = (location, type, province, language) => {
  // Send off analytics data to Adobe Data Layer
  if (typeof window !== 'undefined') {
    window.dataLayer = {
      page: {
        name: formatPageName(location),
        type,
        category: 'prepaid',
        categorysub1: formatCategorySub(location),
        province,
        language,
        section: 'prepaid'
      },
      user: {
        customertype: getCustomerType(location)
      }
    }
  }
}

// helpers
/**
 * Formats the page name for use in analytics using location object.
 *
 * @param {object} location - location object used to format page name
 * @returns {string} - the formatted page name
 */
function formatPageName(location) {
  // /en/on/koodo/dealer/activation/transaction-summary > /prepaid/dealer/activation/transaction-summary
  const { pathname = '' } = location
  const pageName = pathname.split('/').splice(5).join('/')
  return `prepaid/${pageName}`
}

function formatCategorySub(location) {
  const { pathname = '' } = location
  return pathname.substring(pathname.lastIndexOf('/') + 1)
}

function getCustomerType(location) {
  const { pathname = '' } = location
  const type = pathname.split('/')[5] || null
  return type === 'dealer' ? 'dealer' : 'consumer'
}
