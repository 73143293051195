import React, { useMemo } from 'react'
import { getById, getImgByTitle } from '@apps/utils/contentful'
import { useSelector } from 'react-redux'
import BodySmallBold from '../Typography/BodySmallBold'

const ValidationCard = ({
  children,
  title,
  visibility,
  customImageStyles
}) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      koodoRSmallCuts: getImgByTitle(commonElements, 'koodoRSmallCuts')?.url
    }
  }, [pageContent, selectedLanguage])

  return (
    <div className={`relative w-full ${visibility}`}>
      <div className="p-3 text-sm text-black rounded bg-card-background lg:p-6">
        {title && <BodySmallBold styles="mb-1">{title}</BodySmallBold>}
        {children}
      </div>
      {content?.koodoRSmallCuts && (
      <img
        className={`${customImageStyles} absolute bottom-0 right-0 mx-auto my-0`}
        src={content?.koodoRSmallCuts}
        alt="Koodo cuts"
      />
      )}
    </div>
  )
}

export default ValidationCard
