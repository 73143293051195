import { SIM_SELECTION_STATE } from '@apps/redux/features/SimSelectionSlice'
import {
  MAX_INPUT_LENGTH,
  sanitizeTextField,
  isDigitOrEmpty,
  formatSimField
} from '@apps/utils/formHelpers'

export const SIM_SELECTION_EVENTS = {
  SIM: 'event.target.simSelection.sim'
}

export default {
  [SIM_SELECTION_EVENTS.SIM]: {
    onChange: (value, updateSimSelectionState) => {
      const sanitizedValue = sanitizeTextField(value)

      if (isDigitOrEmpty(sanitizedValue)) {
        const { formattedSim, simArr } = formatSimField(sanitizedValue)
        if (simArr && simArr.length > 0) {
          updateSimSelectionState({
            [SIM_SELECTION_STATE.SIM]: formattedSim
          })
        } else {
          updateSimSelectionState({
            [SIM_SELECTION_STATE.SIM]: ''
          })
        }
      }
    },
    onBlur: async (state, isSimValid, setLoading, errors, updateSimSelectionState) => {
      let error = null
      if (state[SIM_SELECTION_STATE.SIM] === '') {
        error = errors.simRequiredError
      } else if (state[SIM_SELECTION_STATE.SIM].length !== MAX_INPUT_LENGTH) {
        error = errors.simLengthError
      } else if (state[SIM_SELECTION_STATE.SIM].length === MAX_INPUT_LENGTH) {
        setLoading(true)
        // perform service call
        const result = await isSimValid(state[SIM_SELECTION_STATE.SIM]).catch(() => {
          return undefined
        })
        if (!result) {
          error = errors.simUnavailableError
        } else {
          error = null
        }
        setLoading(false)
      } else {
        error = null
      }
      updateSimSelectionState({
        [SIM_SELECTION_STATE.SIM_ERROR]: error
      })
      return error !== null
    }
  }
}
