/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'

const BodySmallBold = ({ id, children, className, testId, textColor }) => {
  return (
    <span
      className={`text-xs font-h1 tracking-wider ${textColor || 'text-copy'} ${className}`}
      id={id}
      data-testid={testId}
    >
      {children}
    </span>
  )
}

BodySmallBold.propTypes = {
  id: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  testId: PropTypes.string
}

export default BodySmallBold
