let contentfulKey
let contentfulSpace
let saltUrl
let merchantId
let paymentTokenValue
let EPSscriptURL
let isLocalTesting
let siteKey

if (Object.keys(process.env).length === 0 || process.env.BROWSER) {
  contentfulKey = window.__SERVER_DATA_.KPREAP_CONTENTFUL_KEY || ''
  contentfulSpace = window?.__SERVER_DATA_?.KPREAP_CONTENTFUL_SPACE || ''
  saltUrl = window?.__SERVER_DATA_?.KPREAP_SALT_URL || ''
  merchantId = window?.__SERVER_DATA_?.KPREAP_SALT_MERCHANT || ''
  paymentTokenValue = window?.__SERVER_DATA_?.KPREAP_SALT_TOKEN || ''
  EPSscriptURL = window?.__SERVER_DATA_?.EPS_SCRIPT_URL || ''
  isLocalTesting = window?.__SERVER_DATA_?.LOCAL_TESTING || ''
  siteKey =  window?.__SERVER_DATA_?.RECAPTCHA_SITE_KEY || ''
} else {
  // This will only happen on the server side
  contentfulKey = process.env.KPREAP_CONTENTFUL_KEY || ''
  contentfulSpace = process?.env?.KPREAP_CONTENTFUL_SPACE || ''
  saltUrl = process?.env?.KPREAP_SALT_URL || ''
  merchantId = process?.env?.KPREAP_SALT_MERCHANT || ''
  paymentTokenValue = process?.env?.KPREAP_SALT_TOKEN || ''
  EPSscriptURL = process?.env?.EPS_SCRIPT_URL || ''
  isLocalTesting = process?.env?.LOCAL_TESTING || ''
  siteKey =  process?.env?.RECAPTCHA_SITE_KEY || ''
}

const contentful = {
  accessToken: contentfulKey,
  spaceId: contentfulSpace,
  environmentId: 'development'
}

const googleReCaptchaConfig = {
  siteUrl:'https://www.google.com/recaptcha/api/siteverify',
  siteKey
}

let backendUrl =
'https://publicmobile-public-mobile-mvne-web-pasta.np.gke.telus.digital'

if (isLocalTesting === 'ON') {
  backendUrl = 'http://local.publicmobile.ca:3000'
}

const partnerCookieId = 'partnerCache'

const cookieSecure = false

const tagManager =
  '//assets.adobedtm.com/a2962fe01e14/ec556f4805a8/launch-d3ffc97e9ec8-staging.min.js'

const paymentConfig = {
  registrationUrl: saltUrl,
  merchantId
}

const paymentToken = paymentTokenValue

export default {
  contentful,
  backendUrl,
  partnerCookieId,
  tagManager,
  paymentConfig,
  EPSscriptURL,
  paymentToken,
  cookieSecure,
  googleReCaptchaConfig
}
