import React from 'react'

const NextStepsCard = ({ children, backgroundImage }) => {
  return (
    <div
      className="py-42 px-8 bg-blue-cyan bg-bottom md:bg-right-top"
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }}
    >
      {children}
    </div>
  )
}

export default NextStepsCard
