/* eslint-disable react/require-default-props */
// imports
import React, { useMemo } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import classnames from 'classnames'
import Link from '@/apps/components/Link'
import PropTypes from 'prop-types'

// component
export function RichText(props) {
  // extract props
  const { config = {}, children, className } = props

  // get render options
  const renderOptions = useMemo(() => {
    return getRenderOptions(config)
  }, [config])

  // render
  return <div className={className}>{documentToReactComponents(children, renderOptions)}</div>
}

// helpers
function getRenderOptions(config = {}) {
  return {
    renderMark: {
      [MARKS.BOLD]: (text) => {
        const { className } = config[MARKS.BOLD] || ''
        return <b className={classnames('font-h1', className)}>{text}</b>
      },
      [MARKS.ITALIC]: (text) => {
        const { className } = config[MARKS.ITALIC] || ''
        return <em className={classnames('italic', className)}>{text}</em>
      }
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const { className } = config[BLOCKS.PARAGRAPH] || ''
        return <p className={className || 'text-copy text-sm font-body leading-6'}>{children}</p>
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        const { className } = config[BLOCKS.HEADING_1] || ''
        return <h1 className={className || 'heading-1 mb-4'}>{children}</h1>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const { className } = config[BLOCKS.HEADING_2] || ''
        return <h2 className={className || 'heading-2 mb-4 mt-16'}>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        const { className } = config[BLOCKS.HEADING_3] || ''
        return <h3 className={className || 'heading-3 mb-4 mt-8'}>{children}</h3>
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        const { className } = config[BLOCKS.HEADING_4]
        return <h4 className={className || 'heading-4 my-4'}>{children}</h4>
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        const { className } = config[BLOCKS.HEADING_5] || ''
        return <h5 className={className || 'heading-5 my-4'}>{children}</h5>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        const { className } = config[BLOCKS.UL_LIST] || ''
        return <ul className={className || "ml-4 text-copy list-disc list-outside2"}>
          {children}
        </ul>
          },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const { className } = config[BLOCKS.LIST_ITEM] || ''
        return < >
          {children.map((child) => (
            <li className={className || 'text-sm leading-6 font-body'} key={child.key}>{child.props.children}</li>
          ))}
        </>
      },
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="ml-4 text-copy font-body text-sm list-decimal list-outside2 space-y-2">
          {children}
        </ol>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const { className } = config[INLINES.HYPERLINK] || ''
        return (
          <Link target="_blank" href={node.data.uri} className={className || "break-all font-h1"}>
            {children}
          </Link>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // resolve asset data
        const { description, url } = node.data

        return <img className="mb-4" src={url} alt={description} />
      }
      // [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      //   // resolve video entry data
      //   const { id, thumbnail, videoLength } = node.data
      //
      //   const { onStart } = config[BLOCKS.EMBEDDED_ENTRY] || {}
      //   return (
      //     <Video
      //       defaultVolume={100}
      //       onStart={() => {
      //         if (onStart) {
      //           onStart()
      //         }
      //       }}
      //       posterSrc={thumbnail.url}
      //       videoId={id}
      //       videoLength={videoLength}
      //       copy={locale}
      //     />
      //   )
      // }
    }
  }
}

export default RichText

// prop types
RichText.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
  children: PropTypes.object.isRequired
}
