import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Name from '@apps/components/Name'
import SelfServeHeader from '@/apps/components/SelfServeHeader'
import Heading2 from '@apps/components/Typography/Heading2'
import PIN from '@apps/components/PIN'
import PreferredLanguage from '@apps/components/PreferredLanguage'
import Button from '@apps/components/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  ACCOUNT_SETUP_STATE,
  updateAccountSetupState,
  updateAccountSetupPinErrorState
} from '@apps/redux/features/AccountSetupSlice'
import AccountSetupChangeHandlers, {
  ACCOUNT_SETUP_EVENTS
} from '@apps/utils/ChangeHandlers/AccountSetupChangeHandlers'
import { logIn } from '@apps/redux/features/CommonSlice'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { setPageAnalytics } from '@apps/utils/Analytics'
import {
  APPLICATION_STATE,
  ApplicationContext,
  setLoggedInUser,
  setLandingLogicLoader
} from '@apps/contexts/ApplicationContext'
import ContentService from '@services/ContentService'
import { PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import { getShortTextFromPage, getById } from '@apps/utils/contentful'
import { LANGUAGES_CODE, SIM_OPTIONS } from '@apps/utils/constants'
import Sites from '@sites/index'
import { isMobile } from 'react-device-detect'

const AccountSetupPage = () => {
  const dispatch = useDispatch()
  const { pageContent, featureFlags } = useSelector((state) => state.common.content)
  const {
    userType,
    user: { email }
  } = useSelector((state) => state.common)
  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region,
    [APPLICATION_STATE.SERVICES]: brandServices
  } = useContext(ApplicationContext)

  const { setPatchQuote } = brandServices

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const text = useMemo(() => {
    if (!pageContent || !language || !userType) return {}
    const contentSuffix = isDealer ? 'Dealer' : 'Consumer'
    const accountSetupPageContent = getById(pageContent, `accountSetupPage${contentSuffix}`)?.[
      language
    ]
    return {
      accountSetupHeader: getShortTextFromPage(accountSetupPageContent, 'accountSetupHeader'),
      emailInUse: getShortTextFromPage(accountSetupPageContent, 'emailInUse'),
      emailInvalid: getShortTextFromPage(accountSetupPageContent, 'emailInvalid'),
      emailRequired: getShortTextFromPage(accountSetupPageContent, 'emailRequired'),
      firstNameInvalid: getShortTextFromPage(accountSetupPageContent, 'firstNameInvalid'),
      firstNameRequired: getShortTextFromPage(accountSetupPageContent, 'firstNameRequired'),
      lastNameInvalid: getShortTextFromPage(accountSetupPageContent, 'lastNameInvalid'),
      lastNameRequired: getShortTextFromPage(accountSetupPageContent, 'lastNameRequired'),
      nextStepLabel: getShortTextFromPage(accountSetupPageContent, 'nextStepLabel'),
      phoneNumber: getShortTextFromPage(accountSetupPageContent, 'phoneNumber'),
      postalCodeInvalid: getShortTextFromPage(accountSetupPageContent, 'postalCodeInvalid'),
      postalCodeRequired: getShortTextFromPage(accountSetupPageContent, 'postalCodeRequired'),
      provinceInvalid: getShortTextFromPage(accountSetupPageContent, 'provinceInvalid'),
      provinceRequired: getShortTextFromPage(accountSetupPageContent, 'provinceRequired'),
      securityAnswerErrorRequired: getShortTextFromPage(
        accountSetupPageContent,
        'securityAnswerErrorRequired'
      ),
      securityQuestionErrorRequired: getShortTextFromPage(
        accountSetupPageContent,
        'securityQuestionErrorRequired'
      ),
      step: getShortTextFromPage(accountSetupPageContent, 'step')
    }
  }, [pageContent, language, userType])

  const {
    accountSetupHeader,
    firstNameRequired,
    lastNameRequired,
    firstNameInvalid,
    lastNameInvalid,
    nextStepLabel
  } = text

  const history = useHistory()

  if (featureFlags && featureFlags[0].showSplashing) {
    history.push(isDealer ? dealerActivationPaths.Splash : consumerActivationPaths.Splash)
  }

  const accountSetupPageState = useSelector((state) => state.accountSetupPage)

  const {
    [ACCOUNT_SETUP_STATE.IS_COMPLETE]: isComplete,
    [ACCOUNT_SETUP_STATE.FIRST_NAME]: firstName,
    [ACCOUNT_SETUP_STATE.LAST_NAME]: lastName,
    [ACCOUNT_SETUP_STATE.PIN]: pin,
    [[ACCOUNT_SETUP_STATE.PIN_ERRORS]]: pinErrors,
    [ACCOUNT_SETUP_STATE.LANGUAGE]: preferredLanguage
  } = accountSetupPageState

  const setInitialPaymentState = async () => {
    const paymentConfig = await ContentService.getPaymentConfigCustomer()

    if (paymentConfig?.paymentGateway) {
      updatePaymentStore({[PAYMENT_STATE.PAYMENT_GATEWAY]: paymentConfig?.paymentGateway})
    }
    if (paymentConfig?.paymentAllowedCountryCodes) {
      updatePaymentStore({
        [PAYMENT_STATE.PAYMENT_ALLOWED_COUNTRY_CODES] :
        paymentConfig?.paymentAllowedCountryCodes
      })
    }
    if (paymentConfig?.paymentcvvResponseValue) {
      updatePaymentStore({[PAYMENT_STATE.PAYMENT_CVV_RESP_VAL]: paymentConfig?.paymentcvvResponseValue})
    }
    if (paymentConfig?.paymentAvsPostalResponse) {
      updatePaymentStore({
        [PAYMENT_STATE.PAYMENT_AVS_POSTAL_RESP_VAL]:
        paymentConfig?.paymentAvsPostalResponse
      })
    }
  }


  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
    setInitialPaymentState()
  }, [])

  useEffect(() => {
    if (userType === Sites.consumer) {
      // TODO: Delete the following line when login flow is setup
      setLoggedInUser(true)
      dispatch(logIn())
    }
  }, [])

  useEffect(() => {
    return history.block( (location, action) => {
      if ( (action === 'pop' || action === 'POP') && accountSetupPageState.isComplete === false) {
        return false
      }

      return () => {
        unblock()
      }

    })
  }, [history, accountSetupPageState.isComplete])

  const nameRef = useRef(null)
  const pinRef = useRef(null)

  const updateQuote = async () => {
    try {
      const fullName = `${firstName} ${lastName}`
      const input = {
        email: email,
        language: preferredLanguage === 0 ? LANGUAGES_CODE.EN : LANGUAGES_CODE.FR,
        fullName: fullName,
        dynamicCode: pin
      }
      const response = await setPatchQuote(input)
      return response
    } catch (err) {
      console.log('ERROR', err)
      return err
    }
  }

  const handleNextClick = async () => {

    try {
      setLandingLogicLoader('IN_PROGRESS')
      if (isComplete) {
        const response = await updateQuote()
        const nextPagePath = isDealer
          ? dealerActivationPaths.SimSelection
          : consumerActivationPaths.SimSelection
  
        const splashPagePath = isDealer
          ? dealerActivationPaths.Splash
          : consumerActivationPaths.Splash
  
        if (response?.data?.accountId) {
          history.push(nextPagePath)
        } else {
          history.push(splashPagePath)
        }
      } else {
        var topError = undefined
  
        if (handleFirstNameBlur() && !topError) {
          topError = nameRef
        }
        if (handleLastNameBlur() && !topError) {
          topError = nameRef
        }
        if (handleBlurPin() && !topError) {
          topError = pinRef
        }
        if (handleBlurConfirmPin() && !topError) {
          topError = pinRef
        }
        if (topError) {
          topError.current.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }

    } finally {
      setLandingLogicLoader(false)
    }
  }

  const setAccountSetupState = (payload) => {
    dispatch(updateAccountSetupState(payload))
  }

  const setAccountSetupPinErrorsState = (payload) => {
    dispatch(updateAccountSetupPinErrorState(payload))
  }

  const handleFirstNameBlur = () => {
    const errorCopy = {
      firstNameRequired,
      firstNameInvalid,
      lastNameRequired,
      lastNameInvalid
    }
    return AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.FIRST_NAME].onBlur(
      accountSetupPageState,
      errorCopy,
      setAccountSetupState
    )
  }

  const handleLastNameBlur = () => {
    const errorCopy = {
      firstNameRequired,
      firstNameInvalid,
      lastNameRequired,
      lastNameInvalid
    }
    return AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.LAST_NAME].onBlur(
      accountSetupPageState,
      errorCopy,
      setAccountSetupState
    )
  }

  const handleBlurPin = () => {
    return AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.PIN].onBlur(
      accountSetupPageState,
      setAccountSetupPinErrorsState
    )
  }

  const handleBlurConfirmPin = () => {
    return AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.CONFIRM_PIN].onBlur(
      accountSetupPageState,
      setAccountSetupPinErrorsState
    )
  }

  return (
    <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto space-y-4">
      <div className="space-y-10 pb-10 sm:space-y-6 sm:pb-0">
        <Heading2>{accountSetupHeader}</Heading2>
        <SelfServeHeader />
        <Name
          nameRef={nameRef}
          handleFirstNameBlur={handleFirstNameBlur}
          handleLastNameBlur={handleLastNameBlur}
          setAccountSetupState={setAccountSetupState}
        />
        <PIN
          pinRef={pinRef}
          showToggle={true}
          handleBlurPin={handleBlurPin}
          handleBlurConfirmPin={handleBlurConfirmPin}
        />
        <PreferredLanguage />
        <div className="flex justify-end sm:pt-16">
          <Button
            block={isMobile}
            onClick={() => handleNextClick()}
            label={nextStepLabel}
            testId="accountSetup-nextStep"
          />
        </div>
      </div>
    </div>
  )
}

export default AccountSetupPage
