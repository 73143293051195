import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const TabGroup = ({ children, onSelect, className, selected }) => {
  const styleBase =
    'cursor-pointer w-1/2 relative flex items-center justify-center p-sm md:p-8 border-primary shadow-disabled empty'

  const styleAfter =
    'after:transform after:w-7 after:rotate-45 after:h-7 after:absolute after:bg-primary after:z-negative-1 after:-bottom-3 after:shadow-active'

  const styleSelected = `shadow-active z-10 bg-primary ${styleAfter}`

  const styleUnselected = 'bg-unselected-background hover:bg-hover-background'

  const [selectedName, setSelectedName] = useState('')
  const handleOnSelect = (name) => {
    setSelectedName(name)
    onSelect(name)
  }

  const updateSelectedName = () => {
    children.map((val) => {
      if (val.props.selected) {
        setSelectedName(val.props.name)
      }
    })
  }

  useEffect(() => {
    updateSelectedName()
  }, [])

  useEffect(() => {
    if (selected) {
      setSelectedName(selected)
    }
  }, [selected])

  return (
    <div
      role="tablist"
      className={`flex justify-between w-full space-x-4 flex-nowrap ${className}`}
    >
      {children?.map((val, idx) => {
        return (
          <button
            id={idx}
            aria-controls={val.props.name}
            aria-selected={selectedName === val.props.name}
            onClick={(e) => handleOnSelect(val.props.name)}
            key={val.props.name}
            className={`${styleBase} ${
              selectedName === val.props.name ? styleSelected : styleUnselected
            }`}
            data-testid={val.props['data-testid']}
          >
            {val}
          </button>
        )
      })}
    </div>
  )
}

TabGroup.propTypes = {
  onSelect: PropTypes.func,
  className: PropTypes.string
}

TabGroup.defaultProps = {
  className: 'md:h-40'
}

export default TabGroup
