/* eslint-disable import/extensions */
import React, { useState, useContext, useMemo } from 'react'
import { ContentContext } from '@apps/contexts/ContentContext'
import { useDispatch, useSelector } from 'react-redux'
import { updateSimSelectionState, setSimType } from '@apps/redux/features/SimSelectionSlice'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Input from '@apps/components/Input'
import Alert from '@apps/components/Alert'
import { getById, getBySlug } from '@apps/utils/contentful'
import { isMobile } from 'react-device-detect'
import { BrandName, SIM_OPTIONS } from '@apps/utils/constants'
import RoundedButton from '@/apps/components/RoundedButton'
import PhoneDetails from '@/apps/components/PhoneDetails'

const ConsumerESIMCompatibility = () => {
  const dispatch = useDispatch()

  const simSelectionState = useSelector((state) => state.simSelectionPage)
  const { pageContent } = useSelector((state) => state.common.content)
  const { imei } = simSelectionState

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.SERVICES]: { getDeviceInfo }
  } = useContext(ApplicationContext)
  const haveSimPageContent = getById(pageContent, `haveSimPage`)[language]
  const { shortTextFields } = haveSimPageContent

  const { dynamicContent } = useContext(ContentContext)

  const { haveSimPage = {} } = dynamicContent || {}
  const { content } = haveSimPage

  const [customerIMEI, setCustomerIMEI] = useState('')
  const [deviceInfo, setDeviceInfo] = useState()
  const [foundIMEI, setIMEIFound] = useState(null)
  const [disableIMEI, setDisableIMEI] = useState(true)
  const [loading, setLoading] = useState(false)

  const pageTextContent = useMemo(() => {
    if (!content && !shortTextFields) return {}
    return {
      devicesIcon: getById(content, 'devices-icon')?.image?.url,
      gearIcon: getById(content, 'gear-icon')?.image?.url,
      checkSimCompatibility: getBySlug(shortTextFields, 'checkSimCompatibility')?.value,
      findYourIMEI: getBySlug(shortTextFields, 'findYourIMEI')?.value,
      checkPhoneSettings: getBySlug(shortTextFields, 'checkPhoneSettings')?.value,
      dialPhoneCode: getBySlug(shortTextFields, 'dialPhoneCode')?.value,
      enterYourIMEI: getBySlug(shortTextFields, 'enterYourIMEI')?.value,
      checkIMEI: getBySlug(shortTextFields, 'checkIMEI')?.value,
      imeiNotFound: getBySlug(shortTextFields, 'imeiNotFound')?.value,
      imeiNotFoundMessage: getBySlug(shortTextFields, 'imeiNotFoundMessage')?.value,
      proceedWithESIM: getBySlug(shortTextFields, 'proceedWithESIM')?.value,
      getNewPhysicalSIM: getBySlug(shortTextFields, 'getNewPhysicalSIM')?.value,
      pSimCost: getBySlug(shortTextFields, 'pSimCost')?.value
    }
  }, [content, shortTextFields])

  const pSimPrice = parseFloat(pageTextContent?.pSimCost) ?? 0

  const updateIMEICompatibilityCheck = (newState) => {
    dispatch(updateSimSelectionState(newState))
  }

  const onIMEIChange = (e) => {
    setCustomerIMEI(e.target.value)
    setDisableIMEI(!(e.target.value.length >= 14 && e.target.value.length <= 15))

    if (e.target.value.length === 0) {
      setIMEIFound(null)
      updateIMEICompatibilityCheck({
        imei: '',
        imeiChecked: false,
        imeiCompatible: false
      })
    }
  }

  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  const lookupIMEI = async () => {
    setLoading(true)
    const parsedIMEI = customerIMEI.trim()
    const deviceInfoResponse = await getDeviceInfo(BrandName, parsedIMEI)
    const { data = {} } = deviceInfoResponse

    const { imageURL, enabledESIM, voLteSupportStatusCode, manufacturerName, modelName } = data
    let isIMEICompatible = false
    if (enabledESIM?.toUpperCase() === 'TRUE') {
      isIMEICompatible = true
    }

    if (manufacturerName && modelName) {
      setIMEIFound(true)
    } else {
      setIMEIFound(false)
    }

    updateIMEICompatibilityCheck({
      imei: parsedIMEI,
      imeiChecked: true,
      imeiCompatible: isIMEICompatible
    })

    const deviceInfoData = {
      imageURL,
      eSimCompatible: isIMEICompatible,
      lteEnabled: voLteSupportStatusCode === 'TRUE',
      manufacturer: manufacturerName,
      model: modelName
    }
    setDeviceInfo(deviceInfoData)
    setLoading(false)
  }

  const imeiLookupFailed = () => {
    return foundIMEI === false
  }

  const imeiLookupSucceeded = () => {
    return foundIMEI === true
  }

  const choosePSim = () => {
    dispatch(setSimType({
      simType: SIM_OPTIONS.P_SIM,
      cost: pSimPrice
    }))
  }

  const imeiLookUpFeedback = () => {
    return (
      <>
        {imeiLookupSucceeded() && deviceInfo && (
          <PhoneDetails
            phoneImage={deviceInfo?.imageURL}
            eSimCompatible={deviceInfo?.eSimCompatible}
            lteCompatible={deviceInfo?.lteEnabled}
            imei={imei}
            manufacturer={deviceInfo?.manufacturer}
            model={deviceInfo?.model}
            onChoosePSimClick={choosePSim}
          />
        )}
      </>
    )
  }

  return (
    <div className="mt-4 pt-6 border-t">
      <p className="text-sm font-h3 mb-4">{pageTextContent?.checkSimCompatibility}</p>
      <div className="mb-6 p-6 bg-white border-2 border-l-8 border-tertiary border-r">
        <p className="text-sm font-h3 mb-4">{pageTextContent?.findYourIMEI}</p>
        <div>
          <p className="text-sm font-body mb-1.5 leading-6">
            <img
              alt="Setting Icon"
              src={pageTextContent?.gearIcon}
              className="inline-block mr-2.5"
            />
            {pageTextContent?.checkPhoneSettings}
          </p>
          <p className="text-sm font-body leading-6">
            <img
              alt="Device Icon"
              src={pageTextContent?.devicesIcon}
              className="inline-block mr-2.5"
            />
            {pageTextContent.dialPhoneCode}
          </p>
        </div>
      </div>
      {isMobile && imeiLookUpFeedback()}
      <div className="flex flex-col sm:flex-row items-end mb-0 sm:mb-6 pr-1 space-x-4">
        <div className="w-full sm:w-3/4">
          <Input
            type="text"
            id="input-imei"
            label={pageTextContent?.enterYourIMEI}
            labelStyles="font-body"
            name="IMEI"
            maxLength="15"
            value={customerIMEI}
            onChange={onIMEIChange}
            onKeyPress={handleKeyPress}
            data-testid="sim-selection-enter-your-imei-input"
            autoFocus
          />
        </div>
        <div className="w-full mt-4 sm:w-1/4 sm:mt-0">
          <RoundedButton
            onClick={() => lookupIMEI()}
            label={pageTextContent?.checkIMEI}
            disabled={disableIMEI}
            isLoading={loading}
            internalLoader
          />
        </div>
      </div>
      {imeiLookupFailed() && (
        <div className="mt-6 sm:mt-0 sm:mb-3">
          <Alert
            header={pageTextContent?.imeiNotFound}
            message={pageTextContent?.imeiNotFoundMessage}
          />
          <div className="w-full">
            <RoundedButton onClick={choosePSim} label={pageTextContent?.getNewPhysicalSIM} />
          </div>
        </div>
      )}
      {!isMobile && imeiLookUpFeedback()}
    </div>
  )
}

export default ConsumerESIMCompatibility
