import React, { useMemo } from 'react'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import { useSelector } from 'react-redux'
import { getById, getImgByTitle } from '@apps/utils/contentful'

const ValidationCard = ({ children, title, visibility }) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      koodoCuts: getImgByTitle(commonElements, 'koodoCuts')?.url
    }
  }, [pageContent, selectedLanguage])

  return (
    <div className={`relative w-full bg-card-background p-6 text-sm rounded text-black ${visibility}`}>
      <BodySmallBold className="mb-1">{title}</BodySmallBold>
      {children}
      {content?.koodoCuts && <img className="absolute bottom-0 right-0 w-full h-10 mx-auto my-0" src={content.koodoCuts} alt="" />}
    </div>
  )
}

export default ValidationCard
