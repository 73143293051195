import { useLocation } from 'react-router'
import Brands from '@brands/index'

/**
 * @typedef {hook} useBrand
 *
 * Supported themes or array indexed, used to identify which theming template to use in implementing application
 *
 * @return number Valid index >= 0, where 0 uses the default platform theme, and anything greater can be custom
 */
const useBrand = () => {
  const location = useLocation()

  if (!location.pathname) {
    return undefined
  }

  const pathParts = location.pathname.split('/').filter(item => item.length > 0)

  if (pathParts.length > 0) {
    return Brands[pathParts[3]]
  }

  return undefined
}

export default useBrand
