/* eslint-disable import/extensions */
import React, { useState, useMemo, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSimType } from '@apps/redux/features/SimSelectionSlice'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { getById, getBySlug } from '@apps/utils/contentful'
import Heading4 from '@apps/components/Typography/Heading4'
import Modal from '@apps/components/Modal'
import { SIM_OPTIONS } from '@apps/utils/constants'
import Accordion from '@/apps/components/Accordion'
import GetNewPSimCard from '@/apps/components/GetNewPSimCard'
import ConsumerESIMCompatibility from '@/apps/components/ConsumerESIMCompatibility'

const NewESimDetail = () => {
  const dispatch = useDispatch()
  const { pageContent } = useSelector((state) => state.common.content)
  const { simType } = useSelector((state) => state.simSelectionPage.selectSimType)

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)
  const haveSimPage = getById(pageContent, `haveSimPage`)[language]
  const { shortTextFields, longTextFields } = haveSimPage

  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      chooseASIM: getBySlug(shortTextFields, 'chooseASIM')?.value,
      getNewSIM: getBySlug(shortTextFields, 'getNewSIM')?.value,
      checkAndActivate: getBySlug(shortTextFields, 'checkAndActivate')?.value,
      getNewSIMCard: getBySlug(shortTextFields, 'getNewSIMCard')?.value,
      purchaseAndResumeLater: getBySlug(shortTextFields, 'purchaseAndResumeLater')?.value,
      whatIsESIM: getBySlug(shortTextFields, 'whatIsESIM')?.value,
      eSIMDefinition: getBySlug(shortTextFields, 'eSIMDefinition')?.value,
      activationBenefit: getBySlug(shortTextFields, 'activationBenefit')?.value,
      securityBenefit: getBySlug(shortTextFields, 'securityBenefit')?.value,
      multipleNumbersBenefit: getBySlug(shortTextFields, 'multipleNumbersBenefit')?.value,
      compatibilityWarning: getBySlug(shortTextFields, 'compatibilityWarning')?.value,
      whatIsSIMCard: getBySlug(shortTextFields, 'whatIsSIMCard')?.value,
      simCardDefinition: getBySlug(longTextFields, 'simCardDefinition')?.value,
      eSimCost: getBySlug(shortTextFields, 'eSimCost')?.value,
      pSimCost: getBySlug(shortTextFields, 'pSimCost')?.value
    }
  }, [shortTextFields])

  const eSimPrice = parseFloat(textContent?.eSimCost) ?? 0
  const pSimPrice = parseFloat(textContent?.pSimCost) ?? 0

  const [activeIndex, setActiveIndex] = useState(null)
  const [compatibilityCheckOpen, setCompatibilityCheckOpen] = useState(false)
  const [eSimModalOpen, setESimModalOpen] = useState(false)
  const [pSimModalOpen, setPSimModalOpen] = useState(false)

  const toggleActiveIndex = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  useEffect(() => {
    toggleActiveIndex(simType)
  }, [simType])

  const resetSimSelection = () => {
    setCompatibilityCheckOpen(false)
    dispatch(
      setSimType({
        simType: '',
        cost: 0
      })
    )
  }

  const setSimSelection = (selection, cost) => {
    dispatch(
      setSimType({
        simType: selection,
        cost
      })
    )
  }

  const handleConsumerESIMCompatibility = () => {
    if (activeIndex === SIM_OPTIONS.E_SIM) {
      resetSimSelection()
    } else {
      setCompatibilityCheckOpen(true)
      setSimSelection(SIM_OPTIONS.E_SIM, eSimPrice)
    }
  }

  const handleGetNewPSIM = () => {
    if (activeIndex === SIM_OPTIONS.P_SIM) {
      resetSimSelection()
    } else {
      setSimSelection(SIM_OPTIONS.P_SIM, pSimPrice)
    }
  }

  const handleNewESimInfoClick = () => {
    setESimModalOpen(!eSimModalOpen)
  }

  const handleNewPSimInfoClick = () => {
    setPSimModalOpen(!pSimModalOpen)
  }

  return (
    <div>
      <Heading4 className="text-step leading-8 font-bold font-h3">
        {`${textContent?.chooseASIM} `}
      </Heading4>
      <Accordion
        title={textContent?.getNewSIM}
        subtitle={textContent?.checkAndActivate}
        open={activeIndex === SIM_OPTIONS.E_SIM && compatibilityCheckOpen}
        setOpen={handleConsumerESIMCompatibility}
        onInfoClick={handleNewESimInfoClick}
        testId="newsimcard-getanew-sim"
      >
        <ConsumerESIMCompatibility />
      </Accordion>
      <Accordion
        title={textContent?.getNewSIMCard}
        subtitle={textContent?.purchaseAndResumeLater}
        open={activeIndex === SIM_OPTIONS.P_SIM}
        setOpen={handleGetNewPSIM}
        onInfoClick={handleNewPSimInfoClick}
        testId="newsimcard-getanew-psim"
      >
        <GetNewPSimCard />
      </Accordion>
      <Modal
        isOpen={eSimModalOpen}
        closeModal={handleNewESimInfoClick}
        title={textContent?.whatIsESIM}
      >
        <div className="font-body text-black leading-6 text-sm mt-4">
          <p>{textContent?.eSIMDefinition}</p>
          <ul className="ml-6 mb-6 list-disc">
            <li>{textContent?.activationBenefit}</li>
            <li>{textContent?.multipleNumbersBenefit}</li>
            <li>{textContent?.securityBenefit}</li>
          </ul>
          <p>{textContent?.compatibilityWarning}</p>
        </div>
      </Modal>
      <Modal
        isOpen={pSimModalOpen}
        closeModal={handleNewPSimInfoClick}
        title={textContent?.whatIsSIMCard}
      >
        <div className="font-body text-black leading-6 text-sm mt-4">
          <p>{textContent?.simCardDefinition}</p>
        </div>
      </Modal>
    </div>
  )
}

export default NewESimDetail
