import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ checked, label, onChange, testId = '', hasError = false, disabled = false }) => {
  return (
    <input
      type="checkbox"
      name={label}
      checked={checked}
      style={{ width: '25px', height: '25px' }}
      onChange={(e) => onChange(e)}
      data-testid={testId}
      className={hasError ? 'border-red focus:shadow-danger' : ''}
      disabled={disabled}
    />
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Checkbox
