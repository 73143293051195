import React from 'react'
import PropTypes from 'prop-types'

const BodySmall = ({
  id, children, className, textColor
}) => {
  return (
    <span className={`text-xs ${textColor || 'text-copy'}  font-body ${className}`} id={id}>
      {children}
    </span>
  )
}
BodySmall.prototype = {
  id: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  textColor: PropTypes.string
}

export default BodySmall
