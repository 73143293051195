import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import ToolTipSvg from './tooltipSvg.svg'

const ToolTip = ({
  onClick,
  showQuestion = true,
  children,
  align = 'right',
  toolTipStyles = 'top-0 left-0 right-0'
}) => {
  let alignment = 'right'

  if (align === 'right') {
    alignment = 'alignRightPseudo after:shadow-tooltipCaretTop p-4'
  }
  if (align === 'left') {
    alignment = 'alignLeftPseudo after:shadow-tooltipCaret p-4'
  }
  if (align === 'top') {
    alignment = 'alignBottomRightPseudo after:shadow-tooltipCaret p-4'
  }
  if (align === 'bottom') {
    alignment = 'alignBottomLeftPseudo after:shadow-tooltipCaret p-4'
  }

  return (
    <div className={`absolute ${toolTipStyles}`}>
      <div data-content=" " className={`relative z-20 flex items-center justify-center max-w-3xl rounded after:transform border-primary bg-white shadow-tooltip text-primary empty after:w-5 after:rotate-45 after:h-5 after:top-tooltipLg after:-mb-20 after:-mr-20 after:absolute after:bg-white top-8  ${alignment}`}>{children}</div>
    </div>
  )
}

ToolTip.propTypes = {

  showQuestion: PropTypes.bool
}

export default ToolTip
