/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { SIM_OPTIONS, CONSUMER_SIM_OPTIONS } from '@apps/utils/constants'
import { formatSimField } from '@apps/utils/formHelpers'

export const SIM_SELECTION_STATE = {
  SIM_ERROR: 'simError',
  SIM: 'simNumber'
}

const SimSelectionComplete = (state) => {
  const { selectSimType, simNumber, simError } = state
  const { currentPageModule } = selectSimType

  if (
    currentPageModule === SIM_OPTIONS.P_SIM ||
    currentPageModule === CONSUMER_SIM_OPTIONS.HAVE_SIM
  ) {
    return !!simNumber && !simError
  }
  return true
}

export const simSelectionPage = createSlice({
  name: 'simSelectionPage',
  initialState: {
    selectSimType: {
      currentPageModule: '',
      simType: 'pSIM',
      cost: 0
    },
    simNumber: '',
    esimNumber: '',
    simError: '',
    imei: '',
    imeiChecked: false,
    imeiCompatible: false,
    eid: '',
    isComplete: false
  },
  reducers: {
    recordSimSelectionInfo: (state, action) => {
      if (!action.payload) return
      const { simType, simNumber, eSimPrice, pSimPrice } = action.payload
      if (simType) {
        const simTypeValue =
          simType.toLowerCase() === CONSUMER_SIM_OPTIONS.HAVE_SIM.toLowerCase()
            ? SIM_OPTIONS.P_SIM
            : SIM_OPTIONS.E_SIM

        const update = {
          selectSimType: {
            simType: simTypeValue,
            cost: simTypeValue === SIM_OPTIONS.P_SIM ? pSimPrice : eSimPrice
          }
        }

        if (simTypeValue === SIM_OPTIONS.P_SIM) {
          const { formattedSim } = formatSimField(simNumber)
          update.simNumber = formattedSim
        }

        state = Object.assign(state, update)
      }
    },
    updateSimSelectionState: (state, action) => {
      const newState = Object.assign(state, action.payload)
      newState.isComplete = SimSelectionComplete(newState)
      state = { ...newState }
    },

    setSimType: (state, action) => {
      state.selectSimType.simType = action.payload.simType
      state.selectSimType.cost = action.payload.cost
      state.isComplete = SimSelectionComplete(state)
    },

    setSimNumber: (state, action) => {
      state.simNumber = action.payload
      state.isComplete = SimSelectionComplete(state)
    },

    setESimNumber: (state, action) => {
      state.esimNumber = action.payload
    },

    reset: (state, action) => {
      state.selectSimType.simType = action.payload.simType
      state.isComplete = SimSelectionComplete(state)
    },

    setPageModule: (state, action) => {
      state.selectSimType.currentPageModule = action.payload
      state.isComplete = SimSelectionComplete(state)
    }
  }
})

// this is for dispatch
export const {
  recordSimSelectionInfo,
  setSimType,
  updateSimSelectionState,
  setSimNumber,
  setESimNumber,
  reset,
  setPageModule
} = simSelectionPage.actions

// this is for configureStore
export default simSelectionPage.reducer
