import React from 'react'
import { Provider } from "react-redux"
import AppRoutes from '@apps/AppRoutes'
import { MountsProvider } from '@engine/contexts/MountsContext'
import Theme from '@engine/components/Theme'
import ApplicationProvider from '@apps/contexts/ApplicationContext'
import '@brands/index.css'
import store from "@apps/redux/store"

export default function App() {
  return (
    <Theme>
      <MountsProvider>
        <ApplicationProvider>
          <Provider store={store}>
            <AppRoutes />
          </Provider>
        </ApplicationProvider>
      </MountsProvider>
    </Theme>
  )
}
