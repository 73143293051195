import FlowPath from '@engine/flowPath'

class ActivationPaths extends FlowPath {
  get Base() {
    return this.path
  }

}

export default ActivationPaths
