import React, { useEffect, useContext, useMemo, useRef } from 'react'
import { useLocation, useHistory } from 'react-router'
import TransactionSummarySection from '@apps/flows/activation/sections/TransactionSummarySection'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { useSelector } from 'react-redux'
import Sites from '@sites/index'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'

const TransactionSummaryPage = () => {
  const {
    userType,
    content: { featureFlags }
  } = useSelector((state) => state.common)


  const pageRef = useRef(null)
  useEffect(() => {
    window.scrollTo(0, 0)

    if (pageRef.current) {
      pageRef.current.style.minHeight = '100vh'
    }
  }, [])

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const history = useHistory()
  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const splashPage = featureFlags && featureFlags[0]?.showSplashing
  if (splashPage) {
    if (isDealer) history.push(dealerActivationPaths.Splash)
    else history.push(consumerActivationPaths.Splash)
  }

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region
  } = useContext(ApplicationContext)

  const location = useLocation()

  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
  }, [])

  return (
    <div className="flex flex-col max-w-6xl px-4 pb-12 m-auto space-y-4 lg:space-y-24">
      <TransactionSummarySection />
    </div>
  )
}

export default TransactionSummaryPage
