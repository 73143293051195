export const MAX_INPUT_LENGTH = 28

const NEGATED_ACCOUNT_NUMBER_REGEX = /[^a-z0-9]/gi
const NEGATED_NAME_REGEX = /[^a-zA-Z\s'().-]*/gi
const POSTCODE_REGEX = /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$/
const NEGATED_NUMBER_REGEX = /[^0-9]/g

/*
 * global search to remove whitespace,|,-
 */
export const sanitizeTextField = (value) => {
  return value.replace(/\s|-/g, '')
}

export const isDigitOrEmpty = (value) => {
  return /^\d+$/.test(value) || value === ''
}

export const isDigitValid = (value) => {
  return NEGATED_NUMBER_REGEX.test(value) || value === ''
}

export const isPostalCode = (value) => {
  return POSTCODE_REGEX.test(value)
}

export const formatSimField = (sanitizedValue) => {
  let formattedSim = sanitizedValue
  const simArr = sanitizedValue.match(/.{1,5}/g)
  if (simArr && simArr.length > 0) {
    formattedSim = simArr.join(' - ')
  }
  return { simArr, formattedSim }
}

export const isEmptyEmail = (value) => {
  if (value === undefined || value === '') {
    return true
  }

  return false
}

export const isValidEmail = (value) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]{2,}(?:\.[a-zA-Z0-9-]{2,4})$/.test(value)
}

export const isMismatchEmail = (value1, value2) => {
  if (value1.localeCompare(value2, undefined, { sensitivity: 'accent' })) {
    return true
  }

  return false
}

export const sanitizeAccountNumber = (accountNumber) => {
  return accountNumber.replace(NEGATED_ACCOUNT_NUMBER_REGEX, '')
}

export const sanitizeNameField = (name) => {
  return name.replace(NEGATED_NAME_REGEX, '')
}

export const formattedPhoneNumber = (phoneNumber) => {
  const phoneArr = []
  const chunk = phoneNumber.substring(0, 3)
  if (chunk.length > 0) {
    phoneArr.push(chunk)
  }
  const chunk1 = phoneNumber.substring(3, 6)
  if (chunk1.length > 0) {
    phoneArr.push(chunk1)
  }
  const chunk2 = phoneNumber.substring(6, 10)
  if (chunk2.length > 0) {
    phoneArr.push(chunk2)
  }
  if (phoneArr && phoneArr.length > 0) {
    return phoneArr.join(' ')
  }
  return ''
}
