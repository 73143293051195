import React, { useContext, useMemo } from 'react'
import BodyBold from '@apps/components/Typography/BodyBold'
import Heading2 from '@apps/components/Typography/Heading2'
import Heading3 from '@apps/components/Typography/Heading3'
import Heading4 from '@apps/components/Typography/Heading4'
import Expander from '@apps/components/Expander'
import PlansGrid from '@apps/components/PlansGrid'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { ContentContext } from '@apps/contexts/ContentContext'
import RichText from '@apps/components/RichText'
import { useSelector } from 'react-redux'
import Sites from '@sites'
import { getBySlug, getById } from '@apps/utils/contentful'

const PlansSection = ({ planErrorRef, showPlanError, setShowPlanError }) => {
  const { dynamicContent } = useContext(ContentContext)
  const { pageContent } = useSelector((state) => state.common.content)
  const { userType } = useSelector((state) => state.common)

  const { plansPage: { content = [] } = {} } = dynamicContent || {}

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)

  const pagePrefix = userType === Sites.dealer ? 'Dealer' : 'Consumer'
  const plansPageContent = getById(pageContent, `plansPage${pagePrefix}`)[language]
  const { shortTextFields } = plansPageContent || {}

  const textContent = useMemo(() => {
    return {
      legalContent: getById(content, 'legalStuff'),
      planCategory: getById(content, 'plansCategory'),
      pickMobilePlan: getBySlug(shortTextFields, 'pickMobilePlan')?.value,
      pickMobilePlanSubText: getBySlug(shortTextFields, 'pickMobilePlanSubText')?.value
    }
  }, [shortTextFields, content])

  const { legalContent, planCategory, pickMobilePlan, pickMobilePlanSubText } = textContent

  return (
    <div tabIndex="0" className="w-full">
      {dynamicContent && (
        <>
          <Heading2>{pickMobilePlan}</Heading2>
          <BodyBold textSize="text-base" className="mt-4 leading-6 font-h4">
            {pickMobilePlanSubText}
          </BodyBold>
        </>
      )}
      <div className="border-b divide-y">
        <Expander
          heading={<Heading3>{planCategory ? planCategory.title : ''}</Heading3>}
          body={
            <PlansGrid
              planErrorRef={planErrorRef}
              showPlanError={showPlanError}
              setShowPlanError={setShowPlanError}
            />
          }
          toggle="block"
        />
        {legalContent && (
          <Expander
            heading={<Heading4>{legalContent.title}</Heading4>}
            body={<RichText>{legalContent.content}</RichText>}
          />
        )}
      </div>
    </div>
  )
}

export default PlansSection
