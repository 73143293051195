/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import Loader, { SIZE } from '@apps/components/Loader'

const Button = ({
  label = 'BUTTON',
  type = 'button',
  block = false,
  disabled = false,
  className = '',
  onClick = () => {},
  outlined = false,
  testId,
  internalLoader = false,
  isLoading
}) => {
  const baseStyles = 'text-sm font-h1  px-8 py-4 rounded focus:outline-none focus:shadow-active'

  const defaultStyles =
    outlined === true
      ? 'text-black bg-white border border-primary'
      : 'text-copy-inverted bg-primary hover:bg-hover-background hover:text-hover-text'

  const disabledStyles =
    'disabled:bg-primary disabled:text-white disabled:opacity-30'

  // production and pasta different: Dave
  // 'disabled:border-disabled disabled:opacity-75 '
  return (
    <>
    {(internalLoader && isLoading) ? (
       <button className={`text-black bg-hover-background border border-primary p-5 ${block ? 'w-full' : ''} ${className}`}
       disabled={disabled}
       onClick={onClick}
       type={type}
       data-testid={testId}
     >
      <span className='flex justify-center relative bottom-3'>
       <Loader
            className="absolute top-auto bottom-auto z-index-10"
            size={SIZE.MDS}
            loading={isLoading}
          />
      </span>
     </button>
    ) : (
      <button
      className={`${disabledStyles} ${baseStyles} ${defaultStyles} ${block ? 'w-full' : ''} ${className}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid={testId}
    >
      {label}
    </button>
    )}
  </>
    
  )
}
Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  outlined: PropTypes.bool,
  internalLoader: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
}


export default Button
