import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import BodySmall from '@apps/components/Typography/BodySmall'
import Body from '@apps/components/Typography/Body'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import { getById, getImgByTitle, getBySlug } from '@apps/utils/contentful'
import { ContentContext } from '@apps/contexts/ContentContext'
import Sites from '@sites/index'
import { PLAN_DURATION } from '@apps/utils/constants'

const HIDDEN = 'hidden'
const BLOCK = 'block'

const Plan = ({ plan, isSelected, planSelected, id }) => {
  const [detailsOpen, setDetailsOpen] = useState(HIDDEN)
  const [isClickable, setIsClickable] = useState(true)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language
  } = useContext(ApplicationContext)

  const [promo, setPromo] = useState(null)
  const [card, setCardColor] = useState({
    backgoundColor: null,
    textColor: null,
    copyColor: null,
    promoBackgoundColor: null,
    promoTextColor: null,
    planSubTitleColor: null,
    planSubTitleColorText: null
  })

  const {
    content: { pageContent },
    user: { selectedLanguage },
    userType
  } = useSelector((state) => state.common)

  const {
    plans: { promotion, selectPlan, selectedPlan, details, hideDetails }
  } = useContext(ContentContext)

  const isDealer = userType === Sites.dealer
  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const content = useMemo(() => {
    if (!pageContent) return {}
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    const plansPage = getById(pageContent, `plansPage${pagePrefix}`)[selectedLanguage]
    const { shortTextFields } = plansPage
    return {
      chevronUpCircle: getImgByTitle(commonElements, 'chevronUpCircle')?.url,
      chevronDownCircle: getImgByTitle(commonElements, 'chevronDownCircle')?.url,
      chevronDownCircleInverted: getImgByTitle(commonElements, 'chevronDownCircleInverted')?.url,
      chevronUpCircleInverted: getImgByTitle(commonElements, 'chevronUpCircleInverted')?.url,
      promotion: getBySlug(shortTextFields, 'promotion')?.value,
      selectPlan: getBySlug(shortTextFields, 'selectPlan')?.value,
      details: getBySlug(shortTextFields, 'details')?.value,
      hideDetails: getBySlug(shortTextFields, 'hideDetails')?.value
    }
  }, [pageContent, selectedLanguage])

  useEffect(() => {
    setPromo(plan.promo)
  }, [plan.promo])

  useEffect(() => {
    let subTitleColor = ''
    let subTitleColorText = ''
    if (plan.duration === PLAN_DURATION.SLOT_30) {
      subTitleColor = 'bg-thirtyDaysDurationPlan-background'
      subTitleColorText = 'text-copy-inverted'
    } else {
      subTitleColor = 'bg-threeSixtyDaysDurationPlan-background'
      subTitleColorText = 'text-threeSixtyDaysDurationPlan-text'
    }
    switch (plan.network) {
      case '3G':
        setCardColor({
          backgoundColor: 'bg-threeG-background',
          textColor: 'text-threeG-text',
          copyColor: 'threeG-text',
          promoBackgoundColor: 'bg-threeGPromo-background',
          promoTextColor: 'text-threeGPromo-text',
          planSubTitleColor: subTitleColor,
          planSubTitleColorText: subTitleColorText
        })
        break

      case '4G':
        setCardColor({
          backgoundColor: 'bg-fourG-background',
          textColor: 'text-fourG-text',
          copyColor: 'fourG-text',
          promoBackgoundColor: 'bg-fourGPromo-background',
          promoTextColor: 'text-fourGPromo-text',
          planSubTitleColor: subTitleColor,
          planSubTitleColorText: subTitleColorText
        })
        break

      default:
        setCardColor({
          backgoundColor: 'bg-threeG-background',
          textColor: 'text-threeG-text',
          copyColor: 'threeG-text',
          promoBackgoundColor: 'bg-threeGPromo-background',
          promoTextColor: 'text-threeGPromo-text',
          planSubTitleColor: subTitleColor,
          planSubTitleColorText: subTitleColorText
        })
        break
    }
  }, [plan])

  const body = () => {
    return (
      <div className="space-y-5 p-4">
        {plan.promo &&
          plan.promo.bundles?.map((bundle) => (
            <BodySmallBold textColor={card.textColor} className="leading-normal" key={bundle?.id}>
              {bundle?.name}
            </BodySmallBold>
          ))}
        <div>
          <ul className="list-disc list-outside ml-4">
            {plan.features.map((feature) => {
              return (
                <li className={card.textColor} key={feature}>
                  <BodySmall textColor={card.textColor} className="leading-normal">
                    {feature}
                  </BodySmall>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  const toggleDetails = () => {
    if (detailsOpen === BLOCK) {
      setDetailsOpen(HIDDEN)
    } else {
      setDetailsOpen(BLOCK)
    }
  }
  const promotionSection = () => {
    return (
      <>
        {promo && (
          <div
            className={`absolute rounded-full py-2 z-50 px-8 left-6 -top-4 ${card.promoBackgoundColor}`}
          >
            <BodySmallBold textColor={card.promoTextColor}>{promotion}</BodySmallBold>
          </div>
        )}
      </>
    )
  }
  const ltpSection = () => {
    return (
      <>
        {plan.ltpSavings && (
          <div
            className={`absolute rounded-full py-2 z-50 px-8 left-6 -top-4 ${card.promoBackgoundColor}`}
          >
            <BodySmallBold textColor={card.promoTextColor}>{plan.ltpSavings}</BodySmallBold>
          </div>
        )}
      </>
    )
  }
  const promotionSubTitle = () => {
    return (
      <div className={`rounded-full py-1 z-50 px-3 ${card.planSubTitleColor}`}>
        <Body textColor={`text-center ${card.planSubTitleColorText}`}>{plan.subtitle}</Body>
      </div>
    )
  }

  const handleClick = () => {
    if (isClickable) {
      planSelected(id, promo)
      setIsClickable(false)
      setTimeout(() => setIsClickable(true), 300)
    }
  }

  return (
    <div
      tabIndex="0"
      role="listitem"
      className={`cursor-pointer relative flex flex-col rounded ${
        plan.duration !== '360' && promo ? 'mt-8 sm:mt-4' : 'mt-4'
      }`}
    >
      {plan.duration === '360' ? ltpSection() : promotionSection()}
      <div className={`hover:opacity-80 ${isSelected && 'opacity-80'} `}>
        <div className={`${card.backgoundColor} ${isSelected && 'shadow-2xl'} hover:shadow-2xl`}>
          <div
            role="button"
            onClick={handleClick}
            data-testid={`plans-plan${id}${promo ? '-promo' : ''}`}
          >
            <div className="px-4">
              <div className="relative flex items-start justify-end pt-4 pb-3">
                <Body className="plan-selection-radio leading-none pr-2" textColor={card.textColor}>
                  {isSelected ? selectedPlan : selectPlan}
                </Body>
                <input
                  type="radio"
                  name="Select Plan"
                  readOnly
                  checked={isSelected}
                  className={`${
                    plan.network === '3G' ? 'plan-selection-three-g' : 'plan-selection-four-g'
                  } border-2 border-solid cursor-pointer ring-0`}
                  style={{ width: '18px', height: '18px' }}
                />
                <span
                  data-content=""
                  className={`${
                    isSelected ? 'inline' : 'hidden'
                  } after:content absolute rounded-full w-2.5 h-2.5 ${
                    plan.network === '3G' ? 'plan-selected-box-three-g' : 'plan-selected-box-four-g'
                  }`}
                  style={{ right: '4px', top: '20px' }}
                />
              </div>
              <div className="flex flex-row space-x-2">
                <div className={`${card.textColor} flex flex-row`}>
                  {language === 'en' && <div className="text-lg font-h4">$</div>}
                  <span className="text-7xl font-h1" data-testid="plans-planCost">
                    {plan.value}
                  </span>
                  {language === 'fr' && <span className="ml-1 text-lg font-h4">$</span>}
                </div>
                <div className="flex flex-col">
                  <span className={`text-sm leading-7 font-h1 ${card.textColor}`}>{plan.name}</span>
                  {/* <Body textColor={card.textColor}>{plan.subtitle}</Body> */}
                  {promotionSubTitle()}
                </div>
              </div>
              <div className="pt-4 pb-6 pl-5 pr-5 plan-details">
                <BodySmall className="whitespace-pre-wrap leading-tight" textColor={card.textColor}>
                  {plan.description}
                </BodySmall>
              </div>
              <hr
                className={`${
                  plan.network === '3G' ? 'plan-card-hr-three-g' : 'plan-card-hr-four-g'
                }  border-solid border-t mx-16`}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={`${card.backgoundColor} ${isSelected && 'shadow-2xl'} hover:shadow-2xl`}>
            <div
              tabIndex="0"
              onClick={() => toggleDetails()}
              className="flex justify-between items-center cursor-pointer py-3 px-4"
            >
              <BodySmallBold textColor={card.textColor} className="underline">
                {detailsOpen === BLOCK ? hideDetails : details}
              </BodySmallBold>
              {plan.network === '3G' && (
                <img
                  aria-label={
                    detailsOpen === HIDDEN ? content?.chevronDownCircleInverted : content?.chevronUpCircleInverted
                  }
                  width="18"
                  height="18"
                  src={detailsOpen === HIDDEN ? content?.chevronDownCircleInverted : content?.chevronUpCircleInverted}
                />
              )}
              {plan.network === '4G' && (
                <img
                  aria-label={detailsOpen === HIDDEN ? content?.chevronDownCircle : content?.chevronUpCircle}
                  width="18"
                  height="18"
                  src={detailsOpen === HIDDEN ? content?.chevronDownCircle : content?.chevronUpCircle}
                />
              )}
              {!plan.network && (
                <img
                  aria-label={
                    detailsOpen === HIDDEN ? content?.chevronDownCircleInverted : content?.chevronUpCircleInverted
                  }
                  width="18"
                  height="18"
                  src={detailsOpen === HIDDEN ? content?.chevronDownCircleInverted : content?.chevronUpCircleInverted}
                />
              )}
            </div>
            <div className={detailsOpen}>{body()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

Plan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string,
    cost: PropTypes.number,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    network: PropTypes.string,
    description: PropTypes.string,
    promo: PropTypes.object,
    duration: PropTypes.number
  }).isRequired
}

export default Plan
