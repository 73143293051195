import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getById, getImgByTitle } from '@apps/utils/contentful'

const BulletCard = ({ children }) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      cutRightThick: getImgByTitle(commonElements, 'cutRightThick')?.url
    }
  }, [pageContent, selectedLanguage])

  return (
    <div
      style={{
        backgroundImage: `url("${content.cutRightThick}")`,
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '64px 100%'
      }}
      className="rounded bg-card-background pl-4 pr-16 py-42"
    >
      {children}
    </div>
  )
}

export default BulletCard
