/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import React, { useState, useContext, useEffect, useMemo } from 'react'
import LanguageHeader from '@apps/components/LanguageHeader'
import {
  APPLICATION_STATE,
  ApplicationContext,
  ModifyApplicationContext
} from '@apps/contexts/ApplicationContext'
import Sites from '@sites/index'
import { getBySlug, getById, getImgByTitle } from '@apps/utils/contentful'
import { useDispatch, useSelector } from 'react-redux'
import { setShowLanguageSelector, setProvinceId, logOut } from '@apps/redux/features/CommonSlice'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import BodyBold from '../Typography/BodyBold'
import Body from '../Typography/Body'

const SiteHeader = () => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector((state) => state.common.user)
  const { userType, user } = useSelector((state) => state.common)
  const { pageContent } = useSelector((state) => state.common.content)
  const setAppState = useContext(ModifyApplicationContext)
  const appState = useContext(ApplicationContext)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage,
    [APPLICATION_STATE.CURRENT_REGION]: currentRegion,
    [APPLICATION_STATE.PROVINCES]: provinceList,
    [APPLICATION_STATE.SHOW_SELECT_LANGUAGE]: showSelectLanguage,
    [APPLICATION_STATE.IDENTITY]: identity,
    [APPLICATION_STATE.SERVICES]: brandServices
  } = appState

  const { retrieveCookie, deleteCookie } = brandServices

  const { outletId } = identity || {}

  const { showSelectLanguage: showSelectLanguageRedux } = useSelector((state) => state.common)

  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const commonElements = useMemo(() => {
    if (!pageContent || !currentLanguage) return {}
    const page = getById(pageContent, `commonElements`)
    return page[currentLanguage]
  }, [pageContent, currentLanguage, userType])

  const { shortTextFields } = commonElements

  const images = useMemo(() => {
    return {
      logo: getImgByTitle(commonElements, 'logo')?.url,
      cut1: getImgByTitle(commonElements, 'cut1')?.url,
      cut2: getImgByTitle(commonElements, 'cut2')?.url,
      hamburger: getImgByTitle(commonElements, 'hamburger')?.url,
      chevronDown: getImgByTitle(commonElements, 'chevronDown')?.url
    }
  }, [commonElements])

  const { logo, cut1, cut2, hamburger, chevronDown } = images || {}

  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      addons: getBySlug(shortTextFields, 'header-addons')?.value,
      byop: getBySlug(shortTextFields, 'header-byop')?.value,
      needAssistance: getBySlug(shortTextFields, 'header-needAssistance')?.value,
      repName: getBySlug(shortTextFields, 'header-repName')?.value,
      callSupport: getBySlug(shortTextFields, 'header-callSupport')?.value,
      shop: getBySlug(shortTextFields, 'header-shop')?.value,
      selfServe: getBySlug(shortTextFields, 'header-selfServe')?.value,
      getHelp: getBySlug(shortTextFields, 'header-getHelp')?.value,
      phones: getBySlug(shortTextFields, 'header-phones')?.value,
      plans: getBySlug(shortTextFields, 'header-plans')?.value,
      tablets: getBySlug(shortTextFields, 'header-tablets')?.value,
      tabletPlans: getBySlug(shortTextFields, 'header-tabletPlans')?.value,
      travelAndRoaming: getBySlug(shortTextFields, 'header-travelAndRoaming')?.value,
      prepaidPhones: getBySlug(shortTextFields, 'header-prepaidPhones')?.value,
      prepaidPlans: getBySlug(shortTextFields, 'header-prepaidPlans')?.value,
      coverage: getBySlug(shortTextFields, 'header-coverage')?.value,
      wirelessHomePhone: getBySlug(shortTextFields, 'header-wirelessHomePhone')?.value,
      storeID: getBySlug(shortTextFields, 'header-storeID')?.value,
      channelorgCode: getBySlug(shortTextFields, 'header-channelorg-code')?.value,
      needAssistanceUrl: getBySlug(shortTextFields, 'header-need-assistance-url')?.value,
      callSupportPhoneNumber: getBySlug(shortTextFields, 'header-call-support-phonenumber')?.value,
      logout: getBySlug(shortTextFields, 'header-logout')?.value
    }
  }, [shortTextFields])

  const {
    needAssistance,
    repName,
    callSupport,
    shop,
    selfServe,
    getHelp,
    phones,
    plans,
    byop,
    tablets,
    tabletPlans,
    addons,
    travelAndRoaming,
    prepaidPhones,
    prepaidPlans,
    coverage,
    wirelessHomePhone,
    storeID,
    channelorgCode,
    needAssistanceUrl,
    callSupportPhoneNumber,
    logout
  } = textContent

  const shopItems = [
    {
      label: phones,
      url: 'https://www.koodomobile.com/phones'
    },
    {
      label: plans,
      url: 'https://www.koodomobile.com/rate-plans'
    },
    {
      label: byop,
      url: 'https://www.koodomobile.com/bring-your-own-phone'
    },
    {
      label: tablets,
      url: 'https://www.koodomobile.com/tablets'
    },
    {
      label: tabletPlans,
      url: 'https://www.koodomobile.com/tablet-rate-plans'
    },
    {
      label: addons,
      url: 'https://www.koodomobile.com/add-ons'
    },
    {
      label: travelAndRoaming,
      url: 'https://www.koodomobile.com/easyroam'
    },
    {
      label: prepaidPhones,
      url: 'https://www.koodomobile.com/prepaid-phones'
    },
    {
      label: prepaidPlans,
      url: 'https://www.koodomobile.com/prepaid-plans'
    },
    {
      label: coverage,
      url: 'https://choosehappy.koodomobile.com/network/'
    },
    {
      label: wirelessHomePhone,
      url: 'https://www.koodomobile.com/phones/wireless-home-phone'
    }
  ]


  const [showSubMenu, setShowSubMenu] = useState(false)
  const [salesRepName, setSalesRepName] = useState(undefined)
  const [channelOrgCode, setChannelOrgCode] = useState(undefined)
  const [showMobileNav, setShowMobileNav] = useState(false)

  // class string for main menu items
  const menuClasses =
    'no-underline h-7 font-medium min-h-2 cursor-pointer hover:border-b-4 text-primary hover:border-primary'
  const subMenuClasses =
    'no-underline h-7 font-medium min-h-2 cursor-pointer hover:border-b-2 text-primary hover:border-primary hover:text-hover-text'

  useEffect(() => {
    if (identity && identity.givenName && identity.sn) {
      const salesRepFirstName = `${identity.givenName
        .charAt(0)
        .toUpperCase()}${identity.givenName.slice(1)}`
      const salesRepLastInitial = `${identity.sn.charAt(0).toUpperCase()}`

      setSalesRepName(`${salesRepFirstName} ${salesRepLastInitial}.`)
      setChannelOrgCode(`${identity?.channelOrgCode}`)
    }
  }, [identity])

  useEffect(() => {
    if (provinceList && provinceList.length > 0) {
      const [currentProvinceDetails] = provinceList?.filter(
        (province) => province?.shortName === currentRegion
      )
      dispatch(setProvinceId(currentProvinceDetails?.value))
    }
  }, [provinceList, currentRegion])

  const isDealer = () => {
    return userType === Sites.dealer
  }

  const handleShowLanguageSelector = () => {
    setAppState({
      ...appState,
      [APPLICATION_STATE.SHOW_SELECT_LANGUAGE]: !showSelectLanguage
    })
    dispatch(setShowLanguageSelector(!showSelectLanguageRedux))
  }

  const redirectToLandingPage = () => {
    if (isDealer()) {
      window.location = dealerActivationPaths.Base
    } else {
      window.location = consumerActivationPaths.Base
    }
  }

  const handleLogout = async () => {
    console.log('retrieve cookie call 3')
    const { userSessionId } = {} = await retrieveCookie() || {}
    if (userSessionId) {
      await deleteCookie()
      dispatch(logOut())
      redirectToLandingPage()
    } else {
      dispatch(logOut())
      redirectToLandingPage()
    }
  }

  return (
    <>
      {showSelectLanguage && <LanguageHeader />}

      <div className="relative">
        <div
          className="absolute top-0 right-0 w-2/3 h-16 md:h-20 md:w-2/4"
          style={{
            backgroundImage: `url("${cut1}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%'
          }}
        />
        <div
          className="absolute top-0 right-0 w-9/12 h-12"
          style={{
            backgroundImage: `url("${cut2}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%'
          }}
        />
      </div>
      <div className="relative items-end max-w-6xl px-4 m-auto mb-10 h-7 md:mb-0">
        <button
          type="button"
          className="absolute right-0 mx-4 no-underline cursor-pointer top-2"
          onClick={handleShowLanguageSelector}
          tabIndex={0}
        >
          <div className="flex">
            <BodyBold className="text-headerCopy">{String(currentLanguage).toUpperCase()}</BodyBold>
            <BodyBold className="pl-2 ml-2 border-l-2 text-headerCopy border-headerCopy">
              {String(currentRegion).toUpperCase()}
            </BodyBold>
          </div>
        </button>
      </div>
      <div className="flex items-center justify-between max-w-6xl px-4 pb-6 m-auto md:items-end md:justify-start">
        <div>
          <img src={logo} alt="logo" className="mb-1 md:w-48 max-w-none" />
        </div>
        <div className="block md:hidden">
          <button
            onClick={() => {
              setShowMobileNav(!showMobileNav)
            }}
          >
            <img src={hamburger} alt="menu" />
          </button>
        </div>
        {/* tablet and desktop layout */}
        <div className="hidden mt-3 ml-8 space-x-8 md:flex md:w-full">
          {!isDealer() && (
            <>
              <div />
              <div className="w-full flex justify-between">
                <div className="w-full flex space-x-8">
                  <div
                    tabIndex="0"
                    className="h-auto"
                    onMouseEnter={() => setShowSubMenu(true)}
                    onMouseLeave={() => setShowSubMenu(false)}
                    onClick={() => setShowSubMenu(!showSubMenu)}
                    onKeyUp={(event) => {
                      const key = event.which || event.keyCode
                      if (key === 13) {
                        setShowSubMenu(!showSubMenu)
                      }
                    }}
                  >
                    <div className="flex w-full">
                      <div className={menuClasses}>
                        <BodyBold>{shop}</BodyBold>
                      </div>
                    </div>
                  </div>
                  {showSubMenu && (
                    <div
                      className="absolute z-10 mt-7"
                      onMouseEnter={() => setShowSubMenu(true)}
                      onMouseLeave={() => setShowSubMenu(false)}
                    >
                      <ul className="flex flex-col pb-4 pl-4 pr-4 -ml-8 bg-white rounded-sm shadow-menu">
                        {shopItems.map((item) => {
                          return (
                            <li key={item.label} className="pt-2">
                              <a href={item.url} className={subMenuClasses}>
                                <Body className="hover:underline">{item.label}</Body>
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )}

                  <a
                    href="https://www.koodomobile.com/my-account/overview"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={menuClasses}
                    tabIndex={0}
                  >
                    <BodyBold>{selfServe}</BodyBold>
                  </a>
                  <a
                    href={`https://www.koodomobile.com/${currentLanguage}/help`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={menuClasses}
                    tabIndex={0}
                  >
                    <BodyBold>{getHelp}</BodyBold>
                  </a>
                </div>
                {isLoggedIn && (
                  <div className="flex space-x-8 items-start">
                    <BodyBold textColor="text-primary">{user?.email?.toLowerCase()}</BodyBold>
                    <button onClick={handleLogout}>
                      <BodyBold>{logout}</BodyBold>
                    </button>
                  </div>
                )}
              </div>
            </>
          )}

          {isDealer() && (
            <div className="w-full flex justify-between pb-2">
              <div className="flex flex-row space-x-6">
                <BodyBold>
                  {repName}
                  &nbsp;
                  <span className="text-primary">{salesRepName}</span>
                </BodyBold>
                <BodyBold>
                  {channelorgCode}
                  &nbsp;
                  <span className="text-primary">{channelOrgCode}</span>
                </BodyBold>
                 <BodyBold>
                  &nbsp;
                  <span className="text-primary">
                    <a
                      id="needAssistanceUrl"
                      href={needAssistanceUrl}
                      className="text-primary underline"
                      onClick={(e) => {
                        e.preventDefault()
                        window.open(
                          needAssistanceUrl,
                          '_blank',
                          'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,personalbar=no,origin=no,menubar=no,width=400,height=725'
                        )
                      }}
                    >
                      {needAssistance}
                    </a>
                  </span>
                  &nbsp; &nbsp;
                  {callSupport}&nbsp;
                  <span className="text-primary">{callSupportPhoneNumber}</span>
                </BodyBold>
              </div>
              {isLoggedIn && (
                <div className="flex items-start">
                  <button onClick={handleLogout}>
                    <BodyBold>{logout}</BodyBold>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* mobile */}
      {showMobileNav && (
        <div className="relative w-full">
          <div
            className={`absolute z-50 block w-full bg-white ${
              !showSubMenu && 'border-t'
            } md:hidden`}
          >
            <div className="bg-background">
              <ul>
                {!isDealer() && (
                  <>
                    <li>
                      <div
                        className={`p-4 ${showSubMenu && 'bg-hover-background border-l-4 pl-3'}`}
                      >
                        <a
                          tabindex="0"
                          className="flex justify-between"
                          onClick={() => setShowSubMenu(!showSubMenu)}
                          onKeyUp={(event) => {
                            const key = event.which || event.keyCode
                            if (key === 13) {
                              setShowSubMenu(!showSubMenu)
                            }
                          }}
                        >
                          {showSubMenu ? <BodyBold>{shop}</BodyBold> : <Body>{shop}</Body>}
                          <img
                            className={showSubMenu && 'transform rotate-180'}
                            src={chevronDown}
                            alt={`${shop} open`}
                          />
                        </a>
                      </div>
                      <div className="mx-4 border-b">
                        {showSubMenu && (
                          <ul className="p-4 space-y-6">
                            {shopItems.map((item) => {
                              return (
                                <li key={item.label}>
                                  <a href={item.url}>
                                    <Body>{item.label}</Body>
                                  </a>
                                </li>
                              )
                            })}
                          </ul>
                        )}
                      </div>
                    </li>
                    <li className="pt-4 pl-4 pr-4">
                      <div className="pb-4 border-b">
                        <a
                          href="https://www.koodomobile.com/my-account/overview"
                          target="_blank"
                          rel="noopener noreferrer"
                          tabIndex={0}
                        >
                          <Body>{selfServe}</Body>
                        </a>
                      </div>
                    </li>
                    <li className="p-4">
                      <a
                        href={`https://www.koodomobile.com/${currentLanguage}/help`}
                        target="_blank"
                        rel="noopener noreferrer"
                        tabIndex={0}
                      >
                        <Body>{getHelp}</Body>
                      </a>
                    </li>
                  </>
                )}

                {!isDealer() && isLoggedIn && (
                  <>
                    <li className="pl-4 pr-4">
                      <div className="pt-4 pb-4 border-b border-t">
                        <BodyBold textColor="text-primary" className="leading-6">
                          {user?.email?.toLowerCase()}
                        </BodyBold>
                      </div>
                    </li>
                    <li className="p-4">
                      <button onClick={handleLogout}>
                        <BodyBold className="leading-6">{logout}</BodyBold>
                      </button>
                    </li>
                  </>
                )}

                {isDealer() && (
                  <>
                    <li className="pl-4 pr-4">
                      <div className="pt-4 pb-4 border-b">
                        <BodyBold>
                          {repName}
                          &nbsp;
                          <span className="text-primary">{salesRepName}</span>
                        </BodyBold>
                      </div>
                    </li>
                    <li className="pl-4 pr-4">
                    <div className="pt-4 pb-4 border-b">
                        <BodyBold>
                          {channelorgCode}
                          &nbsp;
                          <span className="text-primary">{channelOrgCode}</span>
                        </BodyBold>
                      </div>

                      <div className="pt-4 pb-4 border-b">
                      <BodyBold>
                        &nbsp;
                        <span className="text-primary">
                          <a
                            id="needAssistanceUrl"
                            href={needAssistanceUrl}
                            className="text-primary underline"
                            onClick={(e) => {
                              e.preventDefault()
                              window.open(
                                needAssistanceUrl,
                                '_blank',
                                'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,personalbar=no,origin=no,menubar=no,width=400,height=725'
                              )
                            }}
                          >
                            {needAssistance}
                          </a>
                        </span>
                        &nbsp; &nbsp;
                        {callSupport}&nbsp;
                        <span className="text-primary">{callSupportPhoneNumber}</span>
                      </BodyBold>
                      </div>

                    </li>
                    {isLoggedIn && (
                      <li className="p-4">
                        <button onClick={handleLogout}>
                          <BodyBold className="leading-6">{logout}</BodyBold>
                        </button>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="fixed z-40 w-screen h-screen bg-black opacity-50" />
        </div>
      )}
    </>
  )
}

export default SiteHeader
