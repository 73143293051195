import React from 'react'
import PropTypes from 'prop-types'

const Link = ({
  tabIndex, children, href="#", onClick, target, id, className = '', testId = '', underline = true
}) => {

  const handleOnClick = (e) => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
  }

  return (
    <span role="link" className={`${className} cursor-pointer ${underline && 'underline'}`}>
      <a className="break-normal" tabIndex={tabIndex} href={href} title={id} target={target} onClick={(e) => handleOnClick(e)} data-testid={testId}>{children}</a>
    </span>


  )
}

Link.propTypes = {

}

export default Link
