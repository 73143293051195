/**
 * language-sensitive number currency formatting
 *
 * @param amount float
 * @param language en or fr
 * @return {string}
 */
export const currencyFormatter = (amount, language = 'en') => {
  const options = { style: 'currency', currency: 'CAD' }
  const numberFormat = new Intl.NumberFormat(`${language}-CA`, options)

  return numberFormat.format(amount).replace('CA', '').trim()
}
