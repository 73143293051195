/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { PHONE_NUMBER_OPTIONS, PORT_ORDER_AUTH_TYPE } from '@apps/utils/constants'
import { isNullOrEmpty } from '@apps/utils/common'

export const PHONE_NUMBER_STATE = {
  SELECTED_PHONE_NUMBER_OPTION: 'selectedPhoneNumberOption',
  SELECTED_PHONE_NUMBER: 'selectedPhoneNumber',
  CREATE_NEW_NUMBER: {
    PHONE_NUMBER: 'phoneNumber',
    PHONE_NUMBER_ERROR: 'phoneNumberError',
    PROVINCE_GROUP_ID: 'provinceGroupId',
    CITY_NAME: 'cityName',
    PROVINCE_GROUP_ID_ERROR: 'provinceGroupIdError'
  },
  TRANSFER_A_NUMBER: {
    PORT_IN_AREA_CODE: 'portInAreaCode',
    PORT_IN_THREE_DIGITS: 'portInThreeDigits',
    PORT_IN_FOUR_DIGITS: 'portInFourDigits',
    PREV_PORT_IN_PHONE_NUMBER: 'prevPortInPhoneNumber',
    PORT_IN_PHONE_NUMBER: 'portInPhoneNumber',
    PORT_IN_PHONE_NUMBER_HAS_ERROR: 'portInPhoneNumberHasError',
    PORT_IN_PHONE_NUMBER_ERROR_MESSAGE: 'portInPhoneNumberErroMessage',
    PORT_IN_PHONE_NUMBER_IS_LOADING: 'portInPhoneNumberIsLoading',
    IS_PORT_IN_NUMBER_ELIGIBLE: 'isPortInNumberEligible',
    IS_PREV_PORT_IN_NUMBER_ELIGIBLE: 'isPrevPortInNumberEligible',
    IS_PORT_IN_AGREEMENT_CHECKED: 'isPortInAgreementChecked',
    IS_PORT_IN_HAS_AGREEMENT_ERROR: 'isPortInHasAgreementError',
    IS_PORT_IN_WIRELESS: 'isPortInWireless',
    ALTERNATE_CONTACT_NUMBER: 'alternateContactNumber',
    ALTERNATE_CONTACT_NUMBER_ERROR: 'alternateContactNumberError',
    WIRELINE: {
      FIRST_NAME: 'firstNameLandline',
      LAST_NAME: 'lastNameLandline',
      STREET_NUMBER: 'streetNumber',
      STREET_NAME: 'streetName',
      CITY_NAME: 'cityName',
      SELECTED_PROVINCE_LANDLINE: 'selectedProvinceLandline',
      POSTAL_CODE_LANDLINE: 'postalCodeLandline',
      INTERNET_AND_TV_SERVICES: 'internetAndTvServices',
      FIRST_NAME_ERROR: 'firstNameLandlineError',
      LAST_NAME_ERROR: 'lastNameLandlineError',
      STREET_NUMBER_ERROR: 'streetNumberError',
      STREET_NAME_ERROR: 'streetNameError',
      CITY_NAME_ERROR: 'cityNameError',
      SELECTED_PROVINCE_LANDLINE_ERROR: 'selectedProvinceLandlineError',
      POSTAL_CODE_LANDLINE_ERROR: 'postalCodeLandlineError',
      INTERNET_AND_TV_SERVICES_ERROR: 'internetAndTvServicesError'
    },
    WIRELESS: {
      SELECTED_SPID: 'selectedSpid',
      SELECTED_SPID_ERROR: 'selectedSpidError',
      IMEI: 'selectedImei',
      IMEI_ERROR: 'selectedImeiError',
      PREVIOUS_PROVIDER_ACCOUNT_NUMBER: 'previousProviderAccountNumber',
      PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR: 'previousProviderAccountNumberError'
    }
  },
  IS_COMPLETE: 'isComplete',
  PROVINCE_ID: 'provinceId',
  PROVINCE_NAME: 'provinceName',
  PROVINCE_ID_ERROR: 'provinceIdError'
}

export const createNewNumberState = {
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: '',
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER_ERROR]: '',
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: '',
  [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
}

export const transferANumberCommonState = {
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]: undefined,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]: undefined,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_HAS_AGREEMENT_ERROR]: false,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: undefined,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]: ''
}

export const transferANumberWirelessState = {
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID]: null,
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR]: ''
}

export const transferANumberWirelineState = {
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR]: '',
  [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]: ''
}

const requiredFields = {
  [PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER]: [
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER],
    [PHONE_NUMBER_STATE.PROVINCE_ID],
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]
  ],
  [PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER]: {
    wireless: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]
    ],
    wireline: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]
    ]
  }
}

const errorFields = {
  [PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER]: [
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER_ERROR],
    [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR],
    [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]
  ],
  [PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER]: {
    wireless: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]
    ],
    wireline: [
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR],
      [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]
    ]
  }
}

export const PhoneNumberComplete = (newState) => {
  const flow = newState[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]
  let fields = requiredFields[newState[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]]
  let errors = errorFields[newState[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]]
  const isWireless = newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]

  if (flow === PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER) {
    if (isWireless) {
      // if no account num or imei
      if (!isNullOrEmpty(newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI])) {
        if (!isNullOrEmpty(newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR])) {
          return false
        }
      } else if (
        !isNullOrEmpty(
          newState[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]
        )
      ) {
        if (
          !isNullOrEmpty(
            newState[
              PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR
            ]
          )
        ) {
          return false
        }
      } else {
        return false
      }
    }

    fields = isWireless
      ? requiredFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireless
      : requiredFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireline

    errors = isWireless
      ? errorFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireless
      : errorFields[PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER].wireline
  }

  if (fields && errors) {
    const emptyRequiredField = fields.find((field) => isNullOrEmpty(newState[field]))
    if (emptyRequiredField) {
      return false
    }

    const hasError = errors.find((error) => !isNullOrEmpty(newState[error]))
    if (hasError) {
      return false
    }

    return true
  }
}

export const phoneNumberPage = createSlice({
  name: 'phoneNumberPage',
  initialState: {
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER,
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: '',
    [PHONE_NUMBER_STATE.IS_COMPLETE]: false,
    ...createNewNumberState,
    ...transferANumberCommonState,
    ...transferANumberWirelessState,
    ...transferANumberWirelineState,
    [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
    [PHONE_NUMBER_STATE.PROVINCE_NAME]: '',
    [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
  },
  reducers: {
    setSelectedPhoneNumber: (state, action) => {
      state.selectedPhoneNumber = action.payload
    },
    setSelectedPhoneOption: (state, action) => {
      state.selectedPhoneNumberOption = action.payload
    },
    recordPhoneNumberInfo: (state, action) => {
      if (action.payload.portIn === null) return
      const { portIn, phoneNumber, provinceGroupId, phoneNumberId, cityName } = action.payload

      let updateState = {}

      const updatedPhoneNumber = phoneNumber?.startsWith('1')
        ? phoneNumber?.substring(1)
        : phoneNumber
      const areaCode = updatedPhoneNumber?.substring(0, 3)
      const threeDigits = updatedPhoneNumber?.substring(3, 6)
      const fourDigits = updatedPhoneNumber?.substring(6, 10)

      if (portIn && action.payload.portInformation !== null) {
        const {
          portInformation: {
            isWireless,
            authType,
            authValue,
            contactNumber,
            serviceProvider,
            serviceAction,
            billing
          }
        } = action.payload

        const formattedContactPhoneNumber =
          contactNumber?.substring(0, 3) +
          ' ' +
          contactNumber?.substring(3, 6) +
          ' ' +
          contactNumber.substring(6, 10)

        let imeiNumber = ''
        let previousServiceProviderAccountNumber = ''
        if (authType === PORT_ORDER_AUTH_TYPE.SERIAL_NUMBER) {
          imeiNumber = authValue
        } else if (authType === PORT_ORDER_AUTH_TYPE.ACCOUNT) {
          previousServiceProviderAccountNumber = authValue
        }

        updateState = {
          [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER,
          [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: phoneNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: updatedPhoneNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: updatedPhoneNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]: areaCode,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]: threeDigits,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]: fourDigits,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER
            .ALTERNATE_CONTACT_NUMBER]: formattedContactPhoneNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS
            .PREVIOUS_PROVIDER_ACCOUNT_NUMBER]: previousServiceProviderAccountNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]: imeiNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID]: serviceProvider,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: isWireless,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.CITY_NAME]: billing?.billingCity,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME]: billing?.billingFirstName,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME]: billing?.billingLastName,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]:
            billing?.billingPostalCode,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]:
            billing?.billingProvince,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NAME]: billing?.billingStreetName,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.STREET_NUMBER]:
            billing?.billingStreetNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: serviceAction
        }
      } else {
        updateState = {
          [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]:
            PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER,
          [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: phoneNumber,
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER]: {
            id: phoneNumberId,
            value: phoneNumber,
            label: `(${areaCode}) ${threeDigits} - ${fourDigits}`
          },
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.CITY_NAME]: cityName,
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: provinceGroupId
        }
      }
      const newState = Object.assign(state, updateState)
      const isComplete = PhoneNumberComplete(newState)
      newState[PHONE_NUMBER_STATE.IS_COMPLETE] = isComplete
      
      state = {
        ...newState
      }
    },
    updatePhoneNumberState: (state, action) => {
      const newState = Object.assign(state, action.payload)
      const isComplete = PhoneNumberComplete(newState)
      newState[PHONE_NUMBER_STATE.IS_COMPLETE] = isComplete
      state = {
        ...newState
      }
    }
  }
})

// this is for dispatch
export const {
  setSelectedPhoneNumber,
  setSelectedPhoneOption,
  updatePhoneNumberState,
  recordPhoneNumberInfo
} = phoneNumberPage.actions

// this is for configureStore
export default phoneNumberPage.reducer
