/* eslint-disable import/extensions */
import React, { useMemo, useContext } from 'react'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { useSelector } from 'react-redux'
import { getById, getBySlug } from '@apps/utils/contentful'
import Link from '@/apps/components/Link'
import RoundedButton from '@/apps/components/RoundedButton'
import Heading4 from '@/apps/components/Typography/Heading4'

const GetNewPSimCard = () => {
  const { pageContent } = useSelector((state) => state.common.content)
  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)
  const haveSimPage = getById(pageContent, `haveSimPage`)[language]
  const { shortTextFields } = haveSimPage

  const pageTextContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      needASIM: getBySlug(shortTextFields, 'needASIM')?.value,
      order: getBySlug(shortTextFields, 'order')?.value,
      here: getBySlug(shortTextFields, 'here')?.value,
      locateStore: getBySlug(shortTextFields, 'locateStore')?.value,
      orderLink: getBySlug(shortTextFields, 'orderLink')?.value,
      orVisit: getBySlug(shortTextFields, 'orVisit')?.value,
      orderSimCardOnline: getBySlug(shortTextFields, 'orderSimCardOnline')?.value,
      store: getBySlug(shortTextFields, 'store')?.value,
      storeLink: getBySlug(shortTextFields, 'storeLink')?.value,
      headsUp: getBySlug(shortTextFields, 'headsUp')?.value,
      onlinePurchaseMessage: getBySlug(shortTextFields, 'onlinePurchaseMessage')?.value
    }
  }, [shortTextFields])

  return (
    <div className="mt-4 pt-6 border-t">
      <div className="flex flex-col sm:flex-row sm:justify-between">
        <div className="w-full">
          <Link target="_blank" href={pageTextContent?.orderLink} underline={false}>
            <RoundedButton label={pageTextContent?.orderSimCardOnline} />
          </Link>
        </div>
        <div className="w-2/12 self-center">
          <Heading4 className="text-sm font-h3 leading-6 text-center">
            {pageTextContent?.orVisit}
          </Heading4>
        </div>
        <div className="w-full">
          <Link target="_blank" href={pageTextContent.storeLink} underline={false}>
            <RoundedButton label={pageTextContent?.locateStore} />
          </Link>
        </div>
      </div>
      <div className="mt-6">
        <span className="font-h3 inline text-xs font-bold leading-5">{`${pageTextContent?.headsUp} `}</span>
        <span className="text-copy font-body text-xs leading-5 font-normal">
          {pageTextContent?.onlinePurchaseMessage}
        </span>
      </div>
    </div>
  )
}

export default GetNewPSimCard
