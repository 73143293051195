import React from 'react'
import useBrand from '@engine/hooks/useBrand'
import Brands from '@brands'
import PropTypes from 'prop-types'

const Theme = ({ children }) => {
  const brand = useBrand()
  let theme

  switch (brand) {
    case Brands.public:
      theme = 'theme-public-mobile'
      break
    case Brands.koodo:
      theme = 'theme-koodo-prepaid'
      break
    // case Brands.anelletti:
    //   theme = 'theme-public-mobile'
    //   break
    // case Brands.pasta:
    //   theme = 'theme-koodo-prepaid'
    //   break
    default:
      theme = ''
      break
  }

  return <div className={`${theme}`}>{children}</div>
}

Theme.propTypes = {
  children: PropTypes.node.isRequired
}

// Theme.defaultProps = {
// }

export default Theme
