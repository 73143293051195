import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { ContentContext } from '@apps/contexts/ContentContext'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import { logOut } from '@apps/redux/features/CommonSlice'
import Button from '@apps/components/Button'
import BodyBold from '@apps/components/Typography/BodyBold'
import { useLocation } from 'react-router'
import { setPageAnalytics } from '@apps/utils/Analytics'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import Heading1 from '@/apps/components/Typography/Heading1'
import Body from '@/apps/components/Typography/Body'
import { useDispatch, useSelector } from 'react-redux'
import { getBySlug, getById, getImgByTitle } from '@apps/utils/contentful'


const AlreadyActivated = () => {

  const { pageContent } = useSelector((state) => state.common.content)
  const dealerActivationPaths = new DealerActivationPaths()
  const { dynamicContent } = useContext(ContentContext)
  const dispatch = useDispatch()

  let history = useHistory()
  if (dynamicContent && dynamicContent.accountSetupPage.featureFlags.showSplashing) {
    history.push(dealerActivationPaths.Splash)
  }

  const { [PLANS_STATE.PLAN]: plan } = useSelector((state) => state.plansPage)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region,
    [APPLICATION_STATE.SERVICES]: brandServices
  } = useContext(ApplicationContext)

   const { retrieveCookie, deleteCookie } = brandServices

  const location = useLocation()
  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
  }, [])

  const activatePage = getById(pageContent, 'activateAlreadyPageDealer')[language]
  const [showPlanError, setShowPlanError] = useState(false)
  const planErrorRef = useRef(null)
  const handleNextClick = async () => {
    if (plan && plan.id) {
      history.push(dealerActivationPaths.Payment)
    } else {
      setShowPlanError(true)
      planErrorRef.current.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

  const redirectToLandingPage = () => {
    window.location = dealerActivationPaths.Base
  }

const handleLogout = async () => {
  const { userSessionId, userEmail, isLoggedIn} = {} = await retrieveCookie() || {}
  
  if (userSessionId) {
    await deleteCookie()
    dispatch(logOut())
    redirectToLandingPage()
  } else {
    dispatch(logOut())
    redirectToLandingPage()
  }
}

  return (
    <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto space-y-4 justify-center items-center mt-8">
      <Heading1 className="text-primary max-w-lg text-center">
          {getBySlug(activatePage?.shortTextFields, 'already-been-activated-dealer')?.value}
      </Heading1>

      <Body className="md:w-3/4 text-center">
        {getBySlug(activatePage?.shortTextFields, 'already-been-activated-body-dealer')?.value}
      </Body>

      <div>
        <img className="w-full h-full object-contain pl-20" alt="Colorful Piñata" src={getImgByTitle(activatePage, 'pinata-dealer')?.url} />
      </div>

      <div className="w-full sm:w-2/4">
      <Button
        onClick={() => handleNextClick()}
        label={getBySlug(activatePage?.shortTextFields, 'go-to-self-service-portal')?.value}
        className="w-full my-4 font-h4"
        testId="plans-nextStep"
        />
      </div>
   
      <div className="mx-auto mb-6">
        <button className="flex items-center justify-start" onClick={handleLogout}>
          <BodyBold className="pl-2 text-h1 underline">{getBySlug(activatePage?.shortTextFields, 'go-back-portal-dealer')?.value}</BodyBold>
        </button>
      </div>
    </div>
  )
}

export default AlreadyActivated
