class ActivationSteps {
  /**
   * @param {string} activationPaths the ActivationPaths used in this flow
   */
  constructor(activationPaths, preSelectedPlan, labels = {}) {
    this.activationPaths = activationPaths
    this.preSelectedPlan = preSelectedPlan
    this.labels = labels
  }

  get Steps() {
    const steps = [this.Base, this.AccountSetup, this.SimSelection, this.ChooseNumber]
    if (this.preSelectedPlan) {
      steps.push(this.Payment)
    } else {
      steps.push(this.Plans, this.Payment)
    }
    return steps
  }

  get NavigationSteps() {
    return this.Steps.slice(1)
  }

  get Base() {
    return { number: 0, path: this.activationPaths.Base, label: this.labels?.base }
  }

  get AccountSetup() {
    return { number: 1, path: this.activationPaths.AccountSetup, label: this.labels?.accountSetup }
  }

  get SimSelection() {
    return { number: 2, path: this.activationPaths.SimSelection, label: this.labels?.simSelection }
  }

  get ChooseNumber() {
    return { number: 3, path: this.activationPaths.ChooseNumber, label: this.labels?.chooseNumber }
  }

  get Plans() {
    return { number: 4, path: this.activationPaths.Plans, label: this.labels?.plans }
  }

  get Payment() {
    const number = this.preSelectedPlan ? 4 : 5
    return { number, path: this.activationPaths.Payment, label: this.labels?.payment }
  }

  getStepByNumber(number) {
    return this.Steps.find((step) => step.number === number)
  }
}

export default ActivationSteps
