import React, { useMemo, useContext } from 'react'
import { ContentContext } from '@apps/contexts/ContentContext'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getById } from '@apps/utils/contentful'

const PhoneCompatibility = ({ feature, compatible }) => {
  const { dynamicContent } = useContext(ContentContext)

  const { haveSimPage = {} } = dynamicContent || {}
  const { content, shortTextFields } = haveSimPage
  const pageContent = useMemo(() => {
    if (!content && !shortTextFields) return {}
    return {
      successIcon: getById(content, 'success-icon')?.image?.url,
      failIcon: getById(content, 'fail-icon')?.image?.url
    }
  }, [content, shortTextFields])

  const getCompatibilityIcon = (isCompatible) => {
    return isCompatible ? pageContent?.successIcon : pageContent?.failIcon
  }

  const getCompatibilityColor = (isCompatible) => {
    return isCompatible ? 'text-green-success' : 'text-alert'
  }

  return (
    <p
      className={classNames(getCompatibilityColor(compatible), 'font-bodySmall font-semibold mb-2')}
    >
      <img
        alt="eSim compatibility icon"
        src={getCompatibilityIcon(compatible)}
        className="inline-block mr-4"
      />
      {feature}
    </p>
  )
}

PhoneCompatibility.propTypes = {
  feature: PropTypes.string.isRequired,
  compatible: PropTypes.bool.isRequired
}

export default PhoneCompatibility
