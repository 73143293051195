/* eslint-disable react/require-default-props */
import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from '@apps/components/MaskedInput'
import ValidationCard from '@apps/components/Cards/ValidationCard'
import BodySmall from '@apps/components/Typography/BodySmall'
import BodyError from '@apps/components/Typography/BodyError'
import Heading3 from '@apps/components/Typography/Heading3'
import {
  ACCOUNT_SETUP_STATE,
  updateAccountSetupState
} from '@apps/redux/features/AccountSetupSlice'
import { useDispatch, useSelector } from 'react-redux'
import AccountSetupChangeHandlers, {
  ACCOUNT_SETUP_EVENTS
} from '@apps/utils/ChangeHandlers/AccountSetupChangeHandlers'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { getShortTextFromPage, getById } from '@apps/utils/contentful'
import Sites from '@sites/index'

export const PIN = ({ showToggle = true, pinRef, handleBlurPin, handleBlurConfirmPin }) => {
  const dispatch = useDispatch()
  const { pageContent } = useSelector((state) => state.common.content)
  const { userType } = useSelector((state) => state.common)
  const accountSetupState = useSelector((state) => state.accountSetupPage)

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)

  const {
    [ACCOUNT_SETUP_STATE.PIN]: pin,
    [ACCOUNT_SETUP_STATE.CONFIRM_PIN]: confirmPin,
    [ACCOUNT_SETUP_STATE.PIN_ERRORS]: {
      [ACCOUNT_SETUP_STATE.IS_INVALID_PIN_LENGTH]: isInvalidPINLength,
      [ACCOUNT_SETUP_STATE.PIN_STARTS_WITH_ZERO]: pinStartsWithZero,
      [ACCOUNT_SETUP_STATE.PIN_ONE_TWO_THREE_FOUR]: pinOneTwoThreeFour,
      [ACCOUNT_SETUP_STATE.PIN_SINGLE_REPEATING_DIGIT]: pinSingleRepeatingDigit,
      [ACCOUNT_SETUP_STATE.SHOW_REQUIREMENTS]: showRequirements,
      [ACCOUNT_SETUP_STATE.PIN_MATCHES]: pinMatches,
      [ACCOUNT_SETUP_STATE.CONFIRM_PIN_REQUIRED]: confirmPinRequired,
      [ACCOUNT_SETUP_STATE.PIN_REQUIRED]: pinRequired
    }
  } = accountSetupState
  
  const PIN_LENGTH = 4

  const text = useMemo(() => {
    if (!pageContent || !language || !userType) return {}
    const contentSuffix = userType === Sites.dealer ? 'Dealer' : 'Consumer'
    const accountSetupPageContent = getById(pageContent, `accountSetupPage${contentSuffix}`)?.[
      language
    ]
    return {
      confirmPinLabel: getShortTextFromPage(accountSetupPageContent, 'confirmPinLabel'),
      createPinStep1: getShortTextFromPage(accountSetupPageContent, 'createPinStep1'),
      createPinStep2: getShortTextFromPage(accountSetupPageContent, 'createPinStep2'),
      createPinStep3: getShortTextFromPage(accountSetupPageContent, 'createPinStep3'),
      pinErrorDigits: getShortTextFromPage(accountSetupPageContent, 'pinErrorDigits'),
      pinErrorMismatch: getShortTextFromPage(accountSetupPageContent, 'pinErrorMismatch'),
      pinErrorOneNumber: getShortTextFromPage(accountSetupPageContent, 'pinErrorOneNumber'),
      pinErrorRequired: getShortTextFromPage(accountSetupPageContent, 'pinErrorRequired'),
      pinErrorStartsZero: getShortTextFromPage(accountSetupPageContent, 'pinErrorStartsZero'),
      pinLabel: getShortTextFromPage(accountSetupPageContent, 'pinLabel'),
      pinRequirementsTitle: getShortTextFromPage(accountSetupPageContent, 'pinRequirementsTitle'),
      pinSubtitle: getShortTextFromPage(accountSetupPageContent, 'pinSubtitle'),
      pinTitle: getShortTextFromPage(accountSetupPageContent, 'pinTitle')
    }
  }, [pageContent, language, userType])

  const {
    confirmPinLabel,
    createPinStep1,
    createPinStep2,
    createPinStep3,
    pinErrorDigits,
    pinErrorMismatch,
    pinErrorOneNumber,
    pinErrorRequired,
    pinErrorStartsZero,
    pinLabel,
    pinRequirementsTitle,
    pinSubtitle,
    pinTitle
  } = text

  const confirmPINErrorMessage = () => {
    if (confirmPinRequired) {
      return pinErrorRequired
    }

    if (pinMatches) {
      return pinErrorMismatch
    }

    return ''
  }

  const pinErrorState = () => {
    if (
      pinRequired ||
      isInvalidPINLength ||
      pinStartsWithZero ||
      pinOneTwoThreeFour ||
      pinSingleRepeatingDigit
    ) {
      return true
    }
    return false
  }

  const confirmPinErrorState = () => {
    if (confirmPinRequired || pinMatches) {
      return true
    }
    return false
  }

  const setAccountSetupState = (payload) => {
    dispatch(updateAccountSetupState(payload))
  }

  const handlePinFocus = () => {
    AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.PIN].onFocus(
      accountSetupState,
      setAccountSetupState
    )
  }

  const handlePinChange = (e) => {
    AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.PIN].onChange(
      e.target.value,
      setAccountSetupState
    )
  }

  const handleConfirmPinChange = (e) => {
    AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.CONFIRM_PIN].onChange(
      e.target.value,
      setAccountSetupState
    )
  }

  return (
    <div className="space-y-6">
      <div>
        <Heading3 className="mt-0 sm:mt-14">{pinTitle}</Heading3>
        <BodySmall className="mt-2 leading-5">{pinSubtitle}</BodySmall>
        <ul className="list-disc list-outside text-copy px-8 ">
          <li>
            <BodySmall className="inline leading-5">{createPinStep1}</BodySmall>
          </li>
          <li>
            <BodySmall className="inline leading-5">{createPinStep2}</BodySmall>
          </li>
          <li>
            <BodySmall className="inline leading-5">{createPinStep3}</BodySmall>
          </li>
        </ul>
      </div>
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-y-0">
        <div ref={pinRef} className="w-full sm:w-1/2 space-y-4 lg:mr-3">
          <MaskedInput
            label={pinLabel}
            onFocus={handlePinFocus}
            onBlur={handleBlurPin}
            onChange={handlePinChange}
            minLength={`${PIN_LENGTH}`}
            maxLength={`${PIN_LENGTH}`}
            errorMessage={pinRequired ? pinErrorRequired : ''}
            errorState={pinErrorState()}
            value={pin}
            showToggle={showToggle}
            data-testid="accountSetup-pin"
          />
          <MaskedInput
            label={confirmPinLabel}
            onBlur={handleBlurConfirmPin}
            onChange={handleConfirmPinChange}
            onPaste={(e) => e.preventDefault()}
            minLength={`${PIN_LENGTH}`}
            maxLength={`${PIN_LENGTH}`}
            errorMessage={confirmPINErrorMessage()}
            errorState={confirmPinErrorState()}
            value={confirmPin}
            showToggle={showToggle}
            data-testid="accountSetup-confirmPin"
          />
        </div>
        {showRequirements && (
          <div className="lg:ml-3 flex-1 sm:pt-7">
            <ValidationCard
              title={pinRequirementsTitle}
              showCuts={true}
              visibility={showRequirements ? 'block' : 'hidden'}
            >
              <ul className="list-disc list-outside ml-4">
                <li>
                  {isInvalidPINLength ? (
                    <BodyError className="inline font-bodySmall tracking-wider">
                      {pinErrorDigits}
                    </BodyError>
                  ) : (
                    <BodySmall className="inline">{pinErrorDigits}</BodySmall>
                  )}
                </li>
                <li>
                  {pinStartsWithZero || pinOneTwoThreeFour ? (
                    <BodyError className="inline font-bodySmall tracking-wider">
                      {pinErrorStartsZero}
                    </BodyError>
                  ) : (
                    <BodySmall className="inline">{pinErrorStartsZero}</BodySmall>
                  )}
                </li>
                <li>
                  {pinSingleRepeatingDigit ? (
                    <BodyError className="inline font-bodySmall tracking-wider">
                      {pinErrorOneNumber}
                    </BodyError>
                  ) : (
                    <BodySmall className="inline">{pinErrorOneNumber}</BodySmall>
                  )}
                </li>
              </ul>
            </ValidationCard>
          </div>
        )}
      </div>
    </div>
  )
}

PIN.propTypes = {
  showToggle: PropTypes.bool
}

export default PIN
