import React from 'react'
import PropTypes from 'prop-types'
import Heading4 from '@apps/components/Typography/Heading4'
import Body from '../Typography/Body'

const InfoCard = ({ heading, listItems = [] }) => {
  const items = listItems && listItems.map((item, i) => {
    return (
      <Body key={i}>
        {item}
      </Body>
    )
  })
  return (
    <div className="relative w-full overflow-hidden text-black rounded bg-card-background border-2 border-card-cutout">
      <div className="absolute w-2 h-full bg-card-cutout left-0"></div>
      <div className="px-8 py-6">
        <Heading4>
          {heading}
        </Heading4>
        {items}
      </div>
    </div>
  )
}
InfoCard.propTypes = {
  heading: PropTypes.string.isRequired,
  listItems: PropTypes.array.isRequired
}

export default InfoCard
