import React from "react"
import PropTypes from 'prop-types'

const Body = ({
  id, children, className, textColor
}) => {
  return (
    <p className={`${textColor || 'text-copy'} text-sm font-body ${className}`} id={id}>
      {children}
    </p>
  )
}
Body.prototype = {
  id: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  textColor: PropTypes.string
}

export default Body
