import React from "react"
import PropTypes from 'prop-types'

const Heading3 = ({id, children, className}) => {
  return (
    <h3 className={className} id={id}>
      {children}
    </h3>
  )
}
Heading3.prototype = {
  id: PropTypes.string,
  children: PropTypes.string,
  styles: PropTypes.string
}

export default Heading3