import React, { useContext, useEffect, useMemo, useState } from 'react'
import cookie from 'react-cookies'
import { Route, Switch } from 'react-router-dom'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import { ErrorBoundary } from 'react-error-boundary'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'

import NewPhoneNumberPage from '@sites/shared/PhoneNumberPage'
import PlansPage from '@sites/shared/PlansPage'

import AccountSetupPage from '@sites/shared/AccountSetupPage'
import NewPlanPage from '@sites/shared/NewPlanPage'
import PaymentPage from '@sites/consumer/activation/PaymentPage'
import IntroPage from '@sites/consumer/activation/IntroPage'
import TransactionSummaryPage from '@sites/shared/TransactionSummaryPage'
import SimSelectionPage from '@sites/shared/SimSelectionPage'
import PaymentSummary from '@sites/consumer/activation/PaymentSummary'
import Sites from '@sites/index'

import SiteHeader from '@apps/components/SiteHeader'
import { LANGUAGES_CODE, PAGE_NUMBER } from '@apps/utils/constants'
import ContentProvider from '@apps/contexts/ContentContext'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import useBrand from '@engine/hooks/useBrand'
import Brands from '@brands/index'

import koodoEnglish from '@apps/flows/activation/content/koodo/en.json'
import koodoFrench from '@apps/flows/activation/content/koodo/fr.json'
import publicEnglish from '@apps/flows/activation/content/public/en.json'
import publicFrench from '@apps/flows/activation/content/public/fr.json'
import activationEnglish from '@apps/flows/activation/content/en.json'
import activationFrench from '@apps/flows/activation/content/fr.json'

import consumerEnglish from '@sites/consumer/content/en.json'
import consumerFrench from '@sites/consumer/content/fr.json'
import StepTracker from '@apps/components/StepTracker'
import ContentService from 'services/ContentService'
import ErrorSection from '@apps/flows/activation/sections/ErrorSection'

import { useDispatch, useSelector } from 'react-redux'
import { setUserType } from '@apps/redux/features/CommonSlice'
import { updateAccountSetupState } from '@apps/redux/features/AccountSetupSlice'
import AlreadyActivated from '@sites/consumer/activation/AlreadyActivated'
import ProcessLoader from '@sites/consumer/activation/ProcessLoader'
import ProcessFailure from '@sites/consumer/activation/ProcessFailure'
import Loader, { SIZE } from '@apps/components/Loader'

const ActivationMount = () => {
  const dispatch = useDispatch()
  const brand = useBrand()
  const plansState = useSelector((state) => state.plansPage)
  const { [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan } = plansState
  const [dynamicContent, setDynamicContent] = useState(undefined)

  const consumerActivationPaths = new ConsumerActivationPaths()
  const {
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage,
    [APPLICATION_STATE.IS_LANDING_LOGIC_NOT_COMPLETE]: is_landing_logic_not_complete,
  } = useContext(ApplicationContext)

  const provinces = useMemo(() => {
    return mappedProvinceList?.map((item) => {
      const { label, value, shortName } = item
      return {
        value,
        name: label[currentLanguage],
        shortName
      }
    })
  }, [mappedProvinceList])

  const provinceCookie = cookie.load('prov')
  useEffect(() => {
    if (provinces && provinceCookie) {
      const province = provinces?.find(
        (prov) => prov.shortName?.toLowerCase() === provinceCookie.toLowerCase()
      )
      if (province) {
        dispatch(updateAccountSetupState({ province: province.value }))
      }
    }
  }, [provinces, provinceCookie])

  const koodoContent = {
    [LANGUAGES_CODE.EN]: [activationEnglish, koodoEnglish, consumerEnglish],
    [LANGUAGES_CODE.FR]: [activationFrench, koodoFrench, consumerFrench]
  }

  const publicContent = {
    [LANGUAGES_CODE.EN]: [activationEnglish, publicEnglish, consumerEnglish],
    [LANGUAGES_CODE.FR]: [activationFrench, publicFrench, consumerFrench]
  }

  let contentHierarchy

  if (brand === Brands.koodo) {
    contentHierarchy = koodoContent
  } else if (brand === Brands.public) {
    contentHierarchy = publicContent
  }

  useEffect(() => {
    const currentPath = window.location.pathname
    if (
      currentPath !== consumerActivationPaths.Base &&
      currentPath !== consumerActivationPaths.Splash &&
      !currentPath.includes('/plans/')
    ) {
      window.location = consumerActivationPaths.Base
    }

    dispatch(setUserType(Sites.consumer))

    // fetch supported languages from contentful and apply to state model
    ContentService.getConsumerPageContent().then((consumerContent) => {
      setDynamicContent(consumerContent)
    })
  }, [])

  return (
    <>
      <ContentProvider
        language={currentLanguage}
        contentHierarchy={contentHierarchy}
        dynamicContent={dynamicContent}
      >
        <ErrorBoundary fallback={<ErrorSection />}>
          <SiteHeader />
          {is_landing_logic_not_complete && (
            <div className={`fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-100 overflow-hidden bg-white flex flex-col items-center justify-center ${
              is_landing_logic_not_complete === true ? 'opacity-100' : 'opacity-50'
            }`}>
              <Loader size={SIZE.MD} loading />
            </div>
          )}
          <Switch>
            <Route exact path={consumerActivationPaths.Base}>
              <IntroPage path={consumerActivationPaths.Base} />
            </Route>
            <Route path={consumerActivationPaths.AccountSetup}>
              <StepTracker activeStepIndex={PAGE_NUMBER.ACCOUNT_SETUP} />
              <AccountSetupPage />
              <PaymentSummary />
            </Route>
            <Route path={consumerActivationPaths.ChangeNewPlan}>
              <NewPlanPage />
            </Route>
            <Route path={consumerActivationPaths.ChangeExistingPlan}>
              <NewPlanPage />
            </Route>
            <Route path={consumerActivationPaths.Plan}>
              <IntroPage path={consumerActivationPaths.Base} />
              <PaymentSummary />
            </Route>
            <Route path={consumerActivationPaths.SimSelection}>
              <StepTracker activeStepIndex={PAGE_NUMBER.SIM_SELECTION} />
              <SimSelectionPage />
              <PaymentSummary />
            </Route>
            <Route path={consumerActivationPaths.ChooseNumber}>
              <StepTracker activeStepIndex={PAGE_NUMBER.CHOOSE_NUMBER} />
              <NewPhoneNumberPage />
              <PaymentSummary />
            </Route>
            <Route path={consumerActivationPaths.Plans}>
              <StepTracker activeStepIndex={PAGE_NUMBER.PLAN_SELECTION} />
              <PlansPage />
              <PaymentSummary />
            </Route>
            <Route path={consumerActivationPaths.Payment}>
              <StepTracker
                activeStepIndex={
                  preSelectedPlan ? PAGE_NUMBER.PLAN_PRESELECTED_PAYMENT : PAGE_NUMBER.PAYMENT
                }
              />
              <PaymentPage />
              <PaymentSummary />
            </Route>
            <Route path={consumerActivationPaths.ProcessLoader}>
              <ProcessLoader />
            </Route>
            <Route path={consumerActivationPaths.ProcessFailure}>
              <ProcessFailure />
            </Route>
            <Route path={consumerActivationPaths.TransactionSummary}>
              <TransactionSummaryPage />
            </Route>
            <Route path={consumerActivationPaths.AlreadyActivated}>
              <AlreadyActivated />
            </Route>
            <Route path={consumerActivationPaths.Splash}>
              <div className="mt-10">
                <ErrorSection />
              </div>
            </Route>
            <Route path="*">
              <div className="mt-10">
                <ErrorSection errorNum={404} />
              </div>
            </Route>
          </Switch>
        </ErrorBoundary>
      </ContentProvider>
    </>
  )
}

export default ActivationMount
