/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useContext, useEffect, useRef, useMemo
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import CreditDebitCard from '@apps/components/CreditDebitCard'
import { getById, getImgByTitle } from '@apps/utils/contentful'
import Input from '@apps/components/Input'
import Button from '@apps/components/Button'
import AccountHelpers, { VOUCHER_STATE } from '@apps/flows/activation/services/accountHelper'
import Checkbox from '@apps/components/Checkbox'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import BodySmall from '@apps/components/Typography/BodySmall'
import BodyError from '@apps/components/Typography/BodyError'
import Body from '@apps/components/Typography/Body'
import EPSCard from '@apps/components/CreditDebitCard/EPSCard'
import {
  PAYMENT_STATE,
  updatePaymentStates
} from '@apps/redux/features/PayAndReviewSlice'
import { currencyFormatter } from '@apps/utils/CurrencyFormatter'

const VoucherOptionSection = ({ showErrors, handleSIMActivation, textContent, setButtonIsLoading }) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      koodoCutLeft: getImgByTitle(commonElements, 'koodoCutLeft')?.url,
      verifiedCheckMark: getImgByTitle(commonElements, 'verifiedCheckMark')?.url,
      close: getImgByTitle(commonElements, 'close')?.url,
      error: getImgByTitle(commonElements, 'error')?.url
    }
  }, [pageContent, selectedLanguage])

  const [voucherCode, setVoucherCode] = useState('')
  const [voucherCodeErrorMessage, setVoucherCodeErrorMessage] = useState('')
  const [isValidCode, setIsValidCode] = useState(false)
  const [isVoucherLoading, setIsVoucherLoading] = useState(false)
  const dispatch = useDispatch()
  const { paymentInformation: paymentInfo } = useSelector((state) => state.payAndReviewPage)
  const [autoTopUps, setAutoTopUps] = useState(true)

  const {
    [APPLICATION_STATE.SERVICES]: brandServices,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage
  } = useContext(ApplicationContext)

  const paymentState = useSelector((state) => state.payAndReviewPage)
  const voucherRequiredRef = useRef(null)

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const {
    [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: addedVouchers,
    [PAYMENT_STATE.AUTO_TOP_UPS]: isRegisterTopupEnabled,
    [PAYMENT_STATE.VOUCHER.INSUFFICIENT_AMOUNT_ERROR]: hasInsufficientVoucherCodeError,
    [PAYMENT_STATE.PAYMENT_GATEWAY]: paymentGateway,
    [PAYMENT_STATE.SUMMARY.AMOUNT_DUE]: amountDue,
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_LOADER]: isMultiPromoLoading
  } = paymentState

  const { voucherInfo } = brandServices

  const mounted = useRef(false)
  useEffect(() => {

    if (mounted.current && voucherCode?.length !== 0) {
      validateVoucherCode()
      addVoucherCode()
    } else {
      mounted.current = true
    }

    if (voucherCode?.length === 0) {
      setVoucherCodeErrorMessage('')
    }

    if (mounted.current && voucherCode?.length === 0 &&  voucherCodeErrorMessage !== '') {
      validateVoucherCode()
      addVoucherCode()
    } else {
      mounted.current = true
    }
  }, [currentLanguage, validateVoucherCode, addVoucherCode])


  const handleVoucherCodeChange = (e) => {
    e.preventDefault()
    const { value } = e.target
    if (/^[a-zA-Z0-9]*$/.test(value)) {
      setVoucherCode(value)
    }
    if (voucherCodeErrorMessage !== '') setVoucherCodeErrorMessage('')
  }

  const validateVoucherCode = () => {
    if (voucherCode.length === 0) {
      setVoucherCodeErrorMessage(textContent?.shortTextFields?.voucherCodeRequired)
    } else if (voucherCode.length < 12) {
      setVoucherCodeErrorMessage(textContent?.shortTextFields?.voucherCodeInvalid)
    }
  }
  const removePromo = (item) => {
    const filtered = addedVouchers.filter((voucher) => voucher.id !== item.id)
    updatePaymentStore({[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: filtered})
  }

  const addVoucherCode = async () => {
    validateVoucherCode()
    if (voucherCode && voucherCode.length === 12) {
      const existingVoucher = addedVouchers.find((voucher) => voucher.id === voucherCode)
      if (!existingVoucher) {
        setIsVoucherLoading(true)
        const voucherResp = await AccountHelpers.executeVoucherInfo(voucherInfo, voucherCode)
        if (voucherResp && !voucherResp.error) {
          const voucherArr = Array.from(addedVouchers)
          voucherArr.push(voucherResp)
          updatePaymentStore({[PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: voucherArr})
          // dispatch(setPaymentInformation({
          //   ...paymentInfo,
          //   addedVouchers: [...paymentInfo?.addedVouchers, voucherCode]
          // }))
          setIsValidCode(true)
          setVoucherCode('')
        } else {
          let voucherError = textContent?.shortTextFields?.voucherCodeInvalid
          if (voucherResp && voucherResp.error === VOUCHER_STATE.USED) {
            voucherError = textContent?.shortTextFields?.voucherCodeUsed
          }
          setIsVoucherLoading(false)
          setVoucherCodeErrorMessage(voucherError)
          return false
        }
        setIsVoucherLoading(false)
        return true
      }
      setVoucherCodeErrorMessage(textContent?.shortTextFields?.voucherCodeUsed)
    }
    return false
  }
  const handleRegisterPopup = () => {
    updatePaymentStore({[PAYMENT_STATE.AUTO_TOP_UPS]: !isRegisterTopupEnabled})
    setAutoTopUps(!autoTopUps)
    // dispatch(setPaymentInformation({
    //   ...paymentInfo,
    //   autoTopUps
    // }))
  }

  useEffect(() => {
    updatePaymentStore({[PAYMENT_STATE.VOUCHER.INSUFFICIENT_AMOUNT_ERROR] : (addedVouchers.length <= 0 || (addedVouchers.length > 0 && amountDue > 0))})
    updatePaymentStore({[PAYMENT_STATE.TRIGGER_EPS_CARD_VALIDATION]: false})
    if ((addedVouchers && addedVouchers.length > 0) && (hasInsufficientVoucherCodeError || (Number(amountDue) > 0))) {
      voucherRequiredRef.current.scrollIntoView({
        behavior: "smooth"
      })
    }
    if ((showErrors && addedVouchers && addedVouchers.length === 0)) {
      setVoucherCodeErrorMessage(textContent?.shortTextFields?.voucherCodeRequired)
      voucherRequiredRef.current.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [hasInsufficientVoucherCodeError, amountDue])

  useEffect(() => {
    if ((showErrors && addedVouchers && addedVouchers.length === 0)) {
      setVoucherCodeErrorMessage(textContent?.shortTextFields?.voucherCodeRequired)
    }
  }, [currentLanguage])

  const handleVoucherFocus = () => {
    updatePaymentStore({[PAYMENT_STATE.VOUCHER.INSUFFICIENT_AMOUNT_ERROR]: false})
    setVoucherCodeErrorMessage('')
  }

  return (
    <div className="space-y-8">
      <div className="flex flex-col w-full space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row">
        <div className="lg:space-y-4 lg:w-1/2">
          <div
            className={`flex flex-row  w-full ${
              voucherCodeErrorMessage !== '' ? 'items-center' : 'items-end'
            } `}
          >
            <div ref={voucherRequiredRef} className="flex flex-1">
              <Input
                type="text"
                id="voucherCode"
                label={textContent?.shortTextFields?.voucherCodeTitle}
                name="voucherCode"
                value={voucherCode}
                onChange={(e) => handleVoucherCodeChange(e)}
                errorMessage={voucherCodeErrorMessage}
                onFocus={() => handleVoucherFocus()}
                maxLength="12"
                pattern="[a-zA-Z0-9]+"
                isLoading={isVoucherLoading}
                internalLoader
                data-testid="payment-voucherInput"
              />
            </div>
            <div>
              <Button
                className="ml-2 h-11"
                onClick={addVoucherCode}
                label={textContent?.shortTextFields?.add}
                testId="payment-voucherButton"
              />
            </div>
          </div>

          {addedVouchers &&
            addedVouchers.length > 0 &&
            (hasInsufficientVoucherCodeError || Number(amountDue) > 0) && (
              <div className="flex items-center space-y-2" data-testid="voucherRequired">
                <img className="h-4 my-2" src={content.error} alt="" />
                <BodyError>{textContent?.shortTextFields?.insuffucientAmountMessage}</BodyError>
              </div>
          )}
          <div className="flex flex-row w-full my-2 text-sm text-black">
            <div className="mr-2">
              <Checkbox
                checked={autoTopUps}
                onChange={handleRegisterPopup}
                testId="payment-registerPopupCheck"
                disabled={isMultiPromoLoading}
              />
            </div>
            <div>
              <span className="block text-sm tracking-wider font-h1 text-primary">
                {textContent?.shortTextFields?.voucherMessageHeading} {textContent?.shortTextFields?.voucherMessageHeadingDetail}
              </span>
              <BodySmall>{textContent?.longTextFields?.voucherTopUpMessage}</BodySmall>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="relative w-full overflow-hidden text-black rounded bg-card-background">
            <div className="absolute w-2 h-full" />
            {addedVouchers && addedVouchers.length > 0 ? (
              <>
                <div className="p-6 lg:space-y-2">
                  <BodySmallBold className="mx-2 text-primary">{textContent?.shortTextFields?.voucherAddedTitle}</BodySmallBold>
                  {addedVouchers &&
                    addedVouchers.map((item) => {
                      return (
                        <div key={item.id} className="flex flex-row">
                          <div className="flex flex-row items-center flex-1">
                            <img
                              className="mr-6"
                              width="14"
                              height="14"
                              alt="valid"
                              src={content?.verifiedCheckMark}
                              data-testid="payment-voucherInput-valid"
                            />
                            <BodySmall className="text-md">
                              {item.id}- {`${currencyFormatter(item.totalAmount,currentLanguage)}`} {/* eslint-disable-line */}
                            </BodySmall>
                          </div>
                          <div
                            className="flex flex-row content-between transition duration-300 ease-in-out delay-150 transform cursor-pointer hover:scale-125"
                            onClick={() => removePromo(item)}
                          >
                            <img width="24" height="24" alt="close" src={content?.close} />
                          </div>
                        </div>
                      )
                    })}
                </div>
              </>
            ) : (
              <div className="p-6">
                <Body>{textContent?.shortTextFields?.noVoucherAddedTitle}</Body>
              </div>
            )}
            <div className="relative w-full">
              <img src={content?.koodoCutLeft} alt="koodo cuts" className="w-full h-auto" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/2">
        {
          autoTopUps 
          &&
          (paymentGateway === 'SALT')
          &&
          <CreditDebitCard showErrors={showErrors} textContent= {textContent} />
        }
      </div>
      <div className="w-full md:w-1/2 lg:w-1/2">
        {
          autoTopUps 
          &&
          (paymentGateway === 'EPS')
          &&
          <EPSCard 
            handleSIMActivation={handleSIMActivation}
            showErrors={showErrors}
            textContent= {textContent}
            hasInsufficientVoucherCodeError={hasInsufficientVoucherCodeError}
            voucherRequiredRef={voucherRequiredRef}
            setButtonIsLoading={setButtonIsLoading}

          />  
        }
      </div>
    </div>
  )
}

export default VoucherOptionSection
