import React from "react"
import PropTypes from 'prop-types'

const BodyError = ({id, children, className, testId}) => {
  return (
    <p className={`text-error text-xs font-body ${className}`} id={id} data-testid={testId}>
      {children}
    </p>
  )
}
BodyError.prototype = {
  id: PropTypes.string,
  children: PropTypes.string,
  styles: PropTypes.string
}

export default BodyError
