/* eslint-disable react/require-default-props */
import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import HideIcon from '@apps/assets/HideIcon'
import ShowIcon from '@apps/assets/ShowIcon'
import BodyError from '@apps/components/Typography/BodyError'
import { getById, getImgByTitle } from '@apps/utils/contentful'

const MaskedState = {
  MASKED: 'password',
  UNMASKED: 'text'
}

export const MaskedInput = ({
  id = 'id',
  name = 'name',
  label = 'label',
  value,
  disabled = false,
  showToggle = true,
  minLength,
  maxLength,
  onChange,
  onBlur,
  onFocus,
  onPaste,
  errorMessage,
  errorState = false,
  ...rest
}) => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      error: getImgByTitle(commonElements, 'error')?.url
    }
  }, [pageContent, selectedLanguage])

  const options = { ...rest }
  const [masked, setMasked] = useState(true)
  const toggleMasked = () => {
    setMasked(!masked)
  }
  const errorStyles =
    'border-red border focus:border-red focus:shadow focus:shadow-danger focus:ring-2'

  return (
    <div className="w-full">
      <div className="flex flex-col w-full space-y-2">
        <label
          className={`block text-unselected-text  ${disabled ? 'text-disabled' : ''}`}
          htmlFor={id}
        >
          <h4>{label}</h4>
        </label>
        <div className="relative">
          <input
            type={masked ? MaskedState.MASKED : MaskedState.UNMASKED}
            className={`${
              showToggle ? 'pr-12' : 'pr-4'
            } pl-4 disabled:text-disabled disabled:border-disabled ${
              errorState ? errorStyles : ''
            }`}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            minLength={minLength}
            maxLength={maxLength}
            onPaste={onPaste}
            {...options}
          />
          {showToggle && (
            <button
              type="button"
              className="absolute right-0 h-11 w-11 focus:shadow-active focus:outline-none"
              onClick={toggleMasked}
            >
              {masked ? <ShowIcon title="show Password" /> : <HideIcon title="hide Password" />}
            </button>
          )}
        </div>
      </div>
      {errorMessage && (
        <div className="flex items-center" data-testid={options["data-testid"] ? `${options["data-testid"]}-error` : undefined}>
          <img className="h-4 my-2" src={content?.error} />
          <BodyError className="ml-1 mt-0.5 leading-4 ">{errorMessage}</BodyError>
        </div>
      )}
    </div>
  )
}

MaskedInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  showToggle: PropTypes.bool,
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onPaste: PropTypes.func,
  errorMessage: PropTypes.string,
  errorState: PropTypes.bool
}

export default MaskedInput
