import React, { useContext, useMemo } from 'react'
import { ContentContext } from '@apps/contexts/ContentContext'
import Alert from '@apps/components/Alert'
import { getBySlug } from '@apps/utils/contentful'

const SimPurchaseUnavailable = () => {
  const { dynamicContent } = useContext(ContentContext)
  const { haveSimPage = {} } = dynamicContent || {}
  const { shortTextFields } = haveSimPage

  const pageContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      simPurchaseUnavailableTitle: getBySlug(shortTextFields, 'simPurchaseUnavailableTitle')?.value,
      simPurchaseUnavailableMessage: getBySlug(shortTextFields, 'simPurchaseUnavailableMessage')
        ?.value
    }
  }, [shortTextFields])

  return (
    <Alert
      header={pageContent.simPurchaseUnavailableTitle}
      message={pageContent?.simPurchaseUnavailableMessage}
    />
  )
}

export default SimPurchaseUnavailable
