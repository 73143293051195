import React from 'react'
import Button from '@apps/components/Button'

const RoundedButton = (props) => {
  return (
    <Button
      {...props}
      className="w-full font-h4 h-11 text-base flex justify-center items-center rounded-md"
    />
  )
}

export default RoundedButton
