import React, { useContext, useEffect, useMemo } from 'react'
import useBrand from '@engine/hooks/useBrand'
import Brands from '@brands/index'
import Input from '@apps/components/Input'
import { PHONE_NUMBER_STATE, updatePhoneNumberState } from '@apps/redux/features/PhoneNumberSlice'
import PhoneNumberChangeHandlers, {
  PHONE_NUMBER_EVENTS
} from '@apps/utils/ChangeHandlers/PhoneNumberChangeHandlers'
import { useDispatch, useSelector } from 'react-redux'
import { ContentContext } from '@apps/contexts/ContentContext'
import Address from '@apps/components/Address'
import ValidationCard from '@apps/components/Cards/ValidationCard'
import BodySmall from '@apps/components/Typography/BodySmall'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import Heading4 from '@apps/components/Typography/Heading4'
import RichText from '@apps/components/RichText'
import Sites from '@sites/index'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import { getById, getBySlug } from '@apps/utils/contentful'

const MAX_LENGTH_ALTERNATE_PHONE_NUMBER = 12
const FIRSTNAME_MAX_LENGTH = 11
const LASTNAME_MAX_LENGTH = 18

const TransferLandline = ({ landlineRefs, landlineHandlers }) => {
  const dispatch = useDispatch()
  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const {
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: alternateContactNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]: alternatePhoneError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME]: firstName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME]: lastName,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME_ERROR]: firstNameError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME_ERROR]: lastNameError
  } = phoneNumberState

  const {
    dynamicContent: { phoneNumberPage }
  } = useContext(ContentContext)

  const {
    content: { pageContent },
    user: { selectedLanguage },
    userType
  } = useSelector((state) => state.common)

  const isDealer = userType === Sites.dealer
  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const textContent = useMemo(() => {
    if (!pageContent) return {}
    const phoneNumberPageText = getById(pageContent, `phoneNumberPage${pagePrefix}`)[
      selectedLanguage
    ]
    const { shortTextFields } = phoneNumberPageText || {}
    return {
      firstNameLabel: getBySlug(shortTextFields, 'firstNameLabel')?.value,
      lastNameLabel: getBySlug(shortTextFields, 'firstNameLabel')?.value,
      important: getBySlug(shortTextFields, 'important')?.value,
      alternateContactTitle: getBySlug(shortTextFields, 'alternateContactTitle')?.value,
      alternateInfoKoodo: getBySlug(shortTextFields, 'alternateInfoKoodo')?.value,
      alternateInfoPublic: getBySlug(shortTextFields, 'alternateInfoPublic')?.value,
      transferLandlineConfirm: getBySlug(shortTextFields, 'transferLandlineConfirm')?.value
    }
  }, [pageContent, selectedLanguage])

  const {
    firstNameLabel,
    lastNameLabel,
    important,
    alternateContactTitle,
    alternateInfoKoodo,
    alternateInfoPublic,
    transferLandlineConfirm
  } = textContent

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage } = useContext(ApplicationContext)
  const brand = useBrand()
  const { alternateLandlineRef, firstNameRef } = landlineRefs

  const {
    handleFirstName,
    handleLastName,
    handleAltPhoneLandline: handleAltPhone
  } = landlineHandlers

  const updatePhoneNumberStore = (payload) => {
    dispatch(updatePhoneNumberState(payload))
  }

  const { content = [] } = phoneNumberPage || {}
  const howLongItCanTake = content.find((item) => item.id === 'phoneNumber-transfer')
  useEffect(() => {
    if (phoneNumberState.firstNameLandlineError) {
      handleFirstName(firstName)
    }
    if (phoneNumberState.lastNameLandlineError) {
      handleLastName(lastName)
    }
    if (phoneNumberState.alternateContactNumberError) {
      handleAltPhone(alternateContactNumber)
    }
  }, [currentLanguage])

  return (
    <>
      <div className="space-y-8">
        <div
          ref={firstNameRef}
          className="flex flex-col space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0"
        >
          <Input
            type="text"
            id="firstName"
            label={firstNameLabel}
            name="firstName"
            value={firstName}
            onBlur={() => handleFirstName(firstName)}
            onChange={(e) => {
              PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onChangeName(
                e,
                PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.FIRST_NAME,
                updatePhoneNumberStore
              )
            }}
            errorMessage={firstNameError}
            maxLength={FIRSTNAME_MAX_LENGTH}
            data-testid="phoneNumber-firstName"
          />
          <Input
            type="text"
            id="lastName"
            label={lastNameLabel}
            name="lastName"
            value={lastName}
            onBlur={() => handleLastName(lastName)}
            onChange={(e) => {
              PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.INPUT].onChangeName(
                e,
                PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.LAST_NAME,
                updatePhoneNumberStore
              )
            }}
            errorMessage={lastNameError}
            maxLength={LASTNAME_MAX_LENGTH}
            data-testid="phoneNumber-lastName"
          />
        </div>
        <Address landlineRefs={landlineRefs} landlineHandlers={landlineHandlers} />
        {transferLandlineConfirm && (
          <div>
            <BodySmallBold className="inline">{important}</BodySmallBold>
            <BodySmall>{` ${transferLandlineConfirm}`}</BodySmall>
          </div>
        )}
        <div ref={alternateLandlineRef} className="w-full lg:w-1/2">
          <Input
            type="text"
            id="alternateContactNumber"
            name="Alternate phone number"
            label={alternateContactTitle}
            errorMessage={alternatePhoneError}
            maxLength={MAX_LENGTH_ALTERNATE_PHONE_NUMBER}
            value={alternateContactNumber}
            onBlur={() => handleAltPhone(alternateContactNumber)}
            onChange={(e) => {
              PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.ALTERNATE_CONTACT_NUMBER].onChange(
                e,
                updatePhoneNumberStore
              )
            }}
            data-testid="phoneNumber-alternateContact"
          />
        </div>
      </div>
      <div className="w-full lg:w-8/12">
        <BodySmallBold className="inline">{important}</BodySmallBold>
        <BodySmall className="inline">
          {` ${brand === Brands.koodo ? alternateInfoKoodo : alternateInfoPublic}`}
        </BodySmall>
      </div>
      {howLongItCanTake && (
        <ValidationCard>
          <div className="p-4 pb-6 space-y-2">
            <Heading4 className="pb-2">{howLongItCanTake.title}</Heading4>
            <RichText>{howLongItCanTake.body}</RichText>
          </div>
        </ValidationCard>
      )}
    </>
  )
}

export default TransferLandline
