import React, { useContext, useMemo, useEffect } from 'react'
import { ContentContext } from '@apps/contexts/ContentContext'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import ActivationSteps from '@sites/shared/Activation/ActivationSteps'
import StepStage from '@apps/components/StepStage'
import { Link } from 'react-router-dom'
import { getBySlug } from '@apps/utils/contentful'
import Sites from '@sites/index'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveStepNumber } from '@apps/redux/features/CommonSlice'

const StepTracker = ({ activeStepIndex }) => {
  const dispatch = useDispatch()
  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()

  const { userType } = useSelector((state) => state.common)
  const routes = userType === Sites.dealer ? dealerActivationPaths : consumerActivationPaths

  const { dynamicContent } = useContext(ContentContext)
  const { [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan } = useSelector(
    (state) => state.plansPage
  )

  const { commonElements: { shortTextFields } = {} } = dynamicContent || {}

  useEffect(() => {
    dispatch(setActiveStepNumber(activeStepIndex))
  }, [activeStepIndex])

  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      accountSetup: getBySlug(shortTextFields, 'step-tracker-account-setup')?.value,
      phoneNumber: getBySlug(shortTextFields, 'step-tracker-phone-number')?.value,
      plans: getBySlug(shortTextFields, 'step-tracker-plans')?.value,
      payment: getBySlug(shortTextFields, 'step-tracker-payment')?.value,
      selectSIM: getBySlug(shortTextFields, 'step-tracker-select-sim')?.value
    }
  }, [shortTextFields])

  const steps = useMemo(() => {
    const usePreSelectedPlanSteps = userType === Sites.consumer && preSelectedPlan
    const activationSteps = new ActivationSteps(routes, usePreSelectedPlanSteps, {
      accountSetup: textContent.accountSetup,
      simSelection: textContent.selectSIM,
      chooseNumber: textContent.phoneNumber,
      plans: textContent.plans,
      payment: textContent.payment
    })
    return activationSteps.NavigationSteps
  }, [userType, textContent, preSelectedPlan])

  return (
    <div className="flex max-w-6xl mx-4 md:m-auto md:px-4 md:pb-12 pt-5 mb-5 justify-between border-b-2 md:border-none items-baseline overflow-x-auto">
      {steps.map((step, i) => {
        const isActiveStep = activeStepIndex === step.number
        const isClickable = step.number < activeStepIndex

        if (isClickable) {
          return (
            <Link to={step.path} key={step.label}>
              <StepStage
                key={i}
                isActiveStep={isActiveStep}
                step={step}
                isClickable={isClickable}
              />
            </Link>
          )
        }
        return (
          <StepStage key={i} isActiveStep={isActiveStep} step={step} isClickable={isClickable} />
        )
      })}
    </div>
  )
}

StepTracker.propTypes = {
  activeStepIndex: PropTypes.number.isRequired
}

export default StepTracker
