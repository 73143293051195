import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { mounts } from '@engine/contexts/MountsContext'
import AppMounts from '@apps/AppMounts'

const MountPoint = ({ name }) => {
  const totalMounts = {
    ...AppMounts,
    ...useContext(mounts)
  }

  const Mountable = totalMounts[name]

  if (Mountable === undefined) {
    // missing when not on current LIVE mount and path
    return <></>
  }

  return <Mountable />
}

MountPoint.propTypes = {
  name: PropTypes.string.isRequired
}

export default MountPoint
