import AppPaths from '@apps/AppPaths'
import FlowPath from '@engine/flowPath'

class ConsumerActivationPaths extends FlowPath {
  constructor() {
    // strip
    super(`${AppPaths.Activation.RelativePath}`)
  }

  get Base() {
    return this.path
  }

  get AccountSetup() {
    return `${this.path}/account-setup`
  }

  get ChooseNumber() {
    return `${this.path}/choose-number`
  }

  get SimSelection() {
    return `${this.path}/sim-selection`
  }

  get ChangeNewPlan() {
    return `${this.path}/plans/new`
  }

  get ChangeExistingPlan() {
    return `${this.path}/plans/:planName/change`
  }

  get Plan() {
    return `${this.path}/plans/:planName`
  }

  get Plans() {
    return `${this.path}/plans`
  }

  get Payment() {
    return `${this.path}/payment`
  }

  get ProcessLoader() {
    return `${this.path}/process-loader`
  }

  get ProcessFailure() {
    return `${this.path}/process-failure`
  }

  get TransactionSummary() {
    return `${this.path}/transaction-summary`
  }

  get AlreadyActivated() {
    return `${this.path}/already-activated`
  }

  get Splash() {
    return `${this.path}/splash`
  }
}

export default ConsumerActivationPaths
