/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'

const Heading4 = ({ id, children, className = '' }) => {
  return (
    <h4 className={className} id={id}>
      {children}
    </h4>
  )
}
Heading4.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
}

export default Heading4
