export const getBySlug = (content, slug) => {
  if (!content || !Array.isArray(content) || content.length === 0) {
    return {}
  }

  return content.find(item => item?.slug === slug)
}

export const getById = (content, slug) => {
  if (!content || !Array.isArray(content) || content.length === 0) {
    return {}
  }

  return content.find(item => item?.id === slug)
}

export const getImgByTitle = (page, title) => {
  if (!page || !Array.isArray(page?.images) || page?.images?.length === 0) {
    return {}
  }

  return page?.images?.find(image => image?.title === title)
}

export const getShortTextFromPage = (page, slug) => {
  return getBySlug(page?.shortTextFields, slug)?.value || ''
}

export const getLongTextFromPage = (page, slug) => {
  return getBySlug(page?.longTextFields, slug)?.value || ''
}
