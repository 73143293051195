/* eslint-disable react/require-default-props */
import React from 'react'
import merge from 'lodash.merge'
import PropTypes from 'prop-types'
import { LANGUAGES_CODE } from '@apps/utils/constants'
import commonEnglish from '@apps/assets/content/en.json'
import commonFrench from '@apps/assets/content/fr.json'

export const ContentContext = React.createContext(undefined)

const contentReducer = (state, { language, contentHierarchy }) => {
  if (
    !contentHierarchy ||
    !contentHierarchy[LANGUAGES_CODE.EN] ||
    !contentHierarchy[LANGUAGES_CODE.FR] ||
    contentHierarchy[LANGUAGES_CODE.EN].length === 0 ||
    contentHierarchy[LANGUAGES_CODE.FR].length === 0
  ) {
    throw new Error('Missing or poorly formatted content')
  }

  switch (language) {
    case LANGUAGES_CODE.EN: {
      let result = merge({}, commonEnglish)

      contentHierarchy[LANGUAGES_CODE.EN].forEach((item) => {
        result = merge(result, item)
      })

      return result
    }

    case LANGUAGES_CODE.FR: {
      let result = merge({}, commonFrench)

      contentHierarchy[LANGUAGES_CODE.FR].forEach((item) => {
        result = merge(result, item)
      })

      return result
    }

    default:
      throw new Error('Unsupported language')
  }
}

export const ContentProvider = ({ language, contentHierarchy, dynamicContent = {}, children }) => {
  const state = contentReducer(undefined, { language, contentHierarchy })
  state.dynamicContent = dynamicContent[language]

  return <ContentContext.Provider value={state}>{children}</ContentContext.Provider>
}

export default ContentProvider

ContentProvider.propTypes = {
  language: PropTypes.string.isRequired,
  contentHierarchy: PropTypes.object.isRequired,
  dynamicContent: PropTypes.object,
  children: PropTypes.element.isRequired
}

// ContentProvider.defaultProps = {
//   dynamicContent: {}
// }
