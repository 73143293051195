export const convertCentsToDollars = (cents) => {
  return (Number(cents) / 100).toFixed(2)
}

export const convertDollarsToCents = (dollars) => {
  return dollars.replace('.', '')
}

export const PROMO_CRITERIA = {
  VALIDATION_CODE: 'VALIDATION_CODE',
  EXCLUSIVE_PROMO_CONFLICT: 'EXCLUSIVE_PROMOTION_ERROR',
  REAPPLY_EXISTING_PROMOTION_ERROR: 'REAPPLY_EXISTING_PROMOTION_ERROR'
}
export const VOUCHER_STATE = {
  USED: 'used',
  INVALID: 'invalid'
}

export default {
  applyReferralCode: async (isValidReferral, referralCode, rafSettings) => {
    const { data, error } = await isValidReferral(referralCode, rafSettings)

    if (error) {
      // eslint-disable-next-line
      console.log(error)
      return { error }
    }

    const { mdn, discount, isActiveAccount } = data

    if (!discount || discount === 0 || !isActiveAccount) {
      return undefined
    }
    return {
      referral: {
        mdn,
        code: referralCode,
        name: referralCode,
        value: convertCentsToDollars(discount),
        type: 'SAVINGS'
      }
    }
  },

  validatePromoCode: async (
    validatePromotion,
    optionIds,
    retailChannel,
    province,
    promoCodeInput,
    validationCode,
    language
  ) => {
    if (optionIds && optionIds.length > 0) {
      let optionsStr = optionIds

      if (Array.isArray(optionIds)) {
        optionsStr = optionIds.join()
      }

      const { data, error } = await validatePromotion(
        optionsStr,
        province,
        language,
        retailChannel,
        validationCode,
        promoCodeInput
      )

      if (!data && error) {
        if (error.code === PROMO_CRITERIA.VALIDATION_CODE) {
          return { id: promoCodeInput, code: 'VALIDATION_CODE' }
        }
        return undefined
      }

      const bundleArray = data.bundles.map((bundle) => {
        const { id, name, fee: rawFee, bundleType, unitType, numberMrc } = bundle

        return {
          id,
          name,
          fee: convertCentsToDollars(rawFee),
          bundleType,
          unitType,
          numberMrc
        }
      })

      return {
        id: data.id,
        description: data.description,
        bundles: bundleArray
      }
    }

    return undefined
  },

  findAndValidatePromoCode: async (
    findAndValidatePromotions,
    optionIds,
    retailChannel,
    province,
    promoAndValidationCodeInput,
    language
  ) => {
    if (optionIds && optionIds.length > 0) {
      let optionsStr = optionIds

      if (Array.isArray(optionIds)) {
        optionsStr = optionIds.join()
      }

      const { data } = await findAndValidatePromotions(
        optionsStr,
        province,
        language,
        retailChannel,
        promoAndValidationCodeInput
      )

      
      if (data?.length === 0 || data === null ) return undefined
      const resData = data[data.length - 1]
      if (resData?.criteria) {
        if (resData?.criteria === PROMO_CRITERIA.VALIDATION_CODE) {
          return { id: promoAndValidationCodeInput, code: PROMO_CRITERIA.VALIDATION_CODE }
        } else if (resData?.criteria === PROMO_CRITERIA.EXCLUSIVE_PROMO_CONFLICT) {
          return { id: promoAndValidationCodeInput, code: PROMO_CRITERIA.EXCLUSIVE_PROMO_CONFLICT }
        } else if (resData?.criteria === PROMO_CRITERIA.REAPPLY_EXISTING_PROMOTION_ERROR) {
        return { id: promoAndValidationCodeInput, code: PROMO_CRITERIA.REAPPLY_EXISTING_PROMOTION_ERROR }
      }


        return undefined
      }

      const bundleArray = resData?.bundles.map((bundle) => {
        const { id, name, fee: rawFee, bundleType, unitType, numberMrc } = bundle

        return {
          id,
          name,
          fee: convertCentsToDollars(rawFee),
          bundleType,
          unitType,
          numberMrc
        }
      })

      return {
        id: resData.id,
        description: resData.description,
        bundles: bundleArray
      }
    }

    return undefined
  },

  executeVoucherInfo: async (voucherInfoCb, voucherId) => {
    if (!voucherId) {
      return {
        error: 'Missing Vouchers'
      }
    }

    const { data, error } = await voucherInfoCb(voucherId)

    if (!data) {
      // eslint-disable-next-line
      console.log(error)

      return undefined
    }

    const voucherInfo = data[0]
    const voucherTotalDollars = convertCentsToDollars(voucherInfo.TotalVoucherAmount)

    if (voucherInfo.error !== null || voucherTotalDollars < 1.0) {
      return {
        error: VOUCHER_STATE.INVALID
      }
    }

    if (voucherInfo.VoucherStatus === '2') {
      return {
        error: VOUCHER_STATE.USED
      }
    }

    if (voucherInfo.VoucherStatus === '1') {
      return {
        id: voucherInfo.id,
        status: voucherInfo.VoucherStatus,
        totalAmount: voucherTotalDollars,
        taxAmount: convertCentsToDollars(voucherInfo.VoucherTaxAmount)
      }
    }

    return undefined
  },
  calculateTax: async (calculateTaxCb, phoneNumber, rawAmount) => {
    const amount = convertDollarsToCents(rawAmount)
    const amountInt = Number(amount)

    if (amountInt > 0 && phoneNumber) {
      const { data, error } = await calculateTaxCb(phoneNumber, amountInt)

      if (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      if (data !== -1) {
        return convertCentsToDollars(data)
      }
    }

    return undefined
  }
}
