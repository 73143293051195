import React, { useContext, useEffect, useMemo } from 'react'
import Input from '@apps/components/Input'
import Heading3 from '@apps/components/Typography/Heading3'
import {
  ACCOUNT_SETUP_STATE,
  updateAccountSetupState
} from '@apps/redux/features/AccountSetupSlice'
import AccountSetupChangeHandlers, {
  ACCOUNT_SETUP_EVENTS
} from '@apps/utils/ChangeHandlers/AccountSetupChangeHandlers'
import { useDispatch, useSelector } from 'react-redux'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { getShortTextFromPage, getById } from '@apps/utils/contentful'
import Sites from '@sites/index'

const Name = ({ nameRef, handleFirstNameBlur, handleLastNameBlur }) => {
  const dispatch = useDispatch()
  const { pageContent } = useSelector((state) => state.common.content)
  const { userType } = useSelector((state) => state.common)
  const accountSetupState = useSelector((state) => state.accountSetupPage)

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)

  const {
    [ACCOUNT_SETUP_STATE.FIRST_NAME]: firstName,
    [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR]: firstNameError,
    [ACCOUNT_SETUP_STATE.LAST_NAME]: lastName,
    [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR]: lastNameError
  } = accountSetupState

  const text = useMemo(() => {
    if (!pageContent || !language || !userType) return {}
    const contentSuffix = userType === Sites.dealer ? 'Dealer' : 'Consumer'
    const accountSetupPageContent = getById(pageContent, `accountSetupPage${contentSuffix}`)?.[
      language
    ]
    return {
      firstNameLabel: getShortTextFromPage(accountSetupPageContent, 'firstNameLabel'),
      lastNameLabel: getShortTextFromPage(accountSetupPageContent, 'lastNameLabel'),
      firstNameRequired: getShortTextFromPage(accountSetupPageContent, 'firstNameRequired'),
      lastNameRequired: getShortTextFromPage(accountSetupPageContent, 'lastNameRequired'),
      accountSetupTitle: getShortTextFromPage(accountSetupPageContent,'accountSetupTitle'),
      firstNameInvalid: getShortTextFromPage(accountSetupPageContent, 'firstNameInvalid'),
      lastNameInvalid: getShortTextFromPage(accountSetupPageContent, 'lastNameInvalid')
    }
  }, [pageContent, language, userType])

  const {
    accountSetupTitle,
    firstNameLabel,
    lastNameLabel,
    firstNameRequired,
    lastNameRequired,
    firstNameInvalid,
    lastNameInvalid
  } = text

  const errorCopy = {
    firstNameRequired,
    firstNameInvalid,
    lastNameRequired,
    lastNameInvalid
  }

  useEffect(() => {
    if (accountSetupState.firstNameError) {
      handleFirstNameBlur()
    }
    if (accountSetupState.lastNameError) {
      handleLastNameBlur()
    }
  }, [language])

  const setAccountSetupState = (payload) => {
    dispatch(updateAccountSetupState(payload))
  }

  const handleFirstNameChange = (event) => {
    AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.FIRST_NAME].onChange(
      event.target.value,
      errorCopy,
      setAccountSetupState
    )
  }

  const handleLastNameChange = (event) => {
    AccountSetupChangeHandlers[ACCOUNT_SETUP_EVENTS.LAST_NAME].onChange(
      event.target.value,
      errorCopy,
      setAccountSetupState
    )
  }

  const FIRSTNAME_MAX_LENGTH = '20'
  const LASTNAME_MAX_LENGTH = '13'

  return (
    <div>
      <Heading3 className="pb-6 md:pt-8">{accountSetupTitle}</Heading3>
      <div ref={nameRef} className="flex flex-col space-y-8 lg:flex-row lg:space-x-6 lg:space-y-0">
        <Input
          type="text"
          id="firstName"
          label={firstNameLabel}
          name="firstName"
          value={firstName}
          onBlur={handleFirstNameBlur}
          onChange={handleFirstNameChange}
          errorMessage={firstNameError}
          maxLength={FIRSTNAME_MAX_LENGTH}
          data-testid="accountSetup-firstName"
        />
        <Input
          type="text"
          id="lastName"
          label={lastNameLabel}
          name="lastName"
          value={lastName}
          onBlur={handleLastNameBlur}
          onChange={handleLastNameChange}
          errorMessage={lastNameError}
          maxLength={LASTNAME_MAX_LENGTH}
          data-testid="accountSetup-lastName"
        />
      </div>
    </div>
  )
}

export default Name
