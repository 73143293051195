/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'

const BodyBold = ({ id, children, className, textColor, textSize = 'text-sm' }) => {
  return (
    <p className={`${textColor || 'text-copy'} ${textSize} font-h1 ${className}`} id={id}>
      {children}
    </p>
  )
}

BodyBold.propTypes = {
  id: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  textColor: PropTypes.string,
  textSize: PropTypes.string
}


export default BodyBold
