import useSite from '@engine/hooks/useSite'
import useBrand from '@engine/hooks/useBrand'
import useLocale from '@engine/hooks/useLocale'

/**
 * Encasulates the paths and nested paths for a flow
 */
class FlowPath {
  /**
   * @param {string} path the parent path to this flow
   */
  constructor(path) {
    // useLocale determines lang and prov
    this._locale = useLocale()

    // useBrand determines the path root
    this._brand = useBrand()

    // useSite will determine application hierarchy
    this._site = useSite()

    // beings the application level pathing
    this._relativePath = path

    this.path =
      this._site === undefined || this._brand === undefined ? this._relativePath : `/${this._locale.lang}/${this._locale.prov}/prepaid/${this._brand}/${this._site}${this._relativePath}`
  }

  /**
   * @returns string object base path without the site/application root
   */
  get RelativePath() {
    return this._relativePath
  }
}

export default FlowPath
