import React from 'react'
import PropTypes from 'prop-types'

const TabItem = ({children}) => {

  return (
    <div className="relative w-full focus-visible:outline-none md:w-3/4 lg:w-3/4">
    {children}
    </div>
  )
}

TabItem.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool
}

export default TabItem
