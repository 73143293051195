import React from 'react'

const ShowIcon = ({ title }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className="fill-current	text-primary"
    >
      <title id="showIcon">{title}</title>
      <path
        fillRule="evenodd"
        d="M15.807 6C20.35 6 25.747 9.333 32 16c-6.253 6.667-11.65 10-16.193 10C11.265 26 5.996 22.667 0 16 5.996 9.333 11.265 6 15.807 6zM16 9a7 7 0 100 14 7 7 0 000-14zm0 3.2a3.8 3.8 0 110 7.6 3.8 3.8 0 010-7.6z"
      />
    </svg>
  )
}

export default ShowIcon
