import React from 'react'
import { Route } from 'react-router-dom'
import ActivationRoutes from '@apps/flows/activation/ActivationRoutes'

/**
 * Use nested routes to keep a flow's logic together
 * The top level App route directs to Flows, each Flow has its own routes handled in their {Flow}Routes.js
 */

const AppRoutes = () => {
  return (
    <>
      <Route>
        <ActivationRoutes />
      </Route>
    </>
  )
}

export default AppRoutes
