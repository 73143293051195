import React from 'react'
import { isMobile } from 'react-device-detect'
import Heading3 from '@apps/components/Typography/Heading3'

const StepStage = ({ isActiveStep, step, isClickable }) => {
  return (
    <div
      className={`flex items-center pb-3 min-w-max ${isActiveStep ? '' : 'opacity-30'} ${
        isClickable ? 'hover:opacity-100' : ''
      }`}
    >
      <div
        className={`flex items-center justify-center rounded w-14 h-14 ${
          isMobile && !isActiveStep ? 'bg-transparent text-primary' : 'text-white bg-primary mr-3'
        }`}
      >
        <p className={`${'text-step font-bodyLarge '}`}>{step.number}</p>
      </div>
      <div className={!isActiveStep ? 'hidden md:block' : ''} style={{ maxWidth: '140px' }}>
        <Heading3 className="text-h1 text-medium leading-7">{step.label}</Heading3>
      </div>
    </div>
  )
}

export default StepStage
