import { useLocation } from 'react-router'
import { getLocale } from '@apps/utils/locale'

/**
 * @typedef {hook} useLocale
 *
 * Supported themes or array indexed, used to identify which theming template to use in implementing application
 *
 * @return number Valid index >= 0, where 0 uses the default platform theme, and anything greater can be custom
 */
const useLocale = () => {
  const location = useLocation()

  if (!location.pathname) {
    return undefined
  }

  return getLocale(location.pathname)
}

export default useLocale
