import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react'
import useBrand from '@engine/hooks/useBrand'
import Brands from '@brands'
import Input from '@apps/components/Input'
import { SPIDS } from '@apps/utils/constants'
import { PHONE_NUMBER_STATE, updatePhoneNumberState } from '@apps/redux/features/PhoneNumberSlice'
import PhoneNumberChangeHandlers, {
  PHONE_NUMBER_EVENTS,
  PHONE_NUMBER_ERROR_KEYS
} from '@apps/utils/ChangeHandlers/PhoneNumberChangeHandlers'
import { getById, getBySlug } from '@apps/utils/contentful'
import { useDispatch, useSelector } from 'react-redux'
import ValidationCard from '@apps/components/Password/ValidationCard'
import Datalist from '@apps/components/Datalist'
import ToolTip from '@apps/components/ToolTip'
import { ContentContext } from '@apps/contexts/ContentContext'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import RichText from '@apps/components/RichText'
import Sites from '@sites/index'
import Heading3 from '../Typography/Heading3'
import Heading4 from '../Typography/Heading4'
import BodySmall from '../Typography/BodySmall'
import BodySmallBold from '../Typography/BodySmallBold'

const MAX_LENGTH = '15'
const MAX_LENGTH_ACCOUNT_NUMBER = '255'
const MAX_LENGTH_ALTERNATE_PHONE_NUMBER = '12'

const TransferMobile = ({ mobileRefs, mobileHandlers }) => {
  const dispatch = useDispatch()

  const { userType } = useSelector((state) => state.common)
  const { pageContent } = useSelector((state) => state.common.content)

  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const [toggleTooltip, setToggleToolTip] = useState(false)
  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage } = useContext(ApplicationContext)

  const {
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID]: selectedSpid,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR]: spidError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]: imeiNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR]: imeiError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]:
      prevProviderAccountNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR]:
      prevAccountNumberError,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: alternateContactNumber,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR]: alternatePhoneError
  } = phoneNumberState

  const [spidValue, setSPIDValue] = useState(selectedSpid || '')

  const toolTipHandler = useCallback(() => {
    setToggleToolTip(!toggleTooltip)
  }, [toggleTooltip])

  const toolTipBlurHandler = useCallback(() => {
    setToggleToolTip(false)
  }, [toggleTooltip])

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const phoneNumberPageContent = getById(pageContent, `phoneNumberPage${pagePrefix}`) || {}
  const { shortTextFields } = phoneNumberPageContent[currentLanguage] || {}

  const textContent = useMemo(() => {
    return {
      important: getBySlug(shortTextFields, 'important')?.value,
      previousProvider: getBySlug(shortTextFields, 'previousProvider')?.value,
      previousProviderRequired: getBySlug(shortTextFields, 'previousProviderRequired')?.value,
      completeOneOf: getBySlug(shortTextFields, 'completeOneOf')?.value,
      imei: getBySlug(shortTextFields, 'imei')?.value,
      previousAccountNumber: getBySlug(shortTextFields, 'previousAccountNumber')?.value,
      or: getBySlug(shortTextFields, 'or')?.value,
      imeiTooltip: getBySlug(shortTextFields, 'imeiTooltip')?.value,
      alternateContactTitle: getBySlug(shortTextFields, 'alternateContactTitle')?.value,
      alternateInfoKoodo: getBySlug(shortTextFields, 'alternateInfoKoodo')?.value,
      alternateInfoPublic: getBySlug(shortTextFields, 'alternateInfoPublic')?.value,
      previousProviderInvalid: getBySlug(shortTextFields, 'previousProviderInvalid')?.value
    }
  }, [shortTextFields])

  const {
    dynamicContent: { phoneNumberPage }
  } = useContext(ContentContext)

  const {
    important,
    previousProvider,
    previousProviderRequired,
    completeOneOf,
    imei,
    previousAccountNumber,
    or,
    imeiTooltip,
    alternateContactTitle,
    alternateInfoKoodo,
    alternateInfoPublic,
    previousProviderInvalid
  } = textContent

  const spidErrors = {
    [PHONE_NUMBER_ERROR_KEYS.SPID_REQUIRED]: previousProviderRequired,
    [PHONE_NUMBER_ERROR_KEYS.SPID_INVALID]: previousProviderInvalid
  }

  const brand = useBrand()

  const { prevProvRef, imeiRef, accountRef, alternateRef } = mobileRefs

  const { handlePrevProvider, handleIMEI, handleAccount, handleAltPhone } = mobileHandlers

  const { content = [] } = phoneNumberPage || {}
  const additionalSecurityCard = content.find(
    (item) => item.id === 'phoneNumber-additional-security'
  )

  useEffect(() => {
    if (phoneNumberState.selectedSpidError) {
      handlePrevProvider()
    }
    if (phoneNumberState.selectedImeiError) {
      handleIMEI()
    }
    if (phoneNumberState.previousProviderAccountNumberError) {
      handleAccount(prevProviderAccountNumber)
    }
    if (phoneNumberState.alternateContactNumberError) {
      handleAltPhone()
    }
  }, [currentLanguage])

  const updatePhoneNumberStore = (payload) => {
    dispatch(updatePhoneNumberState(payload))
  }

  return (
    <>
      <div ref={prevProvRef} className="w-full lg:w-1/2">
        <Datalist
          items={SPIDS}
          title={previousProvider}
          onChange={(e) => {
            setSPIDValue(e.target.value)
            PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.SPID].onChange(
              e,
              SPIDS,
              updatePhoneNumberStore
            )
          }}
          onBlur={() => handlePrevProvider()}
          selectedValue={selectedSpid}
          error={spidErrors[spidError]}
          id="phoneNumber-previousProvider"
          value={spidValue}
        />
      </div>
      <div className={`space-y-8 ${selectedSpid ? '' : 'opacity-50'}`}>
        <div className="space-y-8">
          <Heading3>{completeOneOf}</Heading3>
          <div className="flex flex-col items-center justify-center md:items-start lg:items-start lg:flex-row">
            <div
              ref={imeiRef}
              className={`relative w-full lg:mr-5 lg:flex-1 self-end ${
                prevProviderAccountNumber === '' ? '' : 'opacity-50'
              }`}
            >
              <Input
                type="text"
                id="Imei"
                label={imei}
                name="Your Phone's IMEI number"
                errorMessage={imeiError}
                maxLength={MAX_LENGTH}
                value={imeiNumber}
                onBlur={() => handleIMEI()}
                onChange={(e) => {
                  PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.IMEI].onChange(
                    e,
                    updatePhoneNumberStore
                  )
                }}
                disabled={!selectedSpid || prevProviderAccountNumber !== ''}
                showToolTip
                onToolTipClick={toolTipHandler}
                onToolTipBlur={toolTipBlurHandler}
                data-testid="phoneNumber-imei"
              />
              {toggleTooltip && (
                <ToolTip align="top">
                  <Heading4>{imeiTooltip}</Heading4>
                </ToolTip>
              )}
            </div>
            <Heading4 className="mt-2 text-primary lg:mt-10 text-md">{or}</Heading4>
            <div
              ref={accountRef}
              className={`relative w-full lg:ml-5 lg:flex-1 ${
                imeiNumber === '' ? '' : 'opacity-50'
              }`}
            >
              <Input
                type="text"
                id="prevProviderAccountNumber"
                label={previousAccountNumber}
                name="Previous Provider Account Number"
                errorMessage={prevAccountNumberError}
                maxLength={MAX_LENGTH_ACCOUNT_NUMBER}
                value={prevProviderAccountNumber}
                onBlur={() => handleAccount(prevProviderAccountNumber)}
                onChange={(e) => {
                  PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.ACCOUNT_NUMBER].onChange(
                    e,
                    updatePhoneNumberStore
                  )
                }}
                disabled={!selectedSpid || imeiNumber !== ''}
                data-testid="phoneNumber-accountNumber"
              />
            </div>
          </div>
        </div>
        <div className="w-full text-black lg:w-8/12">
          <Heading3>{alternateContactTitle}</Heading3>
          <BodySmallBold className="inline">{important}</BodySmallBold>
          <BodySmall className="inline">{` ${
            brand === Brands.koodo ? alternateInfoKoodo : alternateInfoPublic
          }`}</BodySmall>
          <div ref={alternateRef} className="w-full lg:w-1/2">
            <Input
              type="text"
              id="alternateContactNumber"
              name="sim-number*"
              errorMessage={alternatePhoneError}
              maxLength={MAX_LENGTH_ALTERNATE_PHONE_NUMBER}
              placeholder="(XXX) XXX XXXX"
              value={alternateContactNumber}
              onBlur={() => handleAltPhone()}
              onChange={(e) => {
                PhoneNumberChangeHandlers[PHONE_NUMBER_EVENTS.ALTERNATE_CONTACT_NUMBER].onChange(
                  e,
                  updatePhoneNumberStore
                )
              }}
              disabled={!selectedSpid}
              data-testid="phoneNumber-alternateContact"
            />
          </div>
        </div>
        {additionalSecurityCard && (
          <ValidationCard
            customImageStyles="w-3/4"
            title={<Heading3 className="text-black">{additionalSecurityCard.title}</Heading3>}
          >
            <RichText className="py-2">{additionalSecurityCard.body}</RichText>
          </ValidationCard>
        )}
      </div>
    </>
  )
}

export default TransferMobile
