import React from "react"
import PropTypes from 'prop-types'

const BodyLarge = ({id, children, className}) => {
  return (
    <p className={`text-base text-copy font-bodyLarge ${className}`} id={id}>
      {children}
    </p>
  )
}
BodyLarge.prototype = {
  id: PropTypes.string,
  children: PropTypes.string,
  styles: PropTypes.string
}

export default BodyLarge
