import React from 'react'

const ESimQrCard = ({ qrCode, qrCodeRef }) => {
  return (
    <div ref={qrCodeRef}
      className="flex flex-col justify-center items-center border-4 p-2 rounded-md border-solid border-grape bg-white"
      style={{ width: '200px', height: '200px' }}
    >
      {qrCode && <img src={qrCode} className="w-full" alt="Scan QR Code" />}
    </div>
  )
}

export default ESimQrCard
