import {
  formattedPhoneNumber,
  isDigitOrEmpty,
  MAX_INPUT_LENGTH,
  sanitizeAccountNumber,
  sanitizeNameField,
  sanitizeTextField
} from '@apps/utils/formHelpers'
import { isNullOrEmpty } from '@apps/utils/common'
import { PHONE_NUMBER_STATE } from '@apps/redux/features/PhoneNumberSlice'

export const PHONE_NUMBER_EVENTS = {
  PROVINCE: 'event.target.phoneNumberSetup.province',
  PROVINCE_LANDLINE: 'event.target.phoneNumberSetup.provinceLandline',
  PROVINCE_GROUP: 'event.target.phoneNumberSetup.provinceGroup',
  SPID: 'event.target.phoneNumberSetup.spid',
  ACCOUNT_NUMBER: 'event.target.phoneNumberSetup.accountNumber',
  ALTERNATE_CONTACT_NUMBER: 'event.target.phoneNumberSetup.alternateContactNumber',
  POSTAL_CODE: 'event.target.phoneNumberSetup.postalCode',
  INPUT: 'event.target.phoneNumberSetup.input',
  IMEI: 'event.target.phoneNumberSetup.imei',
  INTERNET_TV: 'event.target.phoneNumberSetup.internetAndTv',
  PHONE_NUMBER: 'event.target.phoneNumberSetup.phoneNumber'
}

export const PHONE_NUMBER_ERROR_KEYS = {
  CITY_REQUIRED: 'cityRequired',
  CITY_INVALID: 'cityInvalid',
  SPID_REQUIRED: 'spidRequired',
  SPID_INVALID: 'spidInvalid'
}

const formatNumberToPort = (rawNumber) => {
  let number

  if (/^\d+$/.test(rawNumber)) {
    if (rawNumber && rawNumber.length === 11 && rawNumber.startsWith('1')) {
      number = rawNumber
    } else if (rawNumber && rawNumber.length === 10) {
      number = `1${rawNumber}`
    }
  }

  return number
}

export default {
  [PHONE_NUMBER_EVENTS.PHONE_NUMBER]: {
    onBlur: async (
      state,
      phoneRequired,
      phoneInvalid,
      isEligibleForPort,
      validCanadianNumber,
      UpdatePhoneNumberStates
    ) => {
      const phoneNumber = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]
      const prevPhoneNumber = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]
      const isPrevPortInNumberEligible =
        state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]

      if (phoneNumber.length === 0) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: phoneRequired
        })
        return true
      }
      if (phoneNumber.length > 0 && phoneNumber.length < 10) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: phoneInvalid
        })
        return true
      }
      if (phoneNumber.length === 10) {
        if (prevPhoneNumber !== phoneNumber || isPrevPortInNumberEligible) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: false,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER
              .PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: validCanadianNumber,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false
          })
          return true
        }
      }

      if (phoneNumber.length === 10 && prevPhoneNumber !== phoneNumber) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER]: phoneNumber,
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: true
        })
        const { data: checkPortResp } = await isEligibleForPort(formatNumberToPort(phoneNumber))
        // Uncomment below line to make number eligible
        // checkPortResp.isEligible = true
        let updateActivationState = {}
        if (checkPortResp) {
          updateActivationState = {
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: false,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]:
              checkPortResp.isEligible,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: checkPortResp.isWireless,
            [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER]: checkPortResp.isEligible
              ? formatNumberToPort(phoneNumber)
              : '',
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PREV_PORT_IN_PHONE_NUMBER]: phoneNumber,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PREV_PORT_IN_NUMBER_ELIGIBLE]:
              checkPortResp.isEligible
          }
          if (checkPortResp.isEligible) {
            UpdatePhoneNumberStates({
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: false,
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: ''
            })
            // Load spids
          } else {
            UpdatePhoneNumberStates({
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_HAS_ERROR]: true,
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER
                .PORT_IN_PHONE_NUMBER_ERROR_MESSAGE]: validCanadianNumber,
              [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false
            })
            return true
          }

          UpdatePhoneNumberStates({
            ...updateActivationState,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_PHONE_NUMBER_IS_LOADING]: false
          })
          return false
        }
      }
    }
  },
  [PHONE_NUMBER_EVENTS.PROVINCE]: {
    onBlur: (state, provinces, errorCopy, value = '', UpdatePhoneNumberStates) => {
      if (value === '') {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
          [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: errorCopy.provinceRequired
        })
        return true
      }

      const matchedProv = provinces && provinces.find((prov) => prov.name === value)
      if (!matchedProv) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
          [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: errorCopy.provinceInvalid
        })
        return true
      }

      if (!state[PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]) {
        if (!state[PHONE_NUMBER_STATE.PROVINCE_ID]) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: errorCopy.provinceRequired
          })
          return true
        }
        if (
          !provinces.find((province) => province.value === state[PHONE_NUMBER_STATE.PROVINCE_ID])
        ) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: errorCopy.provinceInvalid
          })
          return true
        }
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
        })
        return false
      }
    },
    onChange: (value, provinces, errorCopy, showProvinceError, UpdatePhoneNumberStates) => {
      if (value) {
        const matchedProv = provinces && provinces.find((prov) => prov.name === value)
        if (matchedProv) {
          const id = matchedProv.value
          const shortName = matchedProv?.shortName ? matchedProv?.shortName : 'ON'
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.PROVINCE_ID]: id,
            [PHONE_NUMBER_STATE.PROVINCE_NAME]: shortName,
            [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.PROVINCE_ID]: '',
          [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: showProvinceError ? errorCopy.provinceRequired : ""
        })
      }
    },
    onFocus: (UpdatePhoneNumberStates) => {
      UpdatePhoneNumberStates({
        [PHONE_NUMBER_STATE.PROVINCE_ID_ERROR]: ''
      })
    }
  },
  [PHONE_NUMBER_EVENTS.PROVINCE_LANDLINE]: {
    onBlur: (state, provinces, errorCopy, UpdatePhoneNumberState) => {
      if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]) {
        if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]) {
          UpdatePhoneNumberState(
            PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR,
            errorCopy.provinceRequired
          )
          return true
        }
        if (
          !provinces.find(
            (province) =>
              province.value ===
              state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]
          )
        ) {
          UpdatePhoneNumberState(
            PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR,
            errorCopy.provinceInvalid
          )
          return true
        }
        UpdatePhoneNumberState(
          PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR,
          ''
        )
        return false
      }
    },
    onChange: (value, provinces, errorCopy, UpdatePhoneNumberStates) => {
      if (value) {
        const matchedProv = provinces.find((prov) => prov.name === value)
        if (matchedProv) {
          const id = matchedProv.value
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: id,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: '',
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]:
              errorCopy.provinceInvalid
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE]: '',
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.SELECTED_PROVINCE_LANDLINE_ERROR]:
            errorCopy.provinceRequired
        })
      }
    }
  },
  [PHONE_NUMBER_EVENTS.PROVINCE_GROUP]: {
    onBlur: (state, cities, UpdatePhoneNumberStates) => {
      const value = state[PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]
      const error = state[PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]
      if (!error) {
        if (isNullOrEmpty(value)) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]:
              PHONE_NUMBER_ERROR_KEYS.CITY_REQUIRED
          })
          return true
        }
        if (!cities.find((city) => city.value === value)) {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]:
              PHONE_NUMBER_ERROR_KEYS.CITY_INVALID
          })
          return true
        }
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
        })
        return false
      }
      return false
    },
    onChange: (value, cities, UpdatePhoneNumberStates) => {
      if (value !== '') {
        const matchedCity = cities.find((city) => city.name === value)

        if (matchedCity) {
          const id = matchedCity.value
          const cityName = matchedCity.name
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: id,
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.CITY_NAME]: cityName,
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: '',
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.CITY_NAME]: '',
            [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]:
              PHONE_NUMBER_ERROR_KEYS.CITY_INVALID
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID]: '',
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.CITY_NAME]: '',
          [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]:
            PHONE_NUMBER_ERROR_KEYS.CITY_REQUIRED
        })
      }
    },
    onFocus: (UpdatePhoneNumberStates) => {
      UpdatePhoneNumberStates({
        [PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PROVINCE_GROUP_ID_ERROR]: ''
      })
    }
  },
  [PHONE_NUMBER_EVENTS.SPID]: {
    onBlur: (state, spids, UpdatePhoneNumberState) => {
      const spid = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID
      const spidError = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR

      if (isNullOrEmpty(state[spidError])) {
        if (state[spid] === null) {
          UpdatePhoneNumberState({
            [spidError]: PHONE_NUMBER_ERROR_KEYS.SPID_REQUIRED
          })
          return true
        }
        if (!spids.find((province) => province.value === state[spid])) {
          UpdatePhoneNumberState({
            spidError: PHONE_NUMBER_ERROR_KEYS.SPID_INVALID
          })
          return true
        }
        UpdatePhoneNumberState({
          spidError: null
        })
        return false
      }
    },
    onChange: (e, spids, UpdatePhoneNumberStates) => {
      const spid = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID
      const spidError = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.SELECTED_SPID_ERROR
      if (e.target.value !== '') {
        const matchedProv = spids.find((prov) => prov.name === e.target.value)
        if (matchedProv) {
          const id = matchedProv.value
          UpdatePhoneNumberStates({ [spid]: id, [spidError]: null })
        } else {
          UpdatePhoneNumberStates({
            [spid]: null,
            [spidError]: PHONE_NUMBER_ERROR_KEYS.SPID_INVALID
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [spid]: null,
          [spidError]: PHONE_NUMBER_ERROR_KEYS.SPID_REQUIRED
        })
      }
    }
  },
  [PHONE_NUMBER_EVENTS.ACCOUNT_NUMBER]: {
    onChange: (e, UpdatePhoneNumberStates) => {
      const { value } = e.target
      const updateKey =
        PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER
      let sanitizedValue = sanitizeTextField(value)
      sanitizedValue = sanitizeAccountNumber(sanitizedValue)
      UpdatePhoneNumberStates({
        [updateKey]: sanitizedValue
      })
    },
    onBlur: async (state, errors, maxLength, UpdatePhoneNumberStates) => {
      const value =
        state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER]
      const errorKey =
        PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.PREVIOUS_PROVIDER_ACCOUNT_NUMBER_ERROR
      if (value === '') {
        UpdatePhoneNumberStates({
          [errorKey]: errors.requiredErrorMessage
        })
        return true
      }
      if (value.length !== maxLength) {
        UpdatePhoneNumberStates({
          [errorKey]: errors.invalidErrorMessage
        })
        return true
      }
      UpdatePhoneNumberStates({
        [errorKey]: null
      })
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.ALTERNATE_CONTACT_NUMBER]: {
    onChange: (e, UpdatePhoneNumberStates) => {
      const { value } = e.target
      const updateKey = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER
      const sanitizedValue = value.replace(/\s|-/g, '').substring(0, 12)
      if (isNaN(sanitizedValue)) return
      const formattedValue = formattedPhoneNumber(sanitizedValue)
      UpdatePhoneNumberStates({
        [updateKey]: formattedValue
      })
    },
    onBlur: async (state, errors, maxLength, UpdatePhoneNumberStates) => {
      const value = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]
      const errorKey = PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER_ERROR
      if (value === '') {
        UpdatePhoneNumberStates({
          [errorKey]: errors.requiredErrorMessage
        })
        return true
      }
      if (value.length !== maxLength) {
        UpdatePhoneNumberStates({
          [errorKey]: errors.invalidErrorMessage
        })
        return true
      }
      UpdatePhoneNumberStates({
        [errorKey]: null
      })
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.POSTAL_CODE]: {
    onChange: (value, UpdatePhoneNumberStates) => {
      const firstLetterMatch = new RegExp(/([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])/)
      const letterMatch = new RegExp(/([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])/)
      const digitMatch = new RegExp(/\d/)

      const stringArray = value.split('')

      if (stringArray.length > 3 && stringArray.indexOf(' ') === -1) {
        stringArray.splice(3, 0, ' ')
      }
      const letters = [0, 2, 5]
      const numbers = [1, 4, 6]

      let matches = true

      stringArray.forEach((char, i) => {
        if (letters.indexOf(i) !== -1) {
          if (i === 0 && !char.match(firstLetterMatch)) {
            matches = false
          } else if (!char.match(letterMatch)) {
            matches = false
          }
        } else if (numbers.indexOf(i) !== -1) {
          if (!char.match(digitMatch)) {
            matches = false
          }
        }
      })
      if (matches) {
        const result = stringArray.join('')
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]: result
        })
      }
    },
    onBlur: (state, errors, UpdatePhoneNumberStates) => {
      const value = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE]
      if (value === '') {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR]:
            errors.postalCodeRequired
        })
        return true
      }
      const postalMatch = new RegExp(
        /([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])(.?)\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])\d/g
      )
      if (!value.match(postalMatch)) {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR]:
            errors.postalCodeInvalid
        })
        return true
      }
      UpdatePhoneNumberStates({
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.POSTAL_CODE_LANDLINE_ERROR]: null
      })
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.INPUT]: {
    onChange: (e, updateKey, updatePhoneNumberState) => {
      const { value } = e.target
      const sanitizedValue = sanitizeTextField(value)
      if (isDigitOrEmpty(sanitizedValue)) {
        updatePhoneNumberState({
          [updateKey]: sanitizedValue
        })
      }
    },
    onChangeName: (e, updateKey, updatePhoneNumberState) => {
      const { value } = e.target
      let sanitizedValue = value
      if (/^([\s]).*/.test(value)) {
        sanitizedValue = sanitizedValue.trim()
      }
      updatePhoneNumberState({
        [updateKey]: sanitizeNameField(sanitizedValue)
      })
    },
    onBlur: (
      value,
      errorKey,
      requiredErrorMessage,
      invalidErrorMessage,
      maxLength = MAX_INPUT_LENGTH,
      updatePhoneNumberState
    ) => {
      if (value === '') {
        updatePhoneNumberState({
          [errorKey]: requiredErrorMessage
        })
        return true
      }
      if (value.length !== maxLength) {
        updatePhoneNumberState({
          [errorKey]: invalidErrorMessage
        })
        return true
      }
      updatePhoneNumberState({
        [errorKey]: null
      })
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.IMEI]: {
    onChange: (e, UpdatePhoneNumberState) => {
      const { value } = e.target
      const sanitizedValue = sanitizeTextField(value)
      if (isDigitOrEmpty(sanitizedValue)) {
        UpdatePhoneNumberState({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]: sanitizedValue
        })
      }
    },
    onBlur: async (state, errors, UpdatePhoneNumberState) => {
      const imeiLength = new RegExp(/^(\d{14,15})$/)
      const value = state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI]
      if (value === '') {
        UpdatePhoneNumberState({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR]: errors.requiredErrorMessage
        })
        return true
      }
      if (!value.match(imeiLength)) {
        UpdatePhoneNumberState({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR]: errors.invalidErrorMessage
        })
        return true
      }
      UpdatePhoneNumberState({
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELESS.IMEI_ERROR]: null
      })
      return false
    }
  },
  [PHONE_NUMBER_EVENTS.INTERNET_TV]: {
    onBlur: (state, options, errorCopy, UpdatePhoneNumberState) => {
      if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]) {
        if (!state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]) {
          UpdatePhoneNumberState({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]:
              errorCopy.requiredErrorMessage
          })
          return true
        }
        if (
          !options.find(
            (opt) =>
              opt.value ===
              state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]
          )
        ) {
          UpdatePhoneNumberState({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]:
              errorCopy.invalidErrorMessage
          })
          return true
        }
        UpdatePhoneNumberState({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]: ''
        })
        return false
      }
      return true
    },
    onChange: (value, options, errorCopy, UpdatePhoneNumberStates) => {
      if (value) {
        const matchedOpt = options.find((opt) => opt.name === value)
        if (matchedOpt) {
          const id = matchedOpt.value
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: id,
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]: ''
          })
        } else {
          UpdatePhoneNumberStates({
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: '',
            [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]:
              errorCopy.invalidErrorMessage
          })
        }
      } else {
        UpdatePhoneNumberStates({
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES]: '',
          [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.WIRELINE.INTERNET_AND_TV_SERVICES_ERROR]:
            errorCopy.requiredErrorMessage
        })
      }
    }
  }
}
