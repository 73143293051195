import { APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import cookie from 'react-cookies'
import { LANGUAGES_CODE } from '@apps/utils/constants'
import config from '@/config'

const KEY_LANG = 'lang'
const KEY_REGION = 'prov'

const langOptions = ['en', 'fr']
const provOptions = ['ab', 'bc', 'mb', 'nb', 'nl', 'nt', 'ns', 'on', 'pe', 'qc', 'sk', 'yt']

const { cookieSecure } = config

export const changeLang = (newLang, location, history, updateAppState) => {
  const pathParts = location.pathname.split('/').filter((item) => item.length > 0)

  const currentLanguage = pathParts[0]
  document.documentElement.lang = newLang // setting the new language selected in HTML tag
  if (newLang === 'en') {
    // setting title tag
    document.title = 'Activate account'
  } else {
    document.title = 'activer le compte'
  }

  const newPath = location.pathname.replace(`/${currentLanguage.toLowerCase()}/`, `/${newLang}/`)
  history.push(newPath)
  
  updateAppState(newLang)

  cookie.save(KEY_LANG, newLang, { path: '/', secure: cookieSecure })
}

export const changeRegion = (newRegion, location, history, setAppState, appState) => {
  const pathParts = location.pathname.split('/').filter((item) => item.length > 0)

  const currentRegion = pathParts[1]

  const newPath = location.pathname.replace(
    `/${currentRegion.toLowerCase()}/`,
    `/${newRegion.toLowerCase()}/`
  )
  history.push(newPath)
  setAppState({
    ...appState,
    [APPLICATION_STATE.CURRENT_REGION]: newRegion,
    [APPLICATION_STATE.SHOW_SELECT_LANGUAGE]: false
  })

  cookie.save(KEY_REGION, newRegion, { path: '/', secure: cookieSecure })
}

export const getLocaleCookies = (req, res) => {
  const currentLang = req.universalCookies.get(KEY_LANG)?.toLowerCase()
  const currentRegion = req.universalCookies.get(KEY_REGION)?.toLowerCase()

  if (
    currentLang &&
    langOptions.includes(currentLang) &&
    currentRegion &&
    provOptions.includes(currentRegion)
  ) {
    return {
      lang: currentLang,
      prov: currentRegion
    }
  }

  const defaultLocale = {
    lang: LANGUAGES_CODE.EN,
    prov: 'on'
  }
  // set the response cookies for defaultLocale
  res.cookie(KEY_LANG, defaultLocale.lang, { path: '/', secure: cookieSecure })
  res.cookie(KEY_REGION, defaultLocale.prov, { path: '/', secure: cookieSecure })

  return defaultLocale
}

export const getLocale = (location) => {
  if (!location) return {}

  const pathParts = location.split('/').filter((item) => item.length > 0)

  const pathLang = pathParts[0] ? pathParts[0].toLowerCase() : undefined
  const pathProv = pathParts[1] ? pathParts[1].toLowerCase() : undefined

  if (
    pathParts.length > 0 &&
    langOptions.indexOf(pathLang) !== -1 &&
    provOptions.indexOf(pathProv) !== -1
  ) {
    cookie.save(KEY_LANG, pathLang, { path: '/', secure: cookieSecure })
    cookie.save(KEY_REGION, pathProv, { path: '/', secure: cookieSecure })
    return {
      lang: pathLang,
      prov: pathProv
    }
  }

  return {
    lang: LANGUAGES_CODE.EN,
    prov: 'on'
  }
}
