import React, { useContext, useEffect, useState, useMemo } from 'react'
import Button from '@apps/components/Button'
import Modal from '@apps/components/Modal'
import { useSelector } from 'react-redux'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import Sites from '@sites/index'
import { getById, getBySlug } from '@apps/utils/contentful'
import classNames from 'classnames'
import { containsProvince } from '@apps/utils/common'
import { PHONE_NUMBER_STATE } from '@apps/redux/features/PhoneNumberSlice'
import { PHONE_NUMBER_OPTIONS } from '@apps/utils/constants'

const PlanUnavailablePopup = ({
  province,
  onClose,
  onNewPhoneNumberClick,
  onNewPlanClick,
  onValidate,
  disableClose
}) => {
  const {
    [PLANS_STATE.PLAN]: planInfo,
    [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan,
    localizedPlanItem
  } = useSelector((state) => state.plansPage)

  const { [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: selectedPhoneNumberOption } =
    useSelector((state) => state.phoneNumberPage)
  const { userType } = useSelector((state) => state.common)
  const { pageContent } = useSelector((state) => state.common.content)

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const { [APPLICATION_STATE.CURRENT_LANGUAGE]: language } = useContext(ApplicationContext)

  const selectedNewNumber = selectedPhoneNumberOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER

  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const phoneNumberPageContent = getById(pageContent, `phoneNumberPage${pagePrefix}`) || {}
  const { shortTextFields, longTextFields } = phoneNumberPageContent[language] || {}

  const textContent = useMemo(() => {
    return {
      incompatibleNumberMessage: getBySlug(shortTextFields, 'incompatibleNumberMessage')?.value,
      incompatiblePortInMessage: getBySlug(longTextFields, 'incompatiblePortInMessage')?.value,
      incompatibleNumberTitle: getBySlug(shortTextFields, 'incompatibleNumberTitle')?.value,
      incompatiblePortInTitle: getBySlug(shortTextFields, 'incompatiblePortInTitle')?.value,
      selectNewPhoneNumber: getBySlug(shortTextFields, 'selectNewPhoneNumber')?.value,
      selectNewPlan: getBySlug(shortTextFields, 'selectNewPlan')?.value
    }
  }, [shortTextFields])

  const { selectNewPhoneNumber, selectNewPlan } = textContent

  const popupTextContent = useMemo(() => {
    if (selectedNewNumber) {
      return {
        title: textContent?.incompatibleNumberTitle,
        message: textContent?.incompatibleNumberMessage
      }
    }
    return {
      title: textContent?.incompatiblePortInTitle,
      message: textContent?.incompatiblePortInMessage
    }
  }, [textContent, selectedNewNumber])

  const [showPlanUnavailable, setShowPlanUnavailable] = useState(false)

  const validateCurrentPlanInProvince = async (prov) => {
    let planAvailable = false
    const langPlans = localizedPlanItem[language]
    if (langPlans && langPlans.length > 0) {
      const { plans } = langPlans[0]
      planAvailable = plans?.some(
        (plan) =>
          plan?.id === planInfo?.id && (!plan?.province || containsProvince(plan?.province, prov))
      )
    }
    if (!planAvailable) {
      setShowPlanUnavailable(true)
    }
    if (onValidate) {
      onValidate(planAvailable)
    }
  }

  useEffect(() => {
    if (province && planInfo?.id) {
      validateCurrentPlanInProvince(province)
    }
  }, [province, planInfo])

  const handleClosePopup = () => {
    setShowPlanUnavailable(false)
  }

  const handleClose = () => {
    handleClosePopup()
    if (onClose) {
      onClose()
    }
  }

  const handlePhoneNumberClick = () => {
    handleClosePopup()
    if (onNewPhoneNumberClick) {
      onNewPhoneNumberClick()
    }
  }

  const handleChangePlanClick = () => {
    handleClosePopup()
    if (onNewPlanClick) {
      onNewPlanClick()
    }
  }

  return (
    <Modal
      isOpen={showPlanUnavailable}
      closeModal={handleClose}
      title={popupTextContent?.title}
      showControls={!disableClose}
      disableClose={disableClose}
    >
      <div className="text-black leading-6 text-sm">
        <div className="mt-6 mb-9">
          <p className="text-sm font-body leading-6">{popupTextContent?.message}</p>
        </div>
        <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-8">
          {selectedNewNumber && (
            <Button
              type="button"
              label={selectNewPhoneNumber}
              className="w-full rounded-md font-h4"
              onClick={handlePhoneNumberClick}
            />
          )}
          <Button
            type="button"
            onClick={handleChangePlanClick}
            label={selectNewPlan}
            className={classNames('rounded-md font-h4', selectedNewNumber ? 'w-full' : 'w-1/2')}
          />
        </div>
      </div>
    </Modal>
  )
}

export default PlanUnavailablePopup
