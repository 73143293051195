import React from "react"
import PropTypes from 'prop-types'

const Heading2 = ({id, children, className}) => {
  return (
    <h2 className={className} id={id}>
      {children}
    </h2>
  )
}
Heading2.prototype = {
  id: PropTypes.string,
  children: PropTypes.string,
  styles: PropTypes.string
}

export default Heading2