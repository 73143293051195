/* eslint-disable react/no-multi-comp */
/* eslint-disable import/extensions */
import React, { useRef, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { setPageAnalytics } from '@apps/utils/Analytics'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import { ContentContext } from '@apps/contexts/ContentContext'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { useHistory } from 'react-router-dom'
import { getBySlug, getShortTextFromPage, getById } from '@apps/utils/contentful'
import { PAYMENT_STATE, updatePaymentStates } from '@apps/redux/features/PayAndReviewSlice'
import Sites from '@sites/index'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllPlans, PLANS_STATE, updatePlansState } from '@apps/redux/features/PlansSlice'
import { recordAccountInfo, setQuoteInfo, recordUserInfo, recordAccountByEmailInfo,
  recordQuoteByEmailInfo } from '@apps/redux/features/CommonSlice'
import ReCAPTCHA from 'react-google-recaptcha'
import Brands from '@brands/index'
import { PROMO_TYPE } from '@apps/utils/constants'
import useRecaptcha from '@apps/components/ReCaptcha/index'
import envConfig from '@platformConfig/index'
import { isMobile } from 'react-device-detect'
import Link from '@/apps/components/Link'
import BodyLarge from '@/apps/components/Typography/BodyLarge'
import BodyBold from '@/apps/components/Typography/BodyBold'
import BodySmall from '@/apps/components/Typography/BodySmall'
import BodySmallBold from '@/apps/components/Typography/BodySmallBold'
import Heading1 from '@/apps/components/Typography/Heading1'
import Heading2 from '@/apps/components/Typography/Heading2'
import Heading4 from '@/apps/components/Typography/Heading4'
import RichText from '@/apps/components/RichText'
import Button from '@/apps/components/Button'
import Toast from '@/apps/components/Toast'
import Modal from '@/apps/components/Modal'
import useLocale from '../../../_engine/hooks/useLocale'

const SuccessfulValidation = () => {
  return (
    <svg
      width="7rem"
      height="7rem"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="successful-validation"
    >
      <path
        d="M52 104C80.7188 104 104 80.7188 104 52C104 23.2812 80.7188 0 52 0C23.2812 0 0 23.2812 0 52C0 80.7188 23.2812 104 52 104Z"
        fill="#016B6A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6211 49.316C13.0088 28.1916 30.5323 11.4835 51.996 11.4835C74.3662 11.4835 92.5177 29.6198 92.5177 51.9951C92.5177 74.3603 74.3662 92.5169 51.996 92.5169C44.9714 92.5169 38.3621 90.7088 32.5986 87.5688C20.0233 80.686 11.4844 67.3408 11.4844 51.9951C11.4844 51.1557 11.5452 50.3379 11.6064 49.5137C11.6113 49.4479 11.6162 49.3819 11.6211 49.316ZM43.0215 72.7247L43.062 72.6741L77.3037 38.4628L70.117 31.2711L43.0215 58.3615L33.8647 49.2352L26.6984 56.3965L43.0215 72.7247Z"
        fill="white"
      />
      <circle
        cx="52"
        cy="52"
        r="42"
        stroke="white"
        strokeWidth="21"
        fill="transparent"
        transform="rotate(-90, 52, 52)"
      />
      <rect width="2" height="50" x="76" y="26" />
    </svg>
  )
}

const IntroPage = ({ path = '' }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const otpBoxReference = useRef([])
  const {
    content: { pageContent, featureFlags: flags },
    userType
  } = useSelector((state) => state.common)

  const { localizedPlanItem } = useSelector((state) => state.plansPage)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: region,
    [APPLICATION_STATE.SERVICES]: brandServices
  } = useContext(ApplicationContext)

  const landingPageContent = useMemo(() => {
    if (!pageContent || !language) return {}
    const landingPage = getById(pageContent, 'landingPage')
    return landingPage[language]
  }, [pageContent, language])

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
  const [activationTitle, setActivationTitle] = useState()
  const [activationButtonLabel, setActivationButtonLabel] = useState()

  const {
    googleReCaptchaConfig: { siteKey }
  } = envConfig

  const { recaptchaRef, handleRecaptcha } = useRecaptcha()
  const { lang } = useLocale()

  const { dynamicContent } = useContext(ContentContext)

  const {
    isVerifyToken,
    retrieveAccountByEmail,
    retrieveQuoteByEmail,
    validateUserDetails,
    validateOtp,
    createCookie,
    retrieveCookie,
    deleteCookie
  } = brandServices

  const numberOfDigits = 6
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [inputs, setInputs] = useState(Array(numberOfDigits).fill('')) // Initialize input states
  const [showError, setShowError] = useState(false)
  const [isEmailRequired, setEmailRequired] = useState(false)
  const [showMaxAttemptExceeded, setShowMaxAttemptExceeded] = useState(false)
  const [showCaptchError, setCaptchError] = useState(false)
  const [showResendError, setResendError] = useState(false)
  const [showUserBlockedError, setUserBlockedError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [disableResendBtn, setDisableResendBtn] = useState(false)
  const [resetTimer, setResetTimer] = useState(false)
  const [disabledOtpInput, setDisableOtpInput] = useState(false)
  const [otpExpired, setOtpExpired] = useState(false)
  const [isCodeTimerExpired, setIsCodeTimerExpired] = useState(false)

  const [email, setEmail] = useState('')
  const emailValidationTimeoutRef = useRef(null)
  let cookieId = ''

  const redirect = () => {
    history.push(path)
  }

  const handleEmailModal = (e) => {
    if (e === 'new') {
      setActivationTitle(
        getShortTextFromPage(landingPageContent, 'register-your-email-address-header')
      )
      setActivationButtonLabel(getShortTextFromPage(landingPageContent, 'register'))
    } else {
      setActivationTitle(getShortTextFromPage(landingPageContent, 'continue-your-activation'))
      setActivationButtonLabel(getShortTextFromPage(landingPageContent, 'continue-activation-button-label'))

    }

    setEmail('')
    setInputs(Array(numberOfDigits).fill(''))
    setIsEmailModalOpen(true)
    setIsCodeModalOpen(false)
    setEmailRequired(false)
    setShowError(false)
    setCaptchError(false)
    setResendError(false)
    setShowToast(false)
    setShowMaxAttemptExceeded(false)
    setUserBlockedError(false)
    setOtpExpired(false)
    setDisableOtpInput(false)
  }

  const handleCloseModal = () => {
    setIsEmailModalOpen(false)
    setIsCodeModalOpen(false)
    setIsSuccessModalOpen(false)
    setShowError(false)
    setCaptchError(false)
    setResendError(false)
    setShowToast(false)
    setShowMaxAttemptExceeded(false)
    setOtpExpired(false)
    setDisableOtpInput(false)
  }

  const handleCloseOpt = () => {
    setIsCodeModalOpen(false)
    setAttemptsLeft(Number(textContent?.otpMaxAttempts))
    setShowError(false)
    setCaptchError(false)
    setResendError(false)
    setShowToast(false)
    setShowMaxAttemptExceeded(false)
    setUserBlockedError(false)
    setOtpExpired(false)
    setDisableOtpInput(false)
  }

  const handleSuccessfulCloseModal = () => {
    setIsEmailModalOpen(false)
    setIsCodeModalOpen(false)
    setIsSuccessModalOpen(false)
    setShowMaxAttemptExceeded(false)
    setOtpExpired(false)
  }

  const { planName } = useParams()

  const paymentState = useSelector((state) => state.payAndReviewPage)

  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const {
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo,
    [PAYMENT_STATE.PROMO.MULTI_PROMO_CODES]: multiPromoCodes
  } = paymentState

  const consumerActivationPaths = new ConsumerActivationPaths()
  const featureFlags = flags && flags[0]
  if (featureFlags?.showSplashing) {
    history.push(consumerActivationPaths.Splash)
  }

  const { shortTextFields } = landingPageContent
  const { landingPage: { header = {}, content = [] } = {} } = dynamicContent || {}

  const isNewUser = featureFlags?.isNewUser
  const isExistingUser = featureFlags?.isExistingUser

  const location = useLocation()

  useEffect(() => {
    // Set Analytics dataLayer
    setPageAnalytics(location, 'form', region, language)
  }, [language])

  const planPreSelected = () => {
    return !!planName
  }

  const updatePlansByLanguage = () => {
    if (localizedPlanItem && Object.keys(localizedPlanItem).length === 0) return
    const plansObject = localizedPlanItem?.[language]?.find(Boolean)
    const { plans } = plansObject
    const selectedPlan = plansObject?.plans?.find((plan) => {
      return plan?.sluggedName?.toLowerCase() === planName?.toLowerCase()
    })
    dispatch(loadAllPlans(plans))

    if (selectedPlan) {
      if (selectedPlan?.promo) {
        if (
          featureFlags?.enableMultiPromo &&
          multiPromotionCodeInfo &&
          multiPromotionCodeInfo?.length > 0 &&
          multiPromoCodes &&
          multiPromoCodes?.length > 0
        ) {
          const updatedPromotionCodeInfo = multiPromotionCodeInfo.filter(
            (code) => code?.id === selectedPlan.promo?.id
          )
          const updatedPromoCodes = multiPromoCodes.filter(
            (code) => code === PROMO_TYPE.AUTOMATIC_PROMO
          )

          updatePaymentStore({
            ...paymentState,
            multiPromotionCodeInfo: updatedPromotionCodeInfo,
            multiPromoCodes: updatedPromoCodes
          })
        }

        if (
          featureFlags?.enableMultiPromo &&
          multiPromotionCodeInfo?.length === 0 &&
          multiPromoCodes?.length === 0
        ) {
          const newState = { ...paymentState }
          newState.multiPromotionCodeInfo = [...newState.multiPromotionCodeInfo, selectedPlan.promo]
          newState.multiPromoCodes = [...newState.multiPromoCodes, PROMO_TYPE.AUTOMATIC_PROMO]
          updatePaymentStore({
            ...newState
          })
        }
      }
      dispatch(
        updatePlansState({
          [PLANS_STATE.PLAN]: selectedPlan,
          [PLANS_STATE.PRE_SELECTED_PLAN]: true
        })
      )
    } else {
      window.location = consumerActivationPaths.Base
    }
  }

  useEffect(() => {
    if (region && userType === Sites.consumer && planPreSelected()) {
      updatePlansByLanguage()
    }
  }, [language, userType, planPreSelected(), localizedPlanItem])

  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      activationGetStartedMessage: getBySlug(shortTextFields, 'activationGetStartedMessage')?.value,
      activationPrivacyPolicy: getBySlug(shortTextFields, 'activationPrivacyPolicy')?.value,
      activationPrivacyPolicyLink: getBySlug(shortTextFields, 'activationPrivacyPolicyLink')?.value,
      activationGetStarted: getBySlug(shortTextFields, 'activationGetStarted')?.value,
      phoneCompatibility: getBySlug(shortTextFields, 'phoneCompatibility')?.value,
      findOutMore: getBySlug(shortTextFields, 'findOutMore')?.value,
      phoneCompatibilityHelp: getBySlug(shortTextFields, 'phoneCompatibilityHelp')?.value,
      otpMaxAttempts: getBySlug(shortTextFields, 'otpMaxAttempts')?.value,
      otpMaxValidations: getBySlug(shortTextFields, 'otpMaxValidations')?.value,
      minsForUnblockingUser: getBySlug(shortTextFields, 'minsForUnblockingUser')?.value,
      otpExpirationTime: getBySlug(shortTextFields, 'otpExpirationTime')?.value,
      registerFailure: getBySlug(shortTextFields, 'registerFailure')?.value,
      captchaFailure: getBySlug(shortTextFields, 'captchaFailure')?.value,
      invalidEmail: getBySlug(shortTextFields, 'invalidEmail')?.value,
      otpMaxAttemptExceed: getBySlug(shortTextFields, 'otpMaxAttemptExceed')?.value,
      resendError: getBySlug(shortTextFields, 'resendError')?.value,
      userBlockAfterMultipleRetry: getBySlug(shortTextFields, 'userBlockAfterMultipleRetry')?.value,
      wrongCode: getBySlug(shortTextFields, 'wrong-code')?.value,
      otpExpiredError: getBySlug(shortTextFields, 'otp-expired-error')?.value,
      verificationSuccessfulFirst: getBySlug(shortTextFields, 'verification-successful-first')?.value,
      verificationSuccessfulSecond: getBySlug(shortTextFields, 'verification-successful-second')?.value,
      youHaveAttemptsLeftFirst: getBySlug(shortTextFields, 'you-have-attempts-left-first')?.value,
      youHaveAttemptsLeftSecond: getBySlug(shortTextFields, 'you-have-attempts-left-second')?.value,
      emailCharMaxLimit: getBySlug(shortTextFields, 'email-char-max-limit')?.value
    }
  }, [shortTextFields])

  const maxAttemptsCount = textContent?.otpMaxAttempts ? Number(textContent?.otpMaxAttempts) : 3
  const [attemptsLeft, setAttemptsLeft] = useState(maxAttemptsCount)

  const initialMinutes = Number(textContent?.otpExpirationTime)
  const [time, setTime] = useState(initialMinutes * 60)

  const handleInputChange = (value, index) => {
    if (isMobile) {
      const numericValue = value.replace(/\D/g, '')
      setInputs(numericValue.split('').slice(0, numberOfDigits))
    } else {
      const numericValue = value.replace(/\D/g, '')
      const newInputs = [...inputs]
      newInputs[index] = numericValue
      setInputs(newInputs)

      // Handle focus movement for desktop view
      if (value === '' && index > 0) {
        otpBoxReference.current[index - 1].focus()
      } else if (index < numberOfDigits - 1 && numericValue !== '') {
        otpBoxReference.current[index + 1].focus()
      }
    }
  }

  const handleBackspaceAndEnter = (e, index) => {
    if (isMobile) {
      return
    }

    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus()
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus()
    }
  }

  const onPaste = (event) => {
    const pasted = event.clipboardData.getData('text/plain')

    if (/^\d{6}$/.test(pasted)) {
      const numericPasted = pasted.replace(/\D/g, '')

      if (isMobile) {
        setInputs(numericPasted.split('').slice(0, numberOfDigits))
      } else {
        setInputs(numericPasted.split('').slice(0, numberOfDigits))

        // Move focus to the next empty input box after pasting for desktop view
        const nextEmptyIndex = numericPasted.length
        if (nextEmptyIndex < numberOfDigits) {
          otpBoxReference.current[nextEmptyIndex].focus()
        }
      }
    }
  }

  const verifyCode = async () => {
    setIsLoading(true)
    const code = inputs.join('')
    if (!code) {
      setIsLoading(false)
      return
    }
    const { data: validOtpData, error: validOtpError } = code
      ? await validateOtp({ email, phoneNumber: '', provider: Brands.koodo, otp: code })
      : ''
    if (validOtpError) {
      setIsLoading(false)
      attemptsLeft === 0 ? setShowMaxAttemptExceeded(true) : setOtpExpired(true)
      setShowToast(false)
      setInputs(Array(numberOfDigits).fill(''))
      setDisableOtpInput(true)
      return
    }
    if (validOtpData) {
      setIsLoading(false)

      const { validate, maxAttempts, maxValidations } = validOtpData
      if (!validate && (maxAttempts || maxValidations) >= 0) {
        setAttemptsLeft(maxAttempts ? maxAttempts : maxValidations)
        setShowMaxAttemptExceeded(false)
        setOtpExpired(false)
        setShowToast(true)
        return
      } else {

        let userInfo = {}
        userInfo.email = email
        userInfo.isLoggedIn = true
        dispatch(recordUserInfo(userInfo))
        await updateCookie()
        await createCookie({email, flag: true, sessionId: cookieId})
        setIsCodeModalOpen(false)
        setIsSuccessModalOpen(true)
        setTimeout(() => {
          redirect()
        }, 500)
      }
    }
  }
  const validateUser = async () => {
    await updateCookie()
    const { data: validateUserData, error: validateUserError } = await validateUserDetails({
      email,
      phoneNumber: '',
      provider: Brands.koodo,
      language: lang?.toUpperCase(),
      type: '',
      sessionId: cookieId,
      maxAttempts: Number(textContent?.otpMaxAttempts),
      maxValidations: Number(textContent?.otpMaxValidations),
      minsForUnblockingUser: Number(textContent?.minsForUnblockingUser),
      otpExpirationTime: Number(textContent?.otpExpirationTime)
    })
    if (validateUserError) {
      setIsEmailModalOpen(true)
      setIsCodeModalOpen(false)
      setIsLoading(false)
      setShowError(true)
      return
    }
    if (validateUserData) {
      setIsLoading(false)
      const { validate } = validateUserData
      if (!validate) {
        setIsEmailModalOpen(true)
        setIsCodeModalOpen(false)
        setShowError(true)
        return
      }
    }
    setIsEmailModalOpen(false)
    setIsCodeModalOpen(true)
    return
  }

  const updateCookie = async () => {
    const { userSessionId, userEmail } = {} = await retrieveCookie() || {}
    if (userSessionId && userEmail?.toLowerCase() === email?.toLowerCase()) {
      cookieId = userSessionId
    }
    if (!userSessionId || userEmail?.toLowerCase() !== email?.toLowerCase()) {
      await deleteCookie()
      const { data } = await createCookie({ email, flag: false, sessionId: userSessionId })
      cookieId = data?.sessionId
    }
  }

  const onResendClick = async () => {
    // Resend logic here
    await updateCookie()
    setDisableResendBtn(true)
    const { data: validateUserData, error: validateUserError } = await validateUserDetails({
      email,
      phoneNumber: '',
      provider: Brands.koodo,
      language: lang?.toUpperCase(),
      type: '',
      sessionId: cookieId,
      maxAttempts: Number(textContent?.otpMaxAttempts),
      maxValidations: Number(textContent?.otpMaxValidations),
      minsForUnblockingUser: Number(textContent?.minsForUnblockingUser),
      otpExpirationTime: Number(textContent?.otpExpirationTime)
    })
    if (validateUserError) {
      setShowToast(false)
      setShowMaxAttemptExceeded(false)
      setOtpExpired(false)
      setResendError(false)
      setUserBlockedError(true)
      return
    }
    if (validateUserData) {
      const { validate } = validateUserData
      setShowToast(false)
      setShowMaxAttemptExceeded(false)
      setOtpExpired(false)
      if (!validate) {
        setResendError(true)
        return
      }
      setTime(initialMinutes * 60)
      setResetTimer(!resetTimer)
      setDisableOtpInput(false)
      setInputs(Array(numberOfDigits).fill(''))
      setAttemptsLeft(Number(textContent?.otpMaxAttempts))
    }
  }

  const handleEmailChange = (event) => {
    const emailValue = event.target.value
    if (emailValue?.length <= Number(textContent?.emailCharMaxLimit)) {
      
      const sanitizedEmailValue = emailValue.replace(/[^a-zA-Z0-9@._+-]/g, '')
      setEmail(sanitizedEmailValue)
  
      if (emailValidationTimeoutRef.current) {
        clearTimeout(emailValidationTimeoutRef.current)
      }
  
      emailValidationTimeoutRef.current = setTimeout(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (emailRegex.test(sanitizedEmailValue)) {
          setEmailRequired(false)
        } else {
          setEmailRequired(true)
        }
      }, 1000)
    } else {
      setEmailRequired(true)
    }
  }

  const handleKeyDown = (event) => {
    const invalidChars = [
      '!',
      '#',
      '$',
      '%',
      '^',
      '&',
      '*',
      '(',
      ')',
      '=',
      '[',
      ']',
      '{',
      '}',
      '\\',
      '|',
      '',
      ':',
      "'",
      '"',
      ',',
      '<',
      '>',
      '/',
      '?',
      '`',
      '~'
    ]

    if (invalidChars.includes(event.key) || event.key === ' ') {
      event.preventDefault()
    }
  }

  const handleEmailBlur = () => {
    if(email?.length <= Number(textContent?.emailCharMaxLimit)) {
      setEmailRequired(false)
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (emailRegex.test(email)) {
      setEmailRequired(false)
    } else {
      setEmailRequired(true)
    }
  }

  const handleEmailError = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!email || email?.length === 0 || !regex.test(email)) {
      setEmailRequired(true)
      return false
    }
    setEmailRequired(false)
    return true
  }

  const loginUser = async (userEmail) => {
    const userInfo = {}
    userInfo.email = userEmail
    userInfo.isLoggedIn = true
    dispatch(recordUserInfo(userInfo))
    await createCookie({ email, flag: true, sessionId: cookieId })
    redirect()
  }

  const registerUser = async () => {
    try {
      const isEmailValid = handleEmailError()
      setShowError(false)

      const captchaToken = isEmailValid ? await recaptchaRef.current.executeAsync() : ''
      recaptchaRef.current.reset()
      const isTokenVerified = isEmailValid ? await isVerifyToken(captchaToken) : ''
      if (!isTokenVerified?.success && isEmailValid) {
        setCaptchError(true)
        return
      }
      if (isTokenVerified?.success && email) {
        setIsLoading(true)
        const retrieveAccountByEmailResponse = await retrieveAccountByEmail(email)
        const { data: autheticateData, error: autheticateError } = retrieveAccountByEmailResponse

        if (autheticateData) {
          dispatch(recordAccountByEmailInfo(retrieveAccountByEmailResponse))
          const { roles } = autheticateData
          if (roles?.includes('KPRE_UCARE_ACCESS')) {
            // Returning user
            if (isExistingUser) {
              recordAccountInfo(autheticateData)
              await validateUser()
            } else {
              loginUser(email)
            }
          } else if (roles?.includes('KPRE_AP_ACCESS')) {
            const retrieveQuoteByEmailResponse = await retrieveQuoteByEmail({ email })
            const {
              data: retrieveQuoteData,
              error: retrieveQuoteError
            } = retrieveQuoteByEmailResponse
            if (retrieveQuoteError) {
              setShowError(true)
              return
            }
            dispatch(recordQuoteByEmailInfo(retrieveQuoteByEmailResponse))
            const { quoteId } = retrieveQuoteData
            if (quoteId) {
              // Returning user
              if (isExistingUser) {
                dispatch(setQuoteInfo(retrieveQuoteData))
                await validateUser()
              } else {
                loginUser(email)
              }
            } else {
              // New user
              if (isNewUser) {
                await validateUser()
              } else {
                loginUser(email)
              }
              return
            }
          } else {
            // Unauthorized - Block access
            setShowError(true)
            setIsLoading(false)
            return
          }
        }
        if (autheticateError) {
          setShowError(true)
          return
        }
      } else {
        setShowError(false)
        setCaptchError(false)
        setEmailRequired(true)
        return
      }
    } catch (error) {
      console.error("Error in registerUser:", error)
      setShowError(true)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 5000)
    }
  }

  useEffect(() => {
    let timer

    if (isCodeModalOpen) {
      setIsCodeTimerExpired(false)
      timer = setInterval(() => {
        setTime(prevTime => {
          if (prevTime > 0) {
            return prevTime - 1
          } else {
            clearInterval(timer)
            setDisableResendBtn(false)
            setIsCodeTimerExpired(true)
            return 0
          }
        })
      }, 1000)
    } else {
      setTime(initialMinutes * 60)
      setDisableResendBtn(true)
    }

    // Clear the interval on component unmount or when modal closes
    return () => clearInterval(timer)
  }, [isCodeModalOpen, initialMinutes, resetTimer])

  // Format the time in mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
  }

  return (
    <>
      <div className="flex flex-col max-w-6xl px-4 pb-12 m-auto mt-10 space-y-8 lg:space-y-12">
        <div className="lg:space-y-4">
          {header && (
            <>
              <Heading1 className="text-primary">{header.title}</Heading1>
              <BodySmallBold>{header.subtitle}</BodySmallBold>
            </>
          )}
        </div>
        <div className="flex flex-col space-y-4 md:space-y-0 lg:space-y-0 md:flex-row lg:flex-row md:space-x-4 lg:space-x-4">
          {content &&
            content.map((item, index) => {
              const {
                styledContent,
                id,
                image: { url, title: imageTitle },
                title,
                footer,
                footer2
              } = item
              if (index === 2 && planPreSelected()) return null
              return (
                <div
                  key={id}
                  className="flex flex-col flex-1 p-4 border-2 border-black lg:space-y-4"
                >
                  <div className="hidden md:block lg:block">
                    <img className="p-4" src={url} alt={imageTitle} />
                  </div>
                  <BodyBold className="leading-6">{title}</BodyBold>
                  <div className="flex flex-col h-full">
                    <RichText
                      className="pb-6"
                      config={{
                        hyperlink: { className: 'break-all font-h1 text-primary text-sm font-bold' }
                      }}
                    >
                      {styledContent}
                    </RichText>
                    {footer && (
                      <RichText
                        className="pb-6"
                        config={{
                          paragraph: { className: 'text-copy text-sm font-body leading-6' },
                          hyperlink: {
                            className: 'break-all font-h1 text-primary text-sm font-bold'
                          }
                        }}
                      >
                        {footer}
                      </RichText>
                    )}

                    {footer2 && (
                      <RichText
                        className="pb-4"
                        config={{
                          paragraph: { className: 'text-copy text-xs font-body leading-5' }
                        }}
                      >
                        {footer2}
                      </RichText>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
        <div className="flex flex-col items-center self-center justify-center w-full">
          <div className="w-full sm:w-3/4">
            <BodySmall className="sm:text-center sm:block">
              {textContent?.activationGetStartedMessage}{' '}
              <Link
                target="_blank"
                href={textContent?.activationPrivacyPolicyLink}
                className="font-h1"
              >
                {textContent?.activationPrivacyPolicy}
              </Link>
            </BodySmall>
          </div>
          <div className="w-full sm:w-4/12">
            {textContent?.activationGetStarted && (
              <Button
                testId="get-started"
                onClick={() => handleEmailModal('new')}
                label={textContent?.activationGetStarted}
                className="w-full mt-8 font-h4"
              />
            )}
          </div>

          <Modal
            isOpen={isEmailModalOpen}
            closeModal={handleCloseModal}
            title={activationTitle}
            showBackLink={true}
          >
            <p className="text-sm text-gray-700 my-8">
              {getShortTextFromPage(landingPageContent, 'register-your-email-address-info')}
            </p>
            <div className="mb-4">
              <BodyBold className="leading-8">
                {getShortTextFromPage(landingPageContent, 'email-address')}
              </BodyBold>
              <input
                type="email"
                id="email"
                className="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder={getShortTextFromPage(landingPageContent, 'email-address-placeholder')}
                value={email}
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
                onBlur={handleEmailBlur}
                maxLength={Number(textContent?.emailCharMaxLimit)}
              />
            </div>

            <div className="flex w-full items-center justify-center">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={siteKey}
                size="invisible"
                onChange={handleRecaptcha}
                onExpired={() => {
                  recaptchaRef.current.reset() // here
                }}
              />
            </div>
            <div className="flex items-center justify-center">
              <Button
                onClick={registerUser}
                label={activationButtonLabel}
                className="w-full my-2 md:my-4 font-h4"
                internalLoader={isLoading}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
            {(showError || showCaptchError || isEmailRequired) && (
              <Toast
                type="danger"
                text={
                  showError
                    ? textContent?.registerFailure
                    : showCaptchError
                    ? textContent?.captchaFailure
                    : isEmailRequired
                    ? textContent?.invalidEmail
                    : ''
                }
                className="mb-8 items-center bg-wrongCode"
                textClassName="text-sm font-h1 tracking-wider text-copy"
                testId="modal-wrong-security-code"
              />
            )}
          </Modal>

          <Modal
            isOpen={isCodeModalOpen}
            closeModal={handleCloseOpt}
            showBackLink={true}
          >
            <div className="md:mx-6">
             {!showUserBlockedError && <h4 className="text-modalInfo mb-4 text-center font-semibold">
                {getShortTextFromPage(landingPageContent, 'sent-the-security-code')}{' '}
                <button
                  onClick={onResendClick}
                  className={`text-center underline focus:outline-none ${disableResendBtn ? 'text-hover-background cursor-not-allowed' : 'text-grape'}`}
                  disabled={disableResendBtn}
                >
                  {getShortTextFromPage(landingPageContent, 'click-here-resend')}
                </button>
              </h4>}
              <div className={`${showUserBlockedError ? '' : "border-t border-grey-dark pt-4"}`}>
                {!showUserBlockedError ?
                  (
                    <>
                      <Heading4 className="text-center my-4 text-step">
                      {getShortTextFromPage(landingPageContent, 'please-enter-6-digit')}{' '}
                      <span className="text-center hover:underline focus:outline-none text-grape">
                        ({email})
                      </span>
                      </Heading4>
                      {isCodeTimerExpired ? (
                        <BodyLarge className="text-center my-4">
                          {getShortTextFromPage(landingPageContent, 'code-timer-expired-message')}
                        </BodyLarge>
                      ) : (
                        <BodyLarge className="text-center my-4">
                          {getShortTextFromPage(landingPageContent, 'code-expires')}{' '}
                          <span className="text-grape pl-1">{formatTime(time)}</span>
                        </BodyLarge>
                      )}
                      
                      <div className="flex justify-center items-center my-5 md:my-9 w-full">
                        <div className="flex-grow flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2 items-center">
                          <div className="block md:hidden w-full h-12">
                            <input
                              type="text"
                              maxLength="6"
                              className={`w-full h-12 text-lg border border-gray-300 rounded ${disabledOtpInput ? 'cursor-not-allowed' : ''}`}
                              ref={(reference) => (otpBoxReference.current[0] = reference)}
                              autoFocus={true}
                              value={inputs.join('')}
                              onKeyUp={(e) => handleBackspaceAndEnter(e, 0)}
                              onChange={(e) => {
                                const newValue = e.target.value
                                handleInputChange(newValue, 0)
                              }}
                              onPaste={onPaste}
                              disabled={disabledOtpInput}
                            />
                          </div>
                          <div className="hidden md:flex w-full">
                            {inputs.map((input, index) => (
                              <input
                                key={index}
                                type="text"
                                maxLength="1"
                                ref={(reference) => (otpBoxReference.current[index] = reference)}
                                className={`w-12 h-12 text-center text-lg border border-gray-300 rounded mr-4 ${disabledOtpInput ? 'cursor-not-allowed' : ''}`}
                                autoFocus={index === 0}
                                value={input}
                                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                onChange={(e) => {
                                  const newValue = e.target.value
                                  handleInputChange(newValue, index)
                                }}
                                onPaste={onPaste}
                                disabled={disabledOtpInput}
                              />
                            ))}
                          </div>
                          <Button
                            onClick={verifyCode}
                            label={getShortTextFromPage(landingPageContent, 'verify')}
                            className={`w-full md:w-36 md:h-11 font-h4 mt-4 md:mt-0 flex items-center justify-center ${disabledOtpInput ? 'cursor-not-allowed' : ''}`}
                            internalLoader={isLoading}
                            isLoading={isLoading}
                            disabled={isLoading || disabledOtpInput}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Heading2 className="text-center my-5 text-grape">
                        {getShortTextFromPage(landingPageContent, 'user-blocked-heading')}
                      </Heading2>
                    </>
                  )
                }

                {(showToast ||
                  showMaxAttemptExceeded ||
                  showResendError ||
                  showUserBlockedError || otpExpired) && (
                  <Toast
                    type="danger"
                    text={
                      showToast
                        ? textContent?.wrongCode
                        : otpExpired
                        ? textContent?.otpExpiredError
                        : showMaxAttemptExceeded
                        ? textContent?.otpMaxAttemptExceed
                        : showResendError
                        ? textContent?.resendError
                        : showUserBlockedError
                        ? textContent?.userBlockAfterMultipleRetry
                        : ''
                    }
                    className="mb-8 items-center bg-wrongCode"
                    textClassName="text-sm font-h2 tracking-wider text-copy"
                    testId="modal-wrong-security-code"
                  />
                )}

                {!showUserBlockedError && !showMaxAttemptExceeded && <BodyLarge className="text-center">
                  {textContent?.youHaveAttemptsLeftFirst}
                  {' '}
                  <span className="text-grape">{attemptsLeft}</span>
                  {' '}
                  {textContent?.youHaveAttemptsLeftSecond}
                </BodyLarge>}
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={isSuccessModalOpen}
            closeModal={handleSuccessfulCloseModal}
            showControls={false}
            showBackLink={false}
          >
            <div className="flex flex-col items-center justify-center h-96">
              <div className="flex items-center justify-center">
                {/* <img className="place-items-center w-auto mb-4" src={successfulCheckbox?.url} /> */}
                <SuccessfulValidation />
                <div className="flex flex-col items-center justify-center mx-2">
                  <Heading2 className="text-green-success mx-2">
                   {textContent?.verificationSuccessfulFirst}
                    <br />
                   {textContent?.verificationSuccessfulSecond}
                  </Heading2>
                </div>
              </div>
            </div>
          </Modal>

          <div className="w-full sm:w-4/12 mt-2 mb-6 cursor-pointer">
            <Link
              tabIndex="0"
              href="#"
              onClick={() => {
                handleEmailModal('continue')
              }}
              testId="intropage-continue-activation"
            >
              <BodyBold className="text-center underline" textColor="text-primary">
                {getShortTextFromPage(landingPageContent, 'or-continue-yourprevious-activation')}
              </BodyBold>
            </Link>
          </div>
          <div className="w-full sm-4/12 sm:text-center">
            <BodySmall>
              {textContent?.phoneCompatibility}{' '}
              <Link href={textContent?.phoneCompatibilityHelp} className="font-h1">
                {textContent?.findOutMore}
              </Link>
            </BodySmall>
          </div>
        </div>
      </div>
    </>
  )
}

IntroPage.propTypes = {
  path: PropTypes.string.isRequired
}

export default IntroPage
