/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import AccountHelpers from '@apps/flows/activation/services/accountHelper'
import {
  PAYMENT_STATE,
  updatePaymentStates,
  referAFriend as referAFriendInitState
} from '@apps/redux/features/PayAndReviewSlice'
import Button from '@apps/components/Button'
import Input from '@apps/components/Input'
import Sites from '@sites/index'
import { getById, getBySlug, getImgByTitle } from '@apps/utils/contentful'
import PaymentChangeHandlers, {
  PAYMENT_EVENTS
} from '@apps/utils/ChangeHandlers/PaymentChangeHandlers'
import Heading4 from '../Typography/Heading4'

const MultiRafCode = () => {
  const dispatch = useDispatch()
  const {
    [APPLICATION_STATE.SERVICES]: brandServices,
    [APPLICATION_STATE.SITE]: site,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language
  } = useContext(ApplicationContext)

  const {
    userType,
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)
 
  const isDealer = userType === Sites.dealer
  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const pageTextContent = useMemo(() => {
    const paymentPage = getById(pageContent, `paymentPage${pagePrefix}`)[language]
    if (!paymentPage) return {}
    const { shortTextFields } = paymentPage
    return {
      friendRefCode: getBySlug(shortTextFields, 'friendRefCode')?.value,
      friendRefCodeText: getBySlug(shortTextFields, 'friendRefCodeText')?.value,
      add: getBySlug(shortTextFields, 'add')?.value,
      enterFriendCode: getBySlug(shortTextFields, 'enterFriendCode')?.value,
      friendRefCodeInvalid: getBySlug(shortTextFields, 'friendRefCodeInvalid')?.value,
      friendRefCodeUsageExceeded: getBySlug(shortTextFields, 'friendRefCodeUsageExceeded')?.value
    }
  }, [pageContent, selectedLanguage])

  const {
    friendRefCode,
    friendRefCodeText,
    add,
    enterFriendCode,
    friendRefCodeInvalid,
    friendRefCodeUsageExceeded
  } = pageTextContent

  const paymentState = useSelector((state) => state.payAndReviewPage)
  const updatePaymentStore = (payload) => {
    dispatch(updatePaymentStates(payload))
  }

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      close: getImgByTitle(commonElements, 'close')?.url
    }
  }, [pageContent, selectedLanguage])

  const {
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: friendReferralCode,
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_SETTINGS]: friendReferralSettings
  } = paymentState

  const { isValidReferral } = brandServices

  const [isFriendReferralLoading, setIsFriendReferralLoading] = useState(false)
  const [isValidFriendReferralCode, setIsValidFriendReferralCode] = useState(false)
  const [friendReferralCodeError, setFriendReferralCodeError] = useState('')

  const mounted = useRef(false)
  useEffect(() => {
    if (mounted.current && friendReferralCode !== '') {
      validateFriendCode(friendReferralCode)
      addFriendRefrralCode()
    } else {
      mounted.current = true
    }

    if (mounted.current && friendReferralCode === '' && friendReferralCodeError !== '') {
      validateFriendCode(friendReferralCode)
      addFriendRefrralCode()
    } else {
      mounted.current = true
    }
  }, [language, validateFriendCode, addFriendRefrralCode])

  const validateFriendCode = (value) => {
    if (!value) setFriendReferralCodeError(enterFriendCode)
    else setFriendReferralCodeError('')
  }

  const addFriendRefrralCode = async () => {
    validateFriendCode(friendReferralCode)
    if (friendReferralCode) {
      setIsFriendReferralLoading(true)
      const { referral, error } =
        (await AccountHelpers.applyReferralCode(
          isValidReferral,
          friendReferralCode,
          friendReferralSettings
        )) || {}
      if (referral) {
        setIsValidFriendReferralCode(true)
        updatePaymentStore({
          ...paymentState,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: friendReferralCode,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_INFO]: referral,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: true
        })
        setIsFriendReferralLoading(false)
      } else {
        const { code: errorCode } = error || {}
        const errorMessage = errorCode !== '429' ? friendRefCodeInvalid : friendRefCodeUsageExceeded
        setIsValidFriendReferralCode(false)
        setFriendReferralCodeError(errorMessage)
        updatePaymentStore({
          ...paymentState,
          [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
        })
        setIsFriendReferralLoading(false)
      }
    }
  }

  const removeFriendReferralCode = () => {
    setIsValidFriendReferralCode(false)
    updatePaymentStore({
      ...paymentState,
      ...referAFriendInitState,
      [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_IS_VALID]: false
    })
  }

  return (
    <>
      <div className="w-full space-y-4">
        <div className="flex flex-col items-start space-x-0 space-y-4 lg:space-y-0 md:space-y-0 md:space-x-4 lg:space-x-4 md:flex-row lg:flex-row">
          <div className="flex flex-col flex-1 w-full space-y-4">
            <div className="flex flex-col flex-1">
              <div className="flex flex-row w-full">
                <div className="flex flex-col flex-1">
                  <label className="block text-unselected-text" htmlFor="friendReferralCode">
                    <Heading4>{friendRefCode}</Heading4>
                    {site && Sites?.consumer && site === Sites.consumer && (
                      <p className="text-copy text-sm font-body">{friendRefCodeText}</p>
                    )}
                  </label>
                  <div className="flex flex-col md:flex-row w-full items-start flex-1">
                    <div className="flex flex-col flex-1 w-full">
                      <Input
                        type="text"
                        id="friendReferralCode"
                        name="friendReferralCode"
                        value={isValidFriendReferralCode ? '' : friendReferralCode}
                        onChange={(e) => {
                          e.preventDefault()
                          PaymentChangeHandlers[PAYMENT_EVENTS.VALIDATE_CODE].onChange(
                            e.target.value,
                            validateFriendCode,
                            PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE,
                            updatePaymentStore
                          )
                        }}
                        onFocus={() => setFriendReferralCodeError('')}
                        errorMessage={friendReferralCodeError}
                        maxLength="7"
                        disabled={isValidFriendReferralCode}
                        isLoading={isFriendReferralLoading}
                        internalLoader
                        data-testid="payment-referralInput"
                      />
                    </div>
                    <div className="w-full md:w-28 mt-2">
                      <Button
                        className="md:ml-2 h-11 w-full flex justify-center"
                        label={add}
                        testId="payment-rafButton"
                        onClick={addFriendRefrralCode}
                        disabled={isValidFriendReferralCode || isFriendReferralLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isValidFriendReferralCode && (
            <div className="mt-2 flex flex-wrap">
              <>
                <div className="inputTag border pt-2 pb-2 pr-2 pl-4 rounded-full mr-6 flex bg-tag mb-2">
                  <p className="text-primary text-sm font-body leading-6">{friendReferralCode}</p>
                  <div
                    className="flex flex-row content-between transition duration-300 ease-in-out delay-150 transform cursor-pointer hover:scale-125 ml-2"
                    onClick={removeFriendReferralCode}
                  >
                    <img width="24" height="24" alt="close" src={content?.close} />
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default MultiRafCode
