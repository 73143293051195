import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const CustomTab = ({ children, onSelect }) => {

  const styleBase = 'w-1/2 cursor-pointer relative flex items-center justify-center border-primary shadow-disabled empty sm:w-1/2 md:w-auto lg:w-auto'

  // const styleAfter = 'after:transform after:w-7 after:rotate-45 after:h-7 after:absolute after:bg-primary after:z-negative-1 after:-bottom-3 after:shadow-active'

  const styleSelected = `text-primary border-b-2 border-primary z-10`

  const styleUnselected = 'text-black hover:text-primary'

  const [selectedName, setSelectedName] = useState('')

  const handleOnSelect = (name) => {
    setSelectedName(name)
    onSelect(name)
  }

  const updateSelectedName = () => {
    children.map((val) => {
      if (val.props.selected) {
        setSelectedName(val.props.name)
      }
    })
  }

  useEffect(() => {
    updateSelectedName()
  }, [])

  return (
    <div role="tablist" className="flex space-x-4 flex-nowrap my-8 border-b-2 border-grey-light">
      {children.map((val) => (
        <button
        aria-controls={val.props.name}
        aria-selected={selectedName === val.props.name}
        onClick={(e) => handleOnSelect(val.props.name)}
          key={val.props.name}
          className={`${styleBase} ${selectedName === val.props.name ? styleSelected : styleUnselected}`}
        data-testid={val.props["data-testid"]}
        >
          {val}
        </button>
      ))}
    </div>
  )
}

CustomTab.propTypes = {
  onSelect: PropTypes.func
}

export default CustomTab
