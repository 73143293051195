/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { isNullOrEmpty } from '@apps/utils/common'

export const ACCOUNT_SETUP_STATE = {
  BAN: 'ban',
  CONFIRM_DIGITS_PIN: 'confirmDigitsPIN',
  CONFIRM_EMAIL_ERROR: 'confirmEmailError',
  CONFIRM_EMAIL: 'confirmEmail',
  CONFIRM_PASSWORD: 'confirmPassword',
  CONFIRM_PIN: 'confirmPin',
  EMAIL_ERROR: 'emailError',
  EMAIL_IN_USE: 'emailinUse',
  EMAIL: 'email',
  FIRST_NAME_ERROR: 'firstNameError',
  FIRST_NAME: 'firstName',
  FOUR_DIGITS_PIN: 'fourDigitsPIN',
  IS_COMPLETE: 'isComplete',
  LANGUAGE: 'language',
  LAST_NAME_ERROR: 'lastNameError',
  LAST_NAME: 'lastName',
  PASSWORD_ERRORS: 'passwordErrors',
  PASSWORD: 'password',
  PIN_ERRORS: 'pinErrors',
  PIN: 'pin',
  POSTAL_CODE_ERROR: 'postalCodeError',
  POSTAL_CODE: 'postalCode',
  PREFERRED_LANGUAGE: 'preferredLanguage',
  PROVINCE_ERROR: 'provinceError',
  PROVINCE: 'province',
  SECURITY_ANSWER_ERROR: 'securityAnswerError',
  SECURITY_ANSWER: 'securityAnswer',
  SECURITY_QUESTION_ERROR: 'securityQuestionError',
  SECURITY_QUESTION: 'securityQuestion',
  SITE: 'site',
  IS_INVALID_PIN_LENGTH: 'isInvalidPINLength',
  PIN_STARTS_WITH_ZERO: 'pinStartsWithZero',
  PIN_ONE_TWO_THREE_FOUR: 'pinOneTwoThreeFour',
  PIN_SINGLE_REPEATING_DIGIT: 'pinSingleRepeatingDigit',
  SHOW_REQUIREMENTS: 'showRequirements',
  PIN_MATCHES: 'pinMatches',
  CONFIRM_PIN_REQUIRED: 'confirmPinRequired',
  PIN_REQUIRED: 'pinRequired',
  IS_INVALID_PASSWORD_LENGTH: 'isInvalidPasswordLength',
  PASSWORD_NO_UPPER: 'passwordNoUpper',
  PASSWORD_NO_LOWER: 'passwordNoLower',
  PASSWORD_NO_NUMBER: 'passwordNoNumber',
  PASSWORD_NO_SPECIAL_CHAR: 'passwordNoSpecialChar',
  PASSWORD_HAS_SPACE: 'passwordHasSpace',
  PASSWORD_MATCHES: 'passwordMatches',
  CONFIRM_PASSWORD_REQUIRED: 'confirmPasswordRequired',
  PASSWORD_REQUIRED: 'passwordRequired'
}

const requiredFields = [
  [ACCOUNT_SETUP_STATE.FIRST_NAME],
  [ACCOUNT_SETUP_STATE.LAST_NAME],
  [ACCOUNT_SETUP_STATE.PIN],
  [ACCOUNT_SETUP_STATE.CONFIRM_PIN],
  [ACCOUNT_SETUP_STATE.LANGUAGE]
]

const errorFields = [[ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR], [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR]]

export const AccountSetupComplete = (state) => {
  const emptyRequiredField = requiredFields.find((field) => isNullOrEmpty(state[field]))
  const hasError = errorFields.find((error) => !isNullOrEmpty(state[error]))
  const pinErrors = state[ACCOUNT_SETUP_STATE.PIN_ERRORS]
  const hasPinError = Object.values(pinErrors).find((value) => value)

  if (hasPinError || hasError || emptyRequiredField) {
    return false
  }

  return true
}

export const accountSetupSlice = createSlice({
  name: 'accountSetupPage',
  initialState: {
    accountInfo: {
      [ACCOUNT_SETUP_STATE.FIRST_NAME]: '',
      [ACCOUNT_SETUP_STATE.LAST_NAME]: '',
      [ACCOUNT_SETUP_STATE.FOUR_DIGITS_PIN]: '',
      [ACCOUNT_SETUP_STATE.CONFIRM_DIGITS_PIN]: '',
      [ACCOUNT_SETUP_STATE.PREFERRED_LANGUAGE]: 'en'
    },
    [ACCOUNT_SETUP_STATE.FIRST_NAME]: '',
    [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR]: '',
    [ACCOUNT_SETUP_STATE.LAST_NAME]: '',
    [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR]: '',
    [ACCOUNT_SETUP_STATE.EMAIL]: '',
    [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: '',
    [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: null,
    [ACCOUNT_SETUP_STATE.PROVINCE]: '',
    [ACCOUNT_SETUP_STATE.PIN]: '',
    [ACCOUNT_SETUP_STATE.CONFIRM_PIN]: '',
    [ACCOUNT_SETUP_STATE.PIN_ERRORS]: {
      [ACCOUNT_SETUP_STATE.IS_INVALID_PIN_LENGTH]: null,
      [ACCOUNT_SETUP_STATE.PIN_STARTS_WITH_ZERO]: null,
      [ACCOUNT_SETUP_STATE.PIN_ONE_TWO_THREE_FOUR]: null,
      [ACCOUNT_SETUP_STATE.PIN_SINGLE_REPEATING_DIGIT]: null,
      [ACCOUNT_SETUP_STATE.SHOW_REQUIREMENTS]: null,
      [ACCOUNT_SETUP_STATE.PIN_MATCHES]: null,
      [ACCOUNT_SETUP_STATE.CONFIRM_PIN_REQUIRED]: null,
      [ACCOUNT_SETUP_STATE.PIN_REQUIRED]: null
    },
    [ACCOUNT_SETUP_STATE.LANGUAGE]: 0,
    [ACCOUNT_SETUP_STATE.IS_COMPLETE]: false,
    [ACCOUNT_SETUP_STATE.BAN]: ''
  },
  reducers: {
    recordAccountInfo: (state, action) => {
      if (action?.payload?.accountId) {
        state.accountInfo = {
          ...action.payload
        }
      }
    },
    updateAccountSetupState: (state, action) => {
      const newState = Object.assign(state, action.payload)
      const isComplete = AccountSetupComplete(newState)
      newState[ACCOUNT_SETUP_STATE.IS_COMPLETE] = isComplete
      state = { ...newState }
    },
    updateAccountSetupPinErrorState: (state, action) => {
      const newErrorState = Object.assign(state[ACCOUNT_SETUP_STATE.PIN_ERRORS], action.payload)
      const newState = Object.assign(state, newErrorState)
      const isComplete = AccountSetupComplete(newState)
      newState[ACCOUNT_SETUP_STATE.IS_COMPLETE] = isComplete
      state = { ...newState }
    }
  }
})

// this is for dispatch
export const {
  recordAccountInfo,
  updateAccountSetupState,
  updateAccountSetupPinErrorState
} = accountSetupSlice.actions

// this is for configureStore
export default accountSetupSlice.reducer
