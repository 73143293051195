/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from '@apps/components/RadioButton'
import Heading4 from '@apps/components/Typography/Heading4'
import Sites from '@sites/index'
import classNames from 'classnames'

const SelectSimRadioGroup = ({
  userType = Sites.consumer,
  options,
  selectedOption,
  onChange,
  heading
}) => {
  const updateSelectedIndex = (index) => {
    if (onChange) {
      onChange(options[index])
    }
  }

  const radioButtons = options.map((option, i) => {
    return (
      <RadioButton
        key={option.value}
        index={i}
        option={option.name}
        selected={option.value === selectedOption?.value}
        name={option.name}
        setSelectedIndex={(index) => updateSelectedIndex(index)}
        testId={`have-sim-selection-${options.value}`}
      />
    )
  })

  return (
    <>
      <div>
        <Heading4
          className={classNames(
            'text-medium font-bold leading-7 font-h3',
            userType === Sites.dealer ? 'mt-6' : 'mt-8'
          )}
        >
          {heading}
        </Heading4>
      </div>
      <div className="flex flex-col md:flex-row justify-between mt-2 mb-5">{radioButtons}</div>
    </>
  )
}

const optionProps = PropTypes.shape({
  value: PropTypes.string,
  name: PropTypes.string
})

SelectSimRadioGroup.propTypes = {
  userType: PropTypes.string,
  onChange: PropTypes.func,
  heading: PropTypes.string,
  options: PropTypes.arrayOf(optionProps),
  selectedOption: optionProps
}

export default SelectSimRadioGroup
