import { proxy } from './backend'

export default async (service, operation, params) => {
  const result = await proxy(service, operation, params)

  const {
    data: { response, redirectUrl }
  } = result

  return response
}
