export const navigateToPath = (currentState, activationPaths, history, lang = 'en', prov = 'on') => {
  return new Promise((resolve) => {
    const url = history?.location?.pathname
    if (url) {
      if (!isValidUrlEnding(url, lang, prov)) {
        history.push(url)
      } else if (currentState === 'Sim Selection') {
        history.push(activationPaths.SimSelection)
      } else if (currentState === 'Account Creation') {
        history.push(activationPaths.AccountSetup)
      } else if (currentState === 'Phone Number Selection') {
        history.push(activationPaths.ChooseNumber)
      } else if (currentState === 'Plan Selection') {
        history.push(activationPaths.Plans)
      } else if (currentState === 'Payment Information') {
        history.push(activationPaths.Payment)
      } else if (currentState === 'Download App') {
        history.push(activationPaths.AlreadyActivated)
      } else {
        history.push(activationPaths.Splash)
      }
    }
    setTimeout(resolve, 0)
  })
}
  
  const isValidUrlEnding = (url, lang, prov) => {
    const validUrlEndings = [
      '/activation',
      '/activation/plans',
      '/activation/sim-selection',
      '/activation/account-setup',
      '/activation/payment',
      `/${lang}/${prov}`
    ];
    const validUrlEndingWithSlash = validUrlEndings.map(urlEnding => urlEnding.concat('/'));
    validUrlEndings.concat(validUrlEndingWithSlash).some(urlEnding => url.endsWith(urlEnding));
    return validUrlEndings;
  }