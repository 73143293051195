import React, { useContext, useState, useEffect, useMemo } from 'react'
import useBrand from '@engine/hooks/useBrand'
import Brands from '@brands'
import { useHistory } from 'react-router-dom'
import {
  PHONE_NUMBER_STATE,
  transferANumberWirelineState,
  transferANumberWirelessState,
  updatePhoneNumberState
} from '@apps/redux/features/PhoneNumberSlice'
import { PLANS_STATE } from '@apps/redux/features/PlansSlice'
import { useDispatch, useSelector } from 'react-redux'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import PlanUnavailablePopup from '@apps/components/PlanUnavailablePopup'
import PhoneNumber from '@apps/components/PhoneNumber'
import Sites from '@sites/index'
import { getById, getBySlug, getImgByTitle } from '@apps/utils/contentful'
import Heading2 from '@apps/components/Typography/Heading2'
import Checkbox from '../Checkbox'
import TransferLandline from './TransferLandline'
import TransferMobile from './TransferMobile'
import Heading4 from '../Typography/Heading4'
import BodyError from '../Typography/BodyError'
import Body from '../Typography/Body'
import BodySmall from '../Typography/BodySmall'

const TransferNumber = ({ transferRefs, transferHandlers, chooseNewPhoneNumber }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const consumerActivationPaths = new ConsumerActivationPaths()

  const { [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan } = useSelector(
    (state) => state.plansPage
  )

  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const {
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_NUMBER_ELIGIBLE]: isPortInNumberEligible,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]: isPortInAgreementChecked,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_WIRELESS]: isPortInWireless,
    [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_HAS_AGREEMENT_ERROR]: hasAgreementError
  } = phoneNumberState

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.CURRENT_REGION]: currentRegion
  } = useContext(ApplicationContext)

  const [hasCheckedOnce, setHasCheckedOnce] = useState(false)

  const handleOnAgreementChange = () => {
    dispatch(
      updatePhoneNumberState({
        ...phoneNumberState,
        ...transferANumberWirelessState,
        ...transferANumberWirelineState,
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.ALTERNATE_CONTACT_NUMBER]: '',
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_AGREEMENT_CHECKED]:
          !isPortInAgreementChecked,
        [PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.IS_PORT_IN_HAS_AGREEMENT_ERROR]:
          isPortInAgreementChecked && hasCheckedOnce
      })
    )
  }

  useEffect(() => {
    if (isPortInAgreementChecked && !hasCheckedOnce) {
      setHasCheckedOnce(true)
    }
  }, [isPortInAgreementChecked])

  const {
    content: { pageContent },
    user: { selectedLanguage },
    userType
  } = useSelector((state) => state.common)

  const isDealer = userType === Sites.dealer
  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const textContent = useMemo(() => {
    if (!pageContent) return {}
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    const phoneNumberPage = getById(pageContent, `phoneNumberPage${pagePrefix}`)[selectedLanguage]
    const { shortTextFields } = phoneNumberPage || {}

    return {
      transferNumberInfo: getBySlug(shortTextFields, 'transferNumberInfo')?.value,
      transferOptInPublic: getBySlug(shortTextFields, 'transferOptInPublic')?.value,
      transferOptInKoodo: getBySlug(shortTextFields, 'transferOptInKoodo')?.value,
      transferOptInError: getBySlug(shortTextFields, 'transferOptInError')?.value,
      transferMobile: getBySlug(shortTextFields, 'transferMobile')?.value,
      transferLandline: getBySlug(shortTextFields, 'transferLandline')?.value,
      transferLandlineSubtitle: getBySlug(shortTextFields, 'transferLandlineSubtitle')?.value,
      transferLandlineFootnote: getBySlug(shortTextFields, 'transferLandlineFootnote')?.value,
      error: getImgByTitle(commonElements, 'error')?.url
    }
  }, [pageContent, selectedLanguage])

  const {
    transferNumberInfo,
    transferOptInPublic,
    transferOptInKoodo,
    transferOptInError,
    transferMobile,
    transferLandline,
    transferLandlineSubtitle,
    transferLandlineFootnote
  } = textContent

  const brand = useBrand()

  const {
    phoneRef,
    alternateRef,
    accountRef,
    imeiRef,
    prevProvRef,
    alternateLandlineRef,
    firstNameRef,
    streetNameRef,
    provinceLandlineRef,
    cityLandlineRef,
    postalRef,
    tvInternetRef,
    streetNumRef
  } = transferRefs

  const mobileRefs = {
    alternateRef,
    accountRef,
    imeiRef,
    prevProvRef
  }

  const landlineRefs = {
    alternateLandlineRef,
    firstNameRef,
    streetNameRef,
    provinceLandlineRef,
    cityLandlineRef,
    postalRef,
    tvInternetRef,
    streetNumRef
  }

  const {
    handleBlurPhone,
    handlePrevProvider,
    handleIMEI,
    handleAccount,
    handleAltPhone,
    handleFirstName,
    handleLastName,
    handleAltPhoneLandline,
    handleStreetNum,
    handleStreetName,
    handleProvince,
    handleCity,
    handlePostal,
    handleTVInternet
  } = transferHandlers

  const mobileHandlers = {
    handlePrevProvider,
    handleIMEI,
    handleAccount,
    handleAltPhone
  }

  const landlineHandlers = {
    handleFirstName,
    handleLastName,
    handleAltPhoneLandline,
    handleStreetNum,
    handleStreetName,
    handleProvince,
    handleCity,
    handlePostal,
    handleTVInternet
  }
  useEffect(() => {
    if (phoneNumberState.portInPhoneNumberErroMessage) {
      handleBlurPhone()
    }
  }, [language])

  const handleSelectNewPhoneNumber = () => {
    chooseNewPhoneNumber()
  }

  const handleSelectNewPlan = () => {
    history.push(consumerActivationPaths.ChangeNewPlan, {
      from: consumerActivationPaths.ChooseNumber
    })
  }

  return (
    <>
      <div className="w-full mt-12 space-y-4 text-black lg:w-8/12">
        <Heading4>{transferNumberInfo}</Heading4>
        <div ref={phoneRef}>
          <PhoneNumber handleBlurPhone={handleBlurPhone} />
        </div>
        {isPortInNumberEligible && (
          <>
            <div>
              <div
                className={`flex flex-row text-sm text-black ${
                  hasAgreementError ? 'mt-2' : 'my-2'
                }`}
              >
                <div className="mr-2">
                  <Checkbox
                    testId="phoneNumber-agreement"
                    checked={isPortInAgreementChecked}
                    onChange={handleOnAgreementChange}
                  />
                </div>
                <Body onClick={handleOnAgreementChange} className="cursor-default">
                  {brand === Brands.public ? transferOptInPublic : transferOptInKoodo}
                </Body>
              </div>
              {hasAgreementError && (
                <div className="flex items-center">
                  <img className="h-4 my-2" src={textContent.error} alt={transferOptInError}/>
                  <BodyError className="mt-1 ml-1 leading-4 ">{transferOptInError}</BodyError>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {isPortInNumberEligible && isPortInAgreementChecked && (
        <div className="space-y-8">
          <div className="mt-10">
            <Heading2>{isPortInWireless ? transferMobile : transferLandline}</Heading2>
            {!isPortInWireless && <Heading4>{transferLandlineSubtitle}</Heading4>}
            {transferLandlineFootnote !== '' && <BodySmall>{transferLandlineFootnote}</BodySmall>}
          </div>
          {isPortInWireless ? (
            <TransferMobile mobileRefs={mobileRefs} mobileHandlers={mobileHandlers} />
          ) : (
            <TransferLandline landlineRefs={landlineRefs} landlineHandlers={landlineHandlers} />
          )}
        </div>
      )}
      {preSelectedPlan && (
        <PlanUnavailablePopup
          province={currentRegion}
          onNewPhoneNumberClick={handleSelectNewPhoneNumber}
          onNewPlanClick={handleSelectNewPlan}
        />
      )}
    </>
  )
}

export default TransferNumber
