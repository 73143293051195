export const AppMountPoints = {
  activationMount: 'activationMount'
}

// hand curated list.. there could be a better way
const AppMounts = {
  [AppMountPoints.activationMount]: undefined
}

export default AppMounts
