import { PAYMENT_STATE } from '../../../apps/redux/features/PayAndReviewSlice'

export const PAYMENT_EVENTS = {
  VALIDATE_CODE: 'event.target.payment.validateCode',
  POSTAL_CODE: 'event.target.payment.postalCode'
}


export default {
  [PAYMENT_EVENTS.VALIDATE_CODE]: {
    onChange: (value, validateCode, updateKey,UpdatePaymentState) => {
      if (/^[a-zA-Z0-9]*$/.test(value)) {
        validateCode(value)
        UpdatePaymentState({[updateKey]: value})
      }
    }
  },
  [PAYMENT_EVENTS.POSTAL_CODE]: {
    onChange: (value,UpdatePaymentState) => {
      const firstLetterMatch = new RegExp(/([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])/)
      const letterMatch = new RegExp(/([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])/)
      const digitMatch = new RegExp(/\d/)

      const stringArray = value.split('')

      if (stringArray.length > 3 && stringArray.indexOf(' ') === -1) {
        stringArray.splice(3, 0, ' ')
      }
      const letters = [0, 2, 5]
      const numbers = [1, 4, 6]

      let matches = true

      stringArray.forEach((char, i) => {
        if (letters.indexOf(i) !== -1) {
          if (i === 0 && !char.match(firstLetterMatch)) {
            matches = false
          } else if (!char.match(letterMatch)) {
            matches = false
          }
        } else if (numbers.indexOf(i) !== -1) {
          if (!char.match(digitMatch)) {
            matches = false
          }
        }
      })
      if (matches) {
        const result = stringArray.join('')
        UpdatePaymentState({[PAYMENT_STATE.CC_INFO.POSTAL_CODE]: result})
      }
    },
    onBlur: (state, errors, UpdatePaymentState) => {
      const value = state[PAYMENT_STATE.CC_INFO.POSTAL_CODE]
      if (value === '') {
        UpdatePaymentState({[PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]: errors.postalCodeRequired})
        return true
      }
      const postalMatch = new RegExp(
        /([abceghjklmnprstvxyABCEGHJKLMNPRSTVXY])\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])(.?)\d([abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ])\d/g
      )
      if (!value?.match(postalMatch)) {
        UpdatePaymentState({
          [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]:
            errors.postalCodeInvalid
        })
        return true
      }
      UpdatePaymentState({
        [PAYMENT_STATE.CC_INFO.POSTAL_CODE_ERROR]: null
      })
      return false
    }
  }
}
