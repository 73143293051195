import React from 'react'

const HideIcon = ({ title }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className="stroke-current text-primary fill-current text-primary"
    >
      <title id="hideIcon">{title}</title>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              d="M15.807 6C20.35 6 25.747 9.333 32 16c-6.253 6.667-11.65 10-16.193 10C11.265 26 5.996 22.667 0 16 5.996 9.333 11.265 6 15.807 6zM16 9c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 3.2c2.099 0 3.8 1.701 3.8 3.8 0 2.099-1.701 3.8-3.8 3.8-2.099 0-3.8-1.701-3.8-3.8 0-2.099 1.701-3.8 3.8-3.8z"
              transform="translate(-657 -1351) translate(657 1351)"
            />
            <path
              strokeLinecap="square"
              strokeWidth="3"
              d="M3.5 25L28.5 7"
              transform="translate(-657 -1351) translate(657 1351)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HideIcon
