import React from 'react'
import PropTypes from 'prop-types'
import BodyBold from '../Typography/BodyBold'

const RadioButton = ({ option, selected, setSelectedIndex, index, customStyles, testId }) => {
  // Added Custom Styles , as Radio options could be more than TWO and we might have to use class other than w-1/2
  let parentStyles = index % 2 === 0 ? 'w-full md:w-1/2 p-0 md:pr-3 ' : 'w-full md:w-1/2 p-0 md:pl-3'

  if (customStyles) {
    parentStyles = customStyles
  }

  return (
    <div className={parentStyles}>
      <label
        onClick={(e) => {
          e.preventDefault();
          setSelectedIndex(index)
        }}
        className={`cursor-pointer leading-none ${
          selected
            ? 'bg-selected-background text-copy-inverted'
            : 'bg-unselected-background text-copy'
        } relative flex w-full items-center p-4 mt-4 md:mt-3 mb-0 md:mb-3 rounded focus-within:bg-hover-background hover:${
          !selected && 'bg-hover-background'
        }`}
      >
        <input
          type="radio"
          name={option}
          readOnly
          checked={selected}
          className="mr-6 border-2 border-solid ring-0 border-copy"
          style={{ width: '18px', height: '18px' }}
          data-testid={testId}
        />
        <span
          data-content=""
          className={`${
            selected ? 'inline' : 'hidden'
          } bg-white after:content absolute rounded-full w-2.5 h-2.5`}
          style={{ left: '20px' }}
        />
        <BodyBold className={selected ? 'text-copy-inverted' : 'text-copy'}>{option}</BodyBold>
      </label>
    </div>
  )
}
RadioButton.propTypes = {
  option: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
}

export default RadioButton
