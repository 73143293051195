/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { toFixed } from '@apps/utils/common'
import cookie from 'react-cookies'
import { PAYMENT_OPTIONS } from '@apps/utils/constants'

const languages = { EN: 'en', FR: 'fr' }
const language = cookie.load('lang') || languages.EN

export const common = createSlice({
  name: 'common',
  initialState: {
    plan: {
      selectedPlan: {},
      pricePlanId: '',
      pricePlanGuid: '',
      selectedPaymentType: 'monthly',
      summaryCardLoader: false,
      preSelectedPlan: false,
      promo: null
    },
    quote: {
      quoteInfo: {},
      quoteByEmailInfo: {}
    },
    timeLine: {
      addressEnable: true
    },
    user: {
      email: '',
      name: '',
      firstName: '',
      lastName: '',
      selectedLanguage: language,
      userProvince: '',
      accountPIN: '',
      preferredLanguage: '',
      accountBan: '',
      isLoggedIn: false,
      referrerUrl: '',
      isReferrerUrlLocked: false,
      accountInfo: {},
      accountByEmailInfo: {}
    },
    content: {
      pages: undefined, // for NGA pages ==> pageContent. It will be clean up
      headerAndFooter: undefined,
      pageContent: undefined
    },
    cart: {
      // this is for cart store
      // TODO: Empty Initial state post API Integration
      amountInformation: {
        planAmount: '',
        simAmount: '',
        promoDiscount: '',
        simDeliveryAmount: '',
        amountDue: (0.0).toFixed(2),
        amountOwing: (0.0).toFixed(2),
        amountFunds: (0.0).toFixed(2),
        taxAmount: (0.0).toFixed(2),
        amountTotalVoucher: (0.0).toFixed(2),
        totalPaid: '',
        addedVouchers: [],
        selectedPaymentOption: PAYMENT_OPTIONS.CREDIT_CARD,
        promotionCodeInfo: ''
      }
    },
    userType: '',
    activeStepNumber: 0,
    provinces: [],
    currentProvinceId: '',
    showSelectLanguage: false,
    isSimValidInfo: true,
    responseError: '',
    postPaymentError: '',
    activationQRcode: ''
  },
  reducers: {
    updateActivateAllResponse: (state, action) => {
      if (action.payload?.responseError !== undefined) {
        state.responseError = action.payload?.responseError
      }

      if (action.payload?.postPaymentError !== undefined) {
        state.postPaymentError = action.payload?.postPaymentError
      }
    },

    setSelectedPlan: (state, action) => {
      state.plan.selectedPlan = action.payload
      state.plan.pricePlanId = action.payload?.id
      state.plan.pricePlanGuid = action.payload?.guid
      state.plan.selectedPaymentType = action.payload?.selectedPaymentType
      state.plan.summaryCardLoader = action.payload?.summaryCardLoader
      state.plan.preSelectedPlan = action.payload?.preSelectedPlan
      state.plan.promo = action.payload?.promo
    },
    setQuoteInfo: (state, action) => {
      state.quote.quoteInfo = action.payload
    },
    recordQuoteByEmailInfo: (state, action) => {
      state.quote.quoteByEmailInfo = action.payload
    },
    setAddressEnable: (state, action) => {
      state.timeLine.addressEnable = action.payload
    },
    setCartInfo: (state, action) => {
      state.cart.amountInformation = {
        planAmount: `${toFixed(action.payload.planAmount, 2)}`,
        simAmount: `${toFixed(action.payload.simAmount, 2)}`,
        promoDiscount: `${toFixed(action.payload.promoDiscount, 2)}`,
        simDeliveryAmount: `${toFixed(action.payload.simDeliveryAmount, 2)}`,
        cartTotal: `${toFixed(action.payload.cartTotal, 2)}`,
        tax: action.payload.tax,
        totalPaid: `${toFixed(action.payload.totalPaid, 2, true)}`
      }
    },
    logIn: (state) => {
      state.user.isLoggedIn = true
    },
    readUserFromToken: (state, action) => {
      if (action.payload.email) {
        const newUserState = {
          isLoggedIn: true,
          email: action.payload.email,
          name: action.payload.name,
          firstName: action.payload.given_name,
          lastName: action.payload.family_name
        }
        state.user = { ...state.user, ...newUserState }
      }
    },
    recordUserInfo: (state, action) => {
      const newUserState = {}
      
      if (action?.payload?.email) {
        Object.assign(newUserState, {
          isLoggedIn: action?.payload?.isLoggedIn,
          email: action?.payload?.email,
          name: action?.payload?.name,
          firstName: action?.payload?.firstName,
          lastName: action?.payload?.lastName,
          selectedLanguage: action?.payload?.language ? action?.payload?.language : languages.EN,
          accountPIN: action?.payload?.accountPIN,
          preferredLanguage: action?.payload?.preferredLanguage,
        })
      }
    
      if (action?.payload?.referrerUrl && !state.user.isReferrerUrlLocked) {
        newUserState.referrerUrl = action.payload.referrerUrl,
        newUserState.isReferrerUrlLocked = true
      }
    
      state.user = { ...state.user, ...newUserState }
    },

    resetReferrerUnlock: (state) => {
      state.user.isReferrerUrlLocked = false
    },
    logOut: (state) => {
      const newState = {
        quote: {
          quoteInfo: {}
        },
        user: {
          email: '',
          name: '',
          firstName: '',
          lastName: '',
          userType: '',
          accountPIN: '',
          preferredLanguage: '',
          accountBan: '',
          isLoggedIn: false
        }
      }
      state = { ...state, ...newState }
    },
    changeSelectedLanguage: (state, action) => {
      state.user.selectedLanguage = action.payload
    },
    changeSelectedProvince: (state, action) => {
      state.user.userProvince = action.payload
    },
    recordAccountInfo: (state, action) => {
      // if (action?.payload?.profile?.ban || action?.payload?.accountId) {
      if (action?.payload?.profile?.ban) {
        state.user.accountInfo = {
          ...action.payload
        }
      }
    },
    recordAccountByEmailInfo: (state, action) => {
      state.user.accountByEmailInfo = action.payload
    },
    recordAccountId: (state, action) => {
      if (action?.payload?.accountId) {
        state.user.accountInfo.accountId = action.payload.accountId
      }
    },
    recordQuoteId: (state, action) => {
      if (action?.payload?.quoteId) {
        if (!state.user.accountInfo) {
          state.user.accountInfo = {}
        }

        state.user.accountInfo.quoteId = action.payload.quoteId
      }
    },
    updatePages: (state, action) => {
      state.content = {
        ...state.content,
        ...action.payload
      }
    },
    updateHeaderFooter: (state, action) => {
      state.content.headerAndFooter = action.payload
    },
    setPricePlanId: (state, action) => {
      state.plan.pricePlanId = action.payload?.pricePlanId
      state.plan.pricePlanGuid = action.payload?.pricePlanGuid
    },
    updateProvinces: (state, action) => {
      state.provinces = action.payload
    },
    setProvinceId: (state, action) => {
      state.currentProvinceId = action.payload
    },
    setShowLanguageSelector: (state, action) => {
      state.showSelectLanguage = action.payload
    },
    setIsSimValidFlag: (state, action) => {
      state.isSimValidInfo = action.payload
    },
    setUserType: (state, action) => {
      state.userType = action.payload
    },
    setActiveStepNumber: (state, action) => {
      state.activeStepNumber = action.payload
    },
    setActivationQrCode: (state, action) => {
      state.activationQRcode = action.payload
    },
  }
})

// this is for dispatch
export const {
  setSelectedPlan,
  setQuoteInfo,
  setAddressEnable,
  logIn,
  logOut,
  changeSelectedLanguage,
  changeSelectedProvince,
  readUserFromToken,
  recordUserInfo,
  recordAccountInfo,
  recordAccountId,
  recordQuoteId,
  resetReferrerUnlock,
  updatePages,
  updateHeaderFooter,
  setCartInfo,
  setPricePlanId,
  updateProvinces,
  setProvinceId,
  setShowLanguageSelector,
  setIsSimValidFlag,
  setUserType,
  setActiveStepNumber,
  setActivationQrCode,
  updateActivateAllResponse,
  recordAccountByEmailInfo,
  recordQuoteByEmailInfo
  
} = common.actions

// this is for configureStore
export default common.reducer
