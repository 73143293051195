let env

if (Object.keys(process.env).length === 0 || process.env.BROWSER) {
  env = window.__SERVER_DATA_.ENV_APP || 'pasta'
} else {
  // This will only happen on the server side
  env = process.env.ENV_APP || 'pasta'
}

const envConfig = require(`./${env}`).default // eslint-disable-line import/no-dynamic-require

export default envConfig
