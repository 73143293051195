/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Box = ({ children, border = false, padding, className, testId = '' }) => {
  let defaultStyling = ''

  if (border) {
    defaultStyling = 'border border-grey-dark'
  }

  if (padding) {
    defaultStyling += ` p-${padding}`
  }

  return (
    <div className={classNames(defaultStyling, className)} data-testid={testId}>
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  border: PropTypes.bool,
  padding: PropTypes.number,
  testId: PropTypes.string
}


export default Box
