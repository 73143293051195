import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Notification = ({
  delay, show, setVisibility, children
}) => {

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setVisibility(false)
      }, delay)
      return () => clearTimeout(timer)
    }
    return undefined 
  }, [show])

  if (!show) return null

  return (
    <div className="fixed z-50 translate-x-1/2 w-100 bottom-10 mb-22 left-1/2">
      <div className="flex flex-row items-center p-4 bg-white rounded-sm shadow w-100">
        { children }
      </div>
    </div>
  )
}

Notification.propTypes = {
  delay: PropTypes.number,
  show: PropTypes.bool,
  setVisibility: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

Notification.defaultProps = {
  show: false,
  delay: 4000
}

export default Notification
