import React from "react"
import PropTypes from 'prop-types'

const Heading1 = ({id, children, className}) => {
  return (
    <h1 className={className} id={id}>
      {children}
    </h1>
  )
}
Heading1.prototype = {
  id: PropTypes.string,
  children: PropTypes.string,
  styles: PropTypes.string
}

export default Heading1
