import ActivationPaths from '@apps/flows/activation/ActivationPaths'

class AppPaths {

  static get Activation() {
    return new ActivationPaths('/activation')
  }
}

export default AppPaths
