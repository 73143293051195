import { ACCOUNT_SETUP_STATE } from '@apps/redux/features/AccountSetupSlice'
import {
  isEmptyEmail,
  isValidEmail,
  isMismatchEmail
} from '@apps/utils/formHelpers'
import { isNullOrEmpty } from '@apps/utils/common'

/*
 * Event Functions
 */
const firstNameRegex = new RegExp(/^[A-Za-z\u00C0-\u00FF().'\-\s]{1,20}$/)
const lastNameRegex = new RegExp(/^[A-Za-z\u00C0-\u00FF().'\-\s]{1,13}$/)
const regexNumber = /^\d*\.?\d*$/

export const ACCOUNT_SETUP_EVENTS = {
  POSTAL_CODE: 'event.target.accountSetup.postalCode',
  FIRST_NAME: 'event.target.accountSetup.firstName',
  LAST_NAME: 'event.target.accountSetup.lastName',
  PROVINCE: 'event.target.accountSetup.province',
  EMAIL: 'event.target.accountSetup.email',
  CONFIRM_EMAIL: 'event.target.accountSetup.confirmEmail',
  PASSWORD: 'event.target.accountSetup.password',
  CONFIRM_PASSWORD: 'event.target.accountSetup.confirmPassword',
  SECURITY_QUESTION: 'event.target.accountSetup.securityQuestion',
  SECURITY_ANSWER: 'event.target.accountSetup.securityAnswer',
  PIN: 'event.target.accountSetup.pin',
  CONFIRM_PIN: 'event.target.accountSetup.confirmPin',
  LANGUAGE: 'event.target.accountSetup.language'
}

export default {
  [ACCOUNT_SETUP_EVENTS.FIRST_NAME]: {
    onChange: (value, errors, updateAccountSetupState) => {
      if (firstNameRegex.test(value) || value === '') {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.FIRST_NAME]: value.length > 0 ? value : ''
        })
      } else {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR]: errors.firstNameInvalid
        })
      }
    },
    onBlur: (state, errors, updateAccountSetupStates) => {
      const value = state[ACCOUNT_SETUP_STATE.FIRST_NAME].trim()

      let error = ''
      if (value === '') {
        error = errors.firstNameRequired
      } else if (!firstNameRegex.test(value)) {
        error = errors.firstNameInvalid
      }
      updateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.FIRST_NAME]: value,
        [ACCOUNT_SETUP_STATE.FIRST_NAME_ERROR]: error
      })
      return error !== ''
    }
  },
  [ACCOUNT_SETUP_EVENTS.LAST_NAME]: {
    onChange: (value, errors, updateAccountSetupState) => {
      if (lastNameRegex.test(value) || value === '') {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.LAST_NAME]: value.length > 0 ? value : ''
        })
      } else {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR]: errors.lastNameInvalid
        })
      }
    },
    onBlur: (state, errors, updateAccountSetupStates) => {
      const value = state[ACCOUNT_SETUP_STATE.LAST_NAME].trim()

      let error = ''
      if (value === '') {
        error = errors.lastNameRequired
      } else if (!lastNameRegex.test(value)) {
        error = errors.lastNameInvalid
      }
      updateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.LAST_NAME]: value,
        [ACCOUNT_SETUP_STATE.LAST_NAME_ERROR]: error
      })
      return error !== ''
    }
  },
  [ACCOUNT_SETUP_EVENTS.PROVINCE]: {
    onChange: (value, provinces, updateAccountSetupState) => {
      if (value !== '') {
        const foundProvince = provinces.find((province) => province.name === value)
        if (foundProvince) {
          updateAccountSetupState({
            [ACCOUNT_SETUP_STATE.PROVINCE]: foundProvince.value
          })
        } else {
          updateAccountSetupState({
            [ACCOUNT_SETUP_STATE.PROVINCE]: null
          })
        }
      } else {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.PROVINCE]: null
        })
      }
    },
    onBlur: (state, value, errors, updateAccountSetupState) => {
      if (value !== '' && isNullOrEmpty(state[ACCOUNT_SETUP_STATE.PROVINCE])) {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.PROVINCE_ERROR]: errors.provinceInvalid
        })
        return true
      }

      if (isNullOrEmpty(state[ACCOUNT_SETUP_STATE.PROVINCE])) {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.PROVINCE_ERROR]: errors.provinceRequired
        })
        return true
      }

      updateAccountSetupState({
        [ACCOUNT_SETUP_STATE.PROVINCE_ERROR]: ''
      })
      return false
    }
  },
  [ACCOUNT_SETUP_EVENTS.EMAIL]: {
    onChange: (state, value, updateAccountSetupState) => {
      if (state[ACCOUNT_SETUP_STATE.EMAIL].length <= 40) {
        updateAccountSetupState({
          [ACCOUNT_SETUP_STATE.EMAIL]: value
        })
      }
    },
    onBlur: async (state, errors, setLoading, isUsernameActive, updateAccountSetupStates) => {
      const value = state[ACCOUNT_SETUP_STATE.EMAIL]
      const isEmpty = isEmptyEmail(value)
      const isValid = isValidEmail(value)
      const isMismatch = isMismatchEmail(state[ACCOUNT_SETUP_STATE.CONFIRM_EMAIL], value)
      const isEmptyConfirm = isEmptyEmail(state[ACCOUNT_SETUP_STATE.CONFIRM_EMAIL])

      const requiredError = isEmpty ? errors.emailRequired : null
      const invalidError = !isValid ? errors.emailInvalid : null
      const mismatchConfirmError = isMismatch && errors.emailMismatch
      let emailInUseError = ''
      const confirmRequiredError = isEmptyConfirm ? errors.emailRequired : null

      if (!isEmptyConfirm && isValid && !isMismatch && !isEmpty) {
        setLoading(true)
        const result = await isUsernameActive(value).catch(() => {
          return undefined
        })
        setLoading(false)
        if (result) {
          emailInUseError = errors.emailInUse
        }
      }

      if (requiredError) {
        updateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: requiredError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError
        })
        return true
      }
      if (invalidError) {
        updateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: invalidError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError
        })
        return true
      }
      if (mismatchConfirmError) {
        updateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: mismatchConfirmError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError
        })
        return true
      }
      if (emailInUseError !== '') {
        updateAccountSetupStates({
          [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: emailInUseError,
          [ACCOUNT_SETUP_STATE.CONFIRM_EMAIL_ERROR]: confirmRequiredError,
          [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: true
        })
        return true
      }
      updateAccountSetupStates({
        [ACCOUNT_SETUP_STATE.EMAIL_ERROR]: null,
        [ACCOUNT_SETUP_STATE.EMAIL_IN_USE]: false
      })
      return false
    }
  },
  [ACCOUNT_SETUP_EVENTS.PIN]: {
    onChange: (value, updateAccountSetupState) => {
      if (!regexNumber.test(value)) return
      updateAccountSetupState({
        [ACCOUNT_SETUP_STATE.PIN]: value
      })
    },
    onBlur: (state, updateAccountSetupState) => {
      const value = state[ACCOUNT_SETUP_STATE.PIN]
      const confirmPin = state[ACCOUNT_SETUP_STATE.CONFIRM_PIN]
      const pinErrors = state[ACCOUNT_SETUP_STATE.PIN_ERRORS]
      const regexForSameDigits = /^([0-9])\1*$/
      const pinLength = 4

      const invalidPINLength = value.length !== pinLength
      const startWithZero = value.startsWith('0')
      const onetwothreefour = value === '1234'
      const repeatingDigits = regexForSameDigits.test(value)
      const matches = confirmPin === value

      const newErrors = {
        isInvalidPINLength: null,
        pinStartsWithZero: null,
        pinOneTwoThreeFour: null,
        pinSingleRepeatingDigit: null,
        showRequirements: null,
        pinMatches: null,
        confirmPinRequired: null,
        pinRequired: null
      }

      if (value.length > 0) {
        // validate length
        newErrors.isInvalidPINLength = invalidPINLength

        // start with 0
        newErrors.pinStartsWithZero = startWithZero

        // start 1234
        newErrors.pinOneTwoThreeFour = onetwothreefour

        // 1111, 2222, 3333…
        newErrors.pinSingleRepeatingDigit = repeatingDigits

        newErrors.pinRequired = false

        // check for mismatch
        if (confirmPin.length > 0) {
          newErrors.pinMatches = !matches
        }

        // hide requirements
        if (!invalidPINLength && !startWithZero && !onetwothreefour && !repeatingDigits) {
          newErrors.showRequirements = false
        } else {
          newErrors.showRequirements = true
        }
      } else {
        newErrors.pinRequired = true
        newErrors.isInvalidPINLength = true
      }

      updateAccountSetupState({
        ...pinErrors,
        ...newErrors
      })

      if (
        newErrors.isInvalidPINLength ||
        newErrors.pinStartsWithZero ||
        newErrors.pinOneTwoThreeFour ||
        newErrors.pinSingleRepeatingDigit ||
        newErrors.pinMatches ||
        newErrors.confirmPinRequired ||
        newErrors.pinRequired
      ) {
        return true
      }
      return false
    },
    onFocus: (state, updateAccountSetupState) => {
      const pinErrors = state[ACCOUNT_SETUP_STATE.PIN_ERRORS]
      updateAccountSetupState({
        [ACCOUNT_SETUP_STATE.PIN_ERRORS]: {
          ...pinErrors,
          ...{
            [ACCOUNT_SETUP_STATE.SHOW_REQUIREMENTS]: true
          }
        }
      })
    }
  },
  [ACCOUNT_SETUP_EVENTS.CONFIRM_PIN]: {
    onChange: (value, updateAccountSetupState) => {
      if (!regexNumber.test(value)) return
      updateAccountSetupState({
        [ACCOUNT_SETUP_STATE.CONFIRM_PIN]: value
      })
    },
    onBlur: (state, updateAccountSetupState) => {
      const pin = state[ACCOUNT_SETUP_STATE.PIN]
      const value = state[ACCOUNT_SETUP_STATE.CONFIRM_PIN]
      const matches = pin === value

      const confirmPinRequired = value.length === 0
      const pinMatches = !matches

      updateAccountSetupState({
        [ACCOUNT_SETUP_STATE.CONFIRM_PIN_REQUIRED]: confirmPinRequired,
        [ACCOUNT_SETUP_STATE.PIN_MATCHES]: pinMatches
      })
      if (confirmPinRequired || pinMatches) {
        return true
      }
      return false
    }
  },
  [ACCOUNT_SETUP_EVENTS.LANGUAGE]: {
    onChange: (index, updateAccountSetupState) => {
      updateAccountSetupState({
        [ACCOUNT_SETUP_STATE.LANGUAGE]: index
      })
    }
  }
}
