import React, { useContext, useMemo, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import { ACCOUNT_SETUP_STATE } from '@apps/redux/features/AccountSetupSlice'
import { PHONE_NUMBER_STATE } from '@apps/redux/features/PhoneNumberSlice'
import { PLANS_STATE, SelectedPlan, loadAllPlans } from '@apps/redux/features/PlansSlice'
import { PAYMENT_STATE, getVouchers } from '@apps/redux/features/PayAndReviewSlice'
import { useSelector, useDispatch } from 'react-redux'
import { PHONE_NUMBER_OPTIONS, PAYMENT_OPTIONS, SIM_OPTIONS, LANGUAGES_CODE, FR_TAX_TYPE_LABELS } from '@apps/utils/constants'
import { currencyFormatter } from '@apps/utils/CurrencyFormatter'
import { ContentContext } from '@apps/contexts/ContentContext'
import Heading1 from '@apps/components/Typography/Heading1'
import Heading3 from '@apps/components/Typography/Heading3'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import BodySmall from '@apps/components/Typography/BodySmall'
import BodyBold from '@apps/components/Typography/BodyBold'
import Body from '@apps/components/Typography/Body'
import Link from '@apps/components/Link'
import { getById, getLongTextFromPage, getShortTextFromPage , getImgByTitle} from '@apps/utils/contentful'
import NextStepsCard from '@apps/components/Cards/NextStepsCard'
import ESimQrCard from '@apps/components/Cards/ESimQrCard'
import { containsProvince } from '@apps/utils/common'
import Sites from '@/sites'
import Button from '@/apps/components/Button'

const TransactionSummarySection = () => {
  const phoneNumberState = useSelector((state) => state.phoneNumberPage)
  const {
    content: { pageContent },
    userType,
    user: { email },
    currentProvinceId
  } = useSelector((state) => state.common)

  const pageRef = useRef(null)
    useEffect( () => {
    window.scrollTo(0, 0)

    if(pageRef.current) {
      pageRef.current.style.minHeight = '100vh'
    }
  }, [])

  const qrCodeRef = useRef(null)

  const dispatch = useDispatch()

  const {
    selectSimType: { simType, cost },
    simNumber,
    esimNumber
  } = useSelector((state) => state.simSelectionPage)
  const {
    [ACCOUNT_SETUP_STATE.FIRST_NAME]: firstName,
    [ACCOUNT_SETUP_STATE.LAST_NAME]: lastName,
    [ACCOUNT_SETUP_STATE.PROVINCE]: provinceValue,
    [ACCOUNT_SETUP_STATE.BAN]: ban
  } = useSelector((state) => state.accountSetupPage)

  const {
    [PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION]: numberSelectionOption,
    [PHONE_NUMBER_STATE.PROVINCE_ID]: provID
  } = phoneNumberState

  const activationProvinceId = useMemo(() => {
    let provinceId = currentProvinceId
    const usePhoneNumberProvince = numberSelectionOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER
    if (usePhoneNumberProvince) {
      provinceId = provID
    }
    return provinceId
  }, [])

  const plansPageState = useSelector((state) => state.plansPage)
  const { localizedPlanItem } = plansPageState
  const { [PLANS_STATE.PROMO]: planPromo } = plansPageState

  const { postPaymentError, activationQRcode } = useSelector((state) => state.common)

  const {
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE]: referralCode,
    [PAYMENT_STATE.SELECTED_PAYMENT_OPTION]: paymentOption,
    [PAYMENT_STATE.CC_INFO.CREDIT_CARD_NUMBER]: creditCardNumber,
    [PAYMENT_STATE.SUMMARY.AMOUNT_TAX]: taxValue,
    [PAYMENT_STATE.SUMMARY.TAX_INFO]: taxInfo,
    [PAYMENT_STATE.SUMMARY.AMOUNT_OWING]: amountDue,
    [PAYMENT_STATE.SUMMARY.AMOUNT_FUNDS]: amountFunds,
    [PAYMENT_STATE.PROMO.PROMOTION_CODE]: promoCode,
    [PAYMENT_STATE.PROMO.PROMOTION_CODE_INFO]: promoCodeInfo,
    [PAYMENT_STATE.RAF.FRIEND_REFERRAL_CODE_INFO]: friendReferralCodeInfo,
    [PAYMENT_STATE.AUTO_TOP_UPS]: autoTopUp,
    [PAYMENT_STATE.PROMO.MULTI_PROMOTION_CODE_INFO]: multiPromotionCodeInfo,
    [PAYMENT_STATE.VOUCHER.ADDED_VOUCHERS]: addedVouchers
  } = useSelector((state) => state.payAndReviewPage)

  const [taxDetails, setTaxDetails] = useState([])

  const {
    [APPLICATION_STATE.PROVINCES]: provinces,
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.PROVINCES]: mappedProvinceList
  } = useContext(ApplicationContext)

  useEffect(() => {
    const taxData = (taxInfo ?? []).map((data, index) => {
      const { label, value } = data
      const parts = label.split(" ")
      let updatedLabel = language === LANGUAGES_CODE.EN ? label : FR_TAX_TYPE_LABELS[parts[0]?.toUpperCase()]
      if(language === LANGUAGES_CODE.FR) {
        parts[0] = updatedLabel
        updatedLabel = parts.join(" ")
      }
      return {
        id: index,
        label: updatedLabel,
        value: currencyFormatter(value, language)
      }
    })
    setTaxDetails(taxData)
  }, [language])

  const text = useMemo(() => {
    if (!pageContent || !language || !userType) return {}
    const transactionSummaryPageContent = getById(pageContent, `transactionSummaryPage`)?.[language]
    return {
      accountNumber: getShortTextFromPage(transactionSummaryPageContent, 'accountNumber'),
      activationDate: getShortTextFromPage(transactionSummaryPageContent, 'activationDate'),
      amountPaid: getShortTextFromPage(transactionSummaryPageContent, 'amountPaid'),
      androidUserMessage: getShortTextFromPage(transactionSummaryPageContent, 'androidUserMessage'),
      androidUserMessageTwo: getShortTextFromPage(transactionSummaryPageContent, 'androidUserMessageTwo'),
      androidUsersTitle: getShortTextFromPage(transactionSummaryPageContent, 'androidUsersTitle'),
      autoPayCreditCard: getShortTextFromPage(transactionSummaryPageContent, 'autoPayCreditCard'),
      autoPayTitle: getShortTextFromPage(transactionSummaryPageContent, 'autoPayTitle'),
      createNumber: getShortTextFromPage(transactionSummaryPageContent, 'createNumber'),
      creditCard: getShortTextFromPage(transactionSummaryPageContent, 'creditCard'),
      creditCardDetails: getShortTextFromPage(transactionSummaryPageContent, 'creditCardDetails'),
      emailUsername: getShortTextFromPage(transactionSummaryPageContent, 'emailUsername'),
      friendReferralCode: getShortTextFromPage(transactionSummaryPageContent, 'friendReferralCode'),
      fundsAddedToYourAccount: getShortTextFromPage(
        transactionSummaryPageContent,
        'fundsAddedToYourAccount'
      ),
      name: getShortTextFromPage(transactionSummaryPageContent, 'name'),
      nextStepsTitle: getShortTextFromPage(transactionSummaryPageContent, 'nextStepsTitle'),
      note: getShortTextFromPage(transactionSummaryPageContent, 'note'),
      registeredAutoPay: getShortTextFromPage(transactionSummaryPageContent, 'registeredAutoPay'),
      notRegisteredAutoPay: getShortTextFromPage(
        transactionSummaryPageContent,
        'notRegisteredAutoPay'
      ),
      numberSelection: getShortTextFromPage(transactionSummaryPageContent, 'numberSelection'),
      paymentType: getShortTextFromPage(transactionSummaryPageContent, 'paymentType'),
      phoneNumber: getShortTextFromPage(transactionSummaryPageContent, 'phoneNumber'),
      province: getShortTextFromPage(transactionSummaryPageContent, 'province'),
      subtotal: getShortTextFromPage(transactionSummaryPageContent, 'subtotal'),
      tax: getShortTextFromPage(transactionSummaryPageContent, 'tax'),
      transactionSummary: getShortTextFromPage(transactionSummaryPageContent, 'transactionSummary'),
      transferNumber: getShortTextFromPage(transactionSummaryPageContent, 'transferNumber'),
      voucherCodes: getShortTextFromPage(transactionSummaryPageContent, 'voucherCodes'),
      eSimReady: getShortTextFromPage(transactionSummaryPageContent, 'eSimReady'),
      insertSimCardStep: getShortTextFromPage(transactionSummaryPageContent, 'insertSimCardStep'),
      insertSimCardStepTitle: getShortTextFromPage(
        transactionSummaryPageContent,
        'insertSimCardStepTitle'
      ),
      insertSimCardStepInfo: getShortTextFromPage(
        transactionSummaryPageContent,
        'insertSimCardStepInfo'
      ),
      insertSimCardStepText: getShortTextFromPage(
        transactionSummaryPageContent,
        'insertSimCardStepText'
      ),
      onceCompleteStep: getShortTextFromPage(transactionSummaryPageContent, 'onceCompleteStep'),
      onceCompleteStepTitle: getShortTextFromPage(
        transactionSummaryPageContent,
        'onceCompleteStepTitle'
      ),
      onceCompleteStepInfo: getShortTextFromPage(
        transactionSummaryPageContent,
        'onceCompleteStepInfo'
      ),
      onceCompleteStepText: getShortTextFromPage(
        transactionSummaryPageContent,
        'onceCompleteStepText'
      ),
      receiveESimStepTitle: getShortTextFromPage(
        transactionSummaryPageContent,
        'receiveESimStepTitle'
      ),
      receiveESimStepText: getShortTextFromPage(
        transactionSummaryPageContent,
        'receiveESimStepText'
      ),
      goToArticle: getShortTextFromPage(transactionSummaryPageContent, 'goToArticle'),
      goToPortal: getShortTextFromPage(transactionSummaryPageContent, 'goToPortal'),
      planAndPayment: getShortTextFromPage(transactionSummaryPageContent, 'planAndPayment'),
      printPageStepText: getShortTextFromPage(transactionSummaryPageContent, 'printPageStepText'),
      printPageStepTitle: getShortTextFromPage(transactionSummaryPageContent, 'printPageStepTitle'),
      printSummary: getShortTextFromPage(transactionSummaryPageContent, 'printSummary'),
      savePageStep: getShortTextFromPage(transactionSummaryPageContent, 'savePageStep'),
      savePageStepInfo: getShortTextFromPage(transactionSummaryPageContent, 'savePageStepInfo'),
      scanQrStep: getShortTextFromPage(transactionSummaryPageContent, 'scanQrStep'),
      scanQrStepInfo: getShortTextFromPage(transactionSummaryPageContent, 'scanQrStepInfo'),
      selfServeAccountInformation: getShortTextFromPage(transactionSummaryPageContent, 'selfServeAccountInformation'),
      selfServeLoginText: getShortTextFromPage(transactionSummaryPageContent, 'selfServeLoginText'),
      selfServeStep: getShortTextFromPage(transactionSummaryPageContent, 'selfServeStep'),
      selfServeStepInfo: getShortTextFromPage(transactionSummaryPageContent, 'selfServeStepInfo'),
      selfServeStepText: getShortTextFromPage(transactionSummaryPageContent, 'selfServeStepText'),
      selfServeTitle: getShortTextFromPage(transactionSummaryPageContent, 'selfServeTitle'),
      referralDiscountNote: getShortTextFromPage(transactionSummaryPageContent, 'referralDiscountNote'),
      simAndPhoneNumber: getShortTextFromPage(transactionSummaryPageContent, 'simAndPhoneNumber'),
      simCardActive: getShortTextFromPage(transactionSummaryPageContent, 'simCardActive'),
      simCardNumber: getShortTextFromPage(transactionSummaryPageContent, 'simCardNumber'),
      transferNumberNote: getLongTextFromPage(transactionSummaryPageContent, 'transferNumberNote'),
      selfServeLoginLink: getLongTextFromPage(transactionSummaryPageContent, 'selfServeLoginUrl'),
      portalLink: getLongTextFromPage(transactionSummaryPageContent, 'portalLink'),
      articleLink: getLongTextFromPage(transactionSummaryPageContent, 'articleLink'),
      yellowExclamation: getImgByTitle(transactionSummaryPageContent, 'yellow-exclamation'),
      creditcardUpdateMessageTitle: getShortTextFromPage(transactionSummaryPageContent, 'creditcard-update-message-title'),
      creditcardUpdateMessageDetails: getShortTextFromPage(transactionSummaryPageContent, 'creditcard-update-message-details'),
      creditcardUpdatselfServeLoginLink: getLongTextFromPage(transactionSummaryPageContent, 'creditcard-update-message-selfservice-login-link'),
      creditcardUpdatselfServeLoginText: getShortTextFromPage(transactionSummaryPageContent, 'creditcard-update-message-selfservice-login-text'),
      staticQrCode: getImgByTitle(transactionSummaryPageContent, 'static-qr-code'),
      mobileBgImage: getImgByTitle(transactionSummaryPageContent, 'mobile-bg-image'),
      shareQrCodeBtnLabel: getShortTextFromPage(transactionSummaryPageContent, 'share-qr-code-btn-label'),
      shareQrImageTitle: getShortTextFromPage(transactionSummaryPageContent, 'share-qr-image-title'),
      shareQrCodeTitle: getShortTextFromPage(transactionSummaryPageContent, 'share-qr-code-title'),
      shareQrCodeText: getShortTextFromPage(transactionSummaryPageContent, 'share-qr-code-text'),
      simTitle: getShortTextFromPage(transactionSummaryPageContent, 'sim-title'),
      eSIMHeading: getShortTextFromPage(transactionSummaryPageContent, 'esim-heading'),
      promo: getShortTextFromPage(transactionSummaryPageContent, 'promo')
    }
  }, [pageContent, language, userType])

  const subtitle = useMemo(() => {
    if (simType === SIM_OPTIONS.P_SIM) return text?.simCardActive
    return text?.eSimReady
  }, [text, simType])

  const provinceName = () => {
    if (provinces) {
      const result =
        provinces.find((value) => {
          return value.value === activationProvinceId
        }) || {}

      const { label: { en, fr } = '' } = result
      return language === 'en' ? en : fr
    }
    return null
  }

  const dollarAmount = amountDue ? amountDue.split('.')[0] : 0
  const decimalAmount = amountDue ? amountDue.split('.')[1] : 0

  const today = new Date()

  const getPhoneNumber = (state) => {
    return state[PHONE_NUMBER_STATE.SELECTED_PHONE_NUMBER_OPTION] ===
      PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER
      ? state[PHONE_NUMBER_STATE.CREATE_NEW_NUMBER.PHONE_NUMBER].label
      : `(${state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_AREA_CODE]}) 
      ${state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_THREE_DIGITS]}
       - 
      ${state[PHONE_NUMBER_STATE.TRANSFER_A_NUMBER.PORT_IN_FOUR_DIGITS]}`
  }

  const formatTotal = (language) => {
    if (language === 'en') {
      return (
        <>
          <span className="text-4xl">$</span>
          <span className="text-6xl">{dollarAmount}</span>
          <span className="text-4xl">{`.${decimalAmount}`}</span>
        </>
      )
    }
    return (
      <>
        <span className="text-6xl">{dollarAmount}</span>
        <span className="text-4xl">{`,${decimalAmount}`}</span>
        <span className="text-4xl">$</span>
      </>
    )
  }

  const {
    transactionSummary,
    printSummary,
    selfServeAccountInformation,
    name,
    emailUsername,
    friendReferralCode,
    province,
    note,
    referralDiscountNote,
    simAndPhoneNumber,
    simCardNumber,
    phoneNumber,
    numberSelection,
    createNumber,
    transferNumber,
    transferNumberNote,
    planAndPayment,
    selectedPlan,
    tax,
    subtotal,
    amountPaid,
    paymentType,
    voucherCodes,
    fundsAddedToYourAccount,
    autoPayTitle,
    registeredAutoPay,
    notRegisteredAutoPay,
    autoPayCreditCard,
    activationDate,
    creditCard,
    creditCardDetails,
    nextStepsTitle,
    accountNumber,
    promo,
    staticQrCode,
    mobileBgImage,
    shareQrCodeBtnLabel,
    shareQrImageTitle,
    shareQrCodeTitle,
    shareQrCodeText,
    simTitle,
    eSIMHeading
  } = text


  const localizedPlans = useMemo(() => {
    const usePhoneNumberProvince =
    numberSelectionOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER && provID
    const selectedProv = usePhoneNumberProvince
      ? mappedProvinceList?.find((pr) => pr.value === provID)?.shortName
      : mappedProvinceList?.find((pr) => pr.value === currentProvinceId)?.shortName
    const plansObject = localizedPlanItem?.[language]?.find(Boolean)
    const { plans: portalPlanList } = plansObject || {}
    return portalPlanList?.filter((pl) => !pl.province || containsProvince(pl.province, selectedProv))
  }, [
    mappedProvinceList,
    provID,
    currentProvinceId,
    language,
    localizedPlanItem,
    numberSelectionOption
  ])

  useEffect(() => {
    dispatch(loadAllPlans(localizedPlans))
  }, [localizedPlans])

  const { dynamicContent } = useContext(ContentContext)
  const { transactionSummaryPage = {} } = dynamicContent || {}
  const { content } = transactionSummaryPage

  const accentImage = getById(content, 'kpre-accent-3')?.image
  const greenCircleCheck = getById(content, 'green-check-mark')?.image
  const grapeCircleCheck = getById(content, 'grape-check-mark')?.image
  const grapeInfoIcon = getById(content, 'grape-info-icon')?.image
  const testQrCode = getById(content, 'test-qr-code')?.image

  const maskCreditCard = (value) => {
    const lastFourDigits = value.substr(-4)

    return `••••••••••••${lastFourDigits}`
  }

  const plan = SelectedPlan(plansPageState)

  if (!plan) {
    return <></>
  }

  const handleShareQrCode = async () => {
    try {
      const imgElement = qrCodeRef.current.querySelector('img')
      const dataUrl = imgElement.src

      if (navigator.share) {
        const blob = await fetch(dataUrl).then(res => res.blob())
        const filesArray = [
          new File([blob], `${shareQrImageTitle}.png`, { type: blob.type })
        ];

        await navigator.share({
          files: filesArray,
          title: shareQrCodeTitle,
          text: shareQrCodeText,
        })
      } else {
        alert('Web Share API is not supported in your browser.')
      }
    } catch (error) {
      console.error('Error sharing:', error)
    }
  };  

  const getMultiPromoInfo = (multiPromoInfo) => {
    return (
      <>
        {multiPromoInfo && (
          <>
            {multiPromoInfo.map((promoInfo) => {
              return (
                <>
                  {promoInfo && promoInfo.bundles && (
                    <>
                      {promoInfo.bundles.map((bundle) => {
                        // eslint-disable-next-line no-shadow
                        const { fee = 0, id, name } = bundle
                        return (
                          <>
                            <BodySmall key={id}>{name}</BodySmall>
                            <BodySmallBold className="justify-self-end" key={id}>
                              {Math.abs(fee) > 0 ? currencyFormatter(fee, language) : ''}
                            </BodySmallBold>
                          </>
                        )
                      })}
                    </>
                  )}
                </>
              )
            })}
          </>
        )}
      </>
    )
  }
  return (
    <div>
      <div className="flex flex-col-reverse sm:flex-row justify-between">
        <Heading1 className="mb-4">{transactionSummary}</Heading1>
        <div
          className="flex flex-row items-center justify-end sm:justify-start mb-4 sm:mb-0"
          onClick={() => {
            window.print()
          }}
        >
          <BodySmallBold className="pl-2 underline cursor-pointer">{printSummary}</BodySmallBold>
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center mb-5">
          <img src={greenCircleCheck?.url} alt="check" />
          <p className="font-bodyLarge text-green-success pl-4 leading-7">{subtitle}</p>
        </div>
        <NextStepsCard  backgroundImage={isMobile ? mobileBgImage?.url : accentImage?.url}>
          <div className="flex flex-col md:flex-row font-body text-copy">
            <div className="md:w-8/12">
              <BodyBold textColor="text-green-success" className="text-step leading-6 mb-4">
                {nextStepsTitle}
              </BodyBold>
              {userType === Sites.consumer && simType === SIM_OPTIONS.P_SIM && (
                <div className="w-full">
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.insertSimCardStep}</BodyBold>
                      <Body className="inline">{text?.insertSimCardStepInfo}</Body>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.onceCompleteStep}</BodyBold>
                      <Body className="inline">{text?.onceCompleteStepInfo}</Body>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 leading-6">
                      <BodyBold className="inline">{text?.selfServeStep}</BodyBold>
                      <Body className="inline">{text?.selfServeStepInfo}</Body>
                      <Link
                        target="_blank"
                        className="font-h1 text-primary text-sm font-bold"
                        href={text?.selfServeLoginLink}
                      >
                        {text?.selfServeLoginText}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {userType === Sites.consumer && simType === SIM_OPTIONS.E_SIM && (
                <div className="text-copy font-body w-full">
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.scanQrStep}</BodyBold>
                      <Body className="inline">{text?.scanQrStepInfo}</Body>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.savePageStep}</BodyBold>
                      <Body className="inline">{text?.savePageStepInfo}</Body>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 leading-6">
                      <BodyBold className="inline">{text?.selfServeStep}</BodyBold>
                      <Body className="inline">{text?.selfServeStepInfo}</Body>
                      <Link
                        target="_blank"
                        className="font-h1 text-primary text-sm font-bold"
                        href={text?.selfServeLoginLink}
                      >
                        {text?.selfServeLoginText}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {userType === Sites.dealer && simType === SIM_OPTIONS.P_SIM && (
                <div className="text-copy font-body w-full">
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.insertSimCardStepTitle}</BodyBold>
                      <Body className="inline">{text?.insertSimCardStepText}</Body>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 leading-6">
                      <BodyBold className="inline">{text?.onceCompleteStepTitle}</BodyBold>
                      <Body className="inline">{text?.onceCompleteStepText}</Body>
                    </div>
                  </div>
                </div>
              )}
              {userType === Sites.dealer && simType === SIM_OPTIONS.E_SIM && (
                <div className="text-copy font-body w-full">
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.receiveESimStepTitle}</BodyBold>
                      <Body className="inline">{text?.receiveESimStepText}</Body>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.printPageStepTitle}</BodyBold>
                      <Body className="inline">{text?.printPageStepText}</Body>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeCircleCheck?.url} alt="check" />
                    <div className="pl-4 mb-4 leading-6">
                      <BodyBold className="inline">{text?.selfServeTitle}</BodyBold>
                      <Body className="inline">{text?.selfServeStepText}</Body>
                      <Link
                        target="_blank"
                        className="font-h1 text-primary text-sm font-bold"
                        href={text?.portalLink}
                      >
                        {text?.goToPortal}
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-row items-start">
                    <img src={grapeInfoIcon?.url} alt="check" />
                    <div className="pl-4 leading-6">
                      <BodyBold className="inline">{text?.androidUserMessage}</BodyBold>
                      <Body className="inline">{text?.androidUserMessageTwo}</Body>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="md:w-4/12 mt-8 md:m-0 flex justify-center">
              {userType === Sites.consumer && simType === SIM_OPTIONS.E_SIM && (
                <ESimQrCard qrCode={activationQRcode} qrCodeRef={qrCodeRef} />
              )}
              {userType === Sites.dealer && simType === SIM_OPTIONS.E_SIM && (
                <ESimQrCard qrCode={staticQrCode?.url} qrCodeRef={qrCodeRef} />
              )}
            </div>
          </div>
          {isMobile && simType === SIM_OPTIONS.E_SIM && (
            <div className="w-full sm:w-4/12 pb-10">
              <Button
                testId="share-qr-code"
                onClick={() => handleShareQrCode()}
                label={shareQrCodeBtnLabel}
                className="w-full mt-8 font-h4 mb-10"
              />
            </div>
          )}
        </NextStepsCard>
      </div>

    { postPaymentError && ( 
    <div class="py-5 pl-10 mt-6 bg-customYellowCard">
          <div className="mb-4 leading-6">
          <img className="inline" src={text?.yellowExclamation?.url} alt={text?.yellowExclamation?.altText}></img>
            <BodyBold className="inline pl-4">{text?.creditcardUpdateMessageTitle}</BodyBold>
            <Body className="mt-4 pr-4">{text?.creditcardUpdateMessageDetails}</Body>
            <Link
              target="_blank"
              className="font-h1 text-primary text-sm font-bold"
              href={text?.creditcardUpdatselfServeLoginLink}
            >
              {text?.creditcardUpdatselfServeLoginText}
            </Link>
          </div>
      </div> ) }

      <div className="mt-4 space-y-4">
        <div>
          <Heading3 className="py-4">{selfServeAccountInformation}</Heading3>
          <div className="p-4 bg-white">
            <div className="grid grid-cols-3 gap-y-4">
              <BodySmall className="col-span-1">{accountNumber}</BodySmall>
              <BodySmallBold className="col-span-2" testId="transactionSummary-ban">
                {ban}
              </BodySmallBold>
              <BodySmall className="col-span-1">{name}</BodySmall>
              <BodySmallBold className="col-span-2">{`${firstName} ${lastName}`}</BodySmallBold>
              <BodySmall className="col-span-1">{emailUsername}</BodySmall>
              <BodySmallBold className="col-span-2">{email}</BodySmallBold>
              <BodySmall className="col-span-1">{province}</BodySmall>
              <BodySmallBold className="col-span-2">{provinceName()}</BodySmallBold>
              {referralCode && friendReferralCodeInfo && friendReferralCodeInfo.value && (
                <>
                  <BodySmall className="col-span-1">{friendReferralCode}</BodySmall>
                  <BodySmallBold className="col-span-2">{referralCode}</BodySmallBold>
                  <BodySmall className="col-span-3">
                    <BodySmallBold className="inline-block">
                      {note}
                      &nbsp;
                    </BodySmallBold>
                    {referralDiscountNote}
                  </BodySmall>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <Heading3 className="py-4">{simAndPhoneNumber}</Heading3>
          <div className="p-4 bg-white">
            <div className="grid grid-cols-3 gap-y-4">
              <BodySmall className="col-span-1">{simCardNumber}</BodySmall>
              <BodySmallBold className="col-span-2" testId="transactionSummary-sim">
                {simType === SIM_OPTIONS.P_SIM ? simNumber : esimNumber}
              </BodySmallBold>
              <BodySmall className="col-span-1">{phoneNumber}</BodySmall>
              <BodySmallBold className="col-span-2">
                {getPhoneNumber(phoneNumberState)}
              </BodySmallBold>
              <BodySmall className="col-span-1">{numberSelection}</BodySmall>
              <BodySmallBold className="col-span-2">
                {numberSelectionOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER
                  ? createNumber
                  : transferNumber}
              </BodySmallBold>
              <BodySmall className="col-span-3">
                <BodySmallBold className="inline-block">
                  {note}
                  &nbsp;
                </BodySmallBold>
                {transferNumberNote}
              </BodySmall>
            </div>
          </div>
        </div>
        <div>
          <Heading3 className="py-4">{planAndPayment}</Heading3>
          <div className="p-4 bg-white">
            <div className="grid grid-cols-2 gap-y-4">
              <BodySmall className="col-span-2">{selectedPlan}</BodySmall>
              <BodySmallBold>{`${plan?.name} ${plan?.subtitle}`}</BodySmallBold>
              <BodySmallBold className="justify-self-end">
                {currencyFormatter(plan?.value, language)}
              </BodySmallBold>
              <ul className="col-span-2 ml-4 list-disc list-outside text-copy">
                {plan?.features.map((feature) => {
                  return (
                    <li key={feature}>
                      <BodySmall>{feature}</BodySmall>
                    </li>
                  )
                })}
              </ul>
              {planPromo && !promoCode && multiPromotionCodeInfo?.length === 0 && (
                <>
                  <BodySmallBold className="col-span-2">{promo}</BodySmallBold>
                  {planPromo.bundles.map((bundle, i) => {
                    const { fee = 0, name: promoName } = bundle
                    return (
                      <div key={i}>
                        <BodySmall>{promoName}</BodySmall>
                        <BodySmallBold className="justify-self-end">
                          {Math.abs(fee) > 0 ? currencyFormatter(fee, language) : ''}
                        </BodySmallBold>
                      </div>
                    )
                  })}
                </>
              )}
              <hr className="col-span-2 border-t border-solid border-primary" />
              {simType === SIM_OPTIONS.E_SIM && (
                <>
                 <BodySmallBold className="col-span-2">{simTitle}</BodySmallBold>
                 <BodySmall>{eSIMHeading}</BodySmall>
                 <BodySmallBold className="justify-self-end">{currencyFormatter(cost, language)}</BodySmallBold>
                 <hr className="col-span-2 border-t border-solid border-primary" />
                </>
              )}
              {promoCode && promoCodeInfo !== '' && (
                <>
                  <BodySmall className="col-span-2">{promo}</BodySmall>
                  {promoCodeInfo &&
                    promoCodeInfo.bundles &&
                    promoCodeInfo.bundles.map((bundle) => {
                      const { fee = 0, name: promoName } = bundle
                      return (
                        <>
                          <BodySmall>{promoName}</BodySmall>
                          <BodySmallBold className="justify-self-end">
                            {Math.abs(fee) > 0 ? currencyFormatter(fee, language) : ''}
                          </BodySmallBold>
                        </>
                      )
                    })}
                  <hr className="col-span-2 border-t border-solid border-primary" />
                </>
              )}
              {dynamicContent?.accountSetupPage.featureFlags.enableMultiPromo &&
                multiPromotionCodeInfo?.length > 0 && (
                  <>
                    <BodySmallBold className="col-span-2">{promo}</BodySmallBold>
                    {getMultiPromoInfo(multiPromotionCodeInfo)}
                    <hr className="col-span-2 border-t border-solid border-primary" />
                  </>
                )}
              <BodySmall>{subtotal}</BodySmall>
              <BodySmallBold className="justify-self-end">
                {currencyFormatter(amountDue - taxValue, language)}
              </BodySmallBold>
              {Number(taxValue) !== NaN && (
                <>
                  <BodySmall>{tax}</BodySmall>
                  <BodySmall>{''}</BodySmall>
                  {paymentOption === PAYMENT_OPTIONS.CREDIT_CARD &&
                    addedVouchers?.length === 0 &&
                    taxDetails?.map(({ label, value }) => (
                      <>
                        <BodySmall>{label}</BodySmall>
                        <BodySmallBold className="justify-self-end">{value}</BodySmallBold>
                      </>
                    ))}
                  <hr className="col-span-2 border-t border-solid border-primary" />
                </>
              )}
              <BodySmallBold>{amountPaid}</BodySmallBold>
              <span className="font-h1 text-copy justify-self-end">{formatTotal(language)}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-8 p-4 bg-white">
            <div className="grid grid-cols-3 gap-y-4">
              <BodySmall className="col-span-1">{paymentType}</BodySmall>
              <BodySmallBold className="col-span-2">
                {paymentOption === PAYMENT_OPTIONS.CREDIT_CARD ? creditCard : voucherCodes}
              </BodySmallBold>
              {paymentOption === PAYMENT_OPTIONS.VOUCHER && (
                <>
                  <BodySmall className="col-span-1">{voucherCodes}</BodySmall>
                  <BodySmallBold className="col-span-2" testId="transactionSummary-vouchers">
                    {getVouchers(addedVouchers)}
                  </BodySmallBold>
                </>
              )}
              {amountFunds > 0 && (
                <>
                  <BodySmall className="col-span-1">
                    {fundsAddedToYourAccount}
                    &nbsp;
                  </BodySmall>
                  <BodySmallBold className="col-span-2">
                    {currencyFormatter(amountFunds, language)}
                  </BodySmallBold>
                </>
              )}
              {paymentOption === PAYMENT_OPTIONS.CREDIT_CARD && (
                <>
                  <BodySmall className="col-span-1">
                    {creditCardDetails}
                    &nbsp;
                  </BodySmall>
                  <BodySmallBold className="col-span-2">
                    {maskCreditCard(creditCardNumber)}
                  </BodySmallBold>
                </>
              )}
              <BodySmall className="col-span-1">{autoPayTitle}</BodySmall>
              <BodySmallBold className="col-span-2">
                {autoTopUp ? registeredAutoPay : notRegisteredAutoPay}
              </BodySmallBold>
              {autoTopUp && paymentOption === PAYMENT_OPTIONS.VOUCHER && (
                <>
                  <BodySmall className="col-span-1">{autoPayCreditCard}</BodySmall>
                  <BodySmallBold className="col-span-2">
                    {maskCreditCard(creditCardNumber)}
                  </BodySmallBold>
                </>
              )}
              <BodySmall className="col-span-1">{activationDate}</BodySmall>
              <BodySmallBold className="col-span-2">
                {`${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`}
              </BodySmallBold>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionSummarySection
