import React, { useContext, useRef, useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Heading2 from '@apps/components/Typography/Heading2'
import Heading3 from '@apps/components/Typography/Heading3'
import Heading4 from '@apps/components/Typography/Heading4'
import BodySmallBold from '@apps/components/Typography/BodySmallBold'
import BodySmall from '@apps/components/Typography/BodySmall'
import RichText from '@apps/components/RichText'
import Expander from '@apps/components/Expander'
import PlansGrid from '@apps/components/PlansGrid'
import BodyBold from '@apps/components/Typography/BodyBold'
import { getById, getBySlug } from '@apps/utils/contentful'
import { ContentContext } from '@apps/contexts/ContentContext'
import { useSelector, useDispatch } from 'react-redux'
import { PLANS_STATE, updatePlansState } from '@apps/redux/features/PlansSlice'
import { setSelectedPlan } from '@apps/redux/features/CommonSlice'
import { ApplicationContext, APPLICATION_STATE } from '@apps/contexts/ApplicationContext'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import ActivationSteps from '@sites/shared/Activation/ActivationSteps'
import classNames from 'classnames'
import Sites from '@sites/index'

const NewPlanPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { dynamicContent } = useContext(ContentContext)
  const { [PLANS_STATE.PLAN]: selectedPlan, allPlans } = useSelector((state) => state.plansPage)
  const localSelectedPlan = allPlans?.find(plan => plan?.id === selectedPlan?.id)
  const {
    userType,
    activeStepNumber,
    user: { email, referrerUrl }
  } = useSelector((state) => state.common)
  const { pageContent: pagesContent } = useSelector((state) => state.common.content)
  const { [PLANS_STATE.PRE_SELECTED_PLAN]: preSelectedPlan } = useSelector(
    (state) => state.plansPage
  )
  const consumerActivationPaths = new ConsumerActivationPaths()
  const dealerActivationPaths = new DealerActivationPaths()
  const routes = userType === Sites.dealer ? dealerActivationPaths : consumerActivationPaths

  const usePreSelectedPlanSteps = userType === Sites.consumer && preSelectedPlan
  const activationSteps = new ActivationSteps(routes, usePreSelectedPlanSteps)

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.SERVICES]: { setPatchQuote }
  } = useContext(ApplicationContext)

  const { plansPage = {} } = dynamicContent || {}
  const { content, header } = plansPage

  const pagePrefix = userType === Sites.dealer ? 'Dealer' : 'Consumer'
  const plansPageContent = getById(pagesContent, `plansPage${pagePrefix}`)[language]
  const { shortTextFields } = plansPageContent || {}

  const pageContent = useMemo(() => {
    if (!content) return {}
    return {
      chevronLeft: getById(content, 'chevron-left-black')?.image?.url,
      chevronLeftAlt: getById(content, 'chevron-left-black')?.image?.title,
      legalContent: getById(content, 'legalStuff'),
      planCategory: getById(content, 'plansCategory'),
      back: getBySlug(shortTextFields, 'back')?.value,
      select: getBySlug(shortTextFields, 'select')?.value,
      selectedPlanText: getBySlug(shortTextFields, 'selectedPlan')?.value,
      noPlanSelected: getBySlug(shortTextFields, 'noPlanSelected')?.value,
      continueWithPlan: getBySlug(shortTextFields, 'continueWithPlan')?.value
    }
  }, [content, shortTextFields])

  const {
    chevronLeft,
    chevronLeftAlt,
    legalContent,
    planCategory,
    back,
    select,
    selectedPlanText,
    noPlanSelected,
    continueWithPlan
  } = pageContent

  const [showPlanError, setShowPlanError] = useState(false)
  const planErrorRef = useRef(null)
  const originalPlanRef = useRef(null)

  useEffect(() => {
    if (!originalPlanRef.current && selectedPlan.id) {
      originalPlanRef.current = selectedPlan
    }
  }, [selectedPlan])

  const planSelected = () => {
    return localSelectedPlan && localSelectedPlan.id
  }

  const updateQuote = async () => {
    try {
      const input = { email, pricePlanId: selectedPlan.id, pricePlanGuid: selectedPlan.guid }
      const response = await setPatchQuote(input)
      return response
    } catch (err) {
      console.log('UPDATE QUOTE: PLAN SELECTION ERROR', err)
      return err
    }
  }

  const goToNextPage = async () => {
    const cameFromPlan = referrerUrl?.includes('/activation/plans')
    let newPath = ''

    if (cameFromPlan) {
      newPath = consumerActivationPaths.Plan.replace(':planName', selectedPlan.sluggedName)

      if (selectedPlan) {
        const resetPlan = { id: '', pricePlanGuid: '', sluggedName: '' }
        dispatch(
          setSelectedPlan({
            selectedPlan: resetPlan,
            pricePlanId: resetPlan?.id,
            pricePlanGuid: resetPlan?.pricePlanGuid
          })
        )

        setTimeout(() => {
          dispatch(
            setSelectedPlan({
              selectedPlan: {
                id: selectedPlan?.id,
                pricePlanGuid: selectedPlan?.guid,
                sluggedName: selectedPlan?.sluggedName
              },
              pricePlanId: selectedPlan?.id,
              pricePlanGuid: selectedPlan?.guid
            })
          )
        }, 0)
      }
    }

    const currentStep = activationSteps.getStepByNumber(activeStepNumber)
    if (currentStep) {
      if (currentStep.number === 0 && usePreSelectedPlanSteps && newPath) {
        history.push(newPath)
      } else {
        history.push(currentStep.path)
      }
    } else {
      goToSplashPage()
    }
  }

  const goToSplashPage = () => {
    history.push(consumerActivationPaths.Splash)
  }

  const handlePlanChange = async () => {
    if (selectedPlan?.id && email) {
      const updateQuoteResponse = await updateQuote()
      if (updateQuoteResponse?.data?.accountId) {
        goToNextPage()
      } else {
        goToSplashPage()
      }
    } else {
      goToNextPage()
    }
  }

  const setPlan = (plan) => {
    dispatch(
      updatePlansState({
        [PLANS_STATE.PLAN]: plan,
        [PLANS_STATE.PROMO]: plan.promo
      })
    )
  }

  const goBack = () => {
    if (originalPlanRef.current) {
      setPlan(originalPlanRef.current)
    }
    const currentStep = activationSteps.getStepByNumber(activeStepNumber)
    if (currentStep) {
      if (currentStep.number === 0 && usePreSelectedPlanSteps) {
        history.push(consumerActivationPaths.Plan.replace(':planName', originalPlanRef.current.sluggedName))
      } else {
        history.push(currentStep.path)
      }
    } else {
      goToSplashPage()
    }
  }

  const renderHeading = (text) => {
    if (text?.length > 20) {
      return <Heading3 className='text-primary'>{text}</Heading3>
    } else {
      return <Heading2>{text}</Heading2>
    }
  }

  return (
    <div className="flex flex-col max-w-4xl m-auto text-copy px-4 sm:px-0">
      <div className="flex flex-col sm:flex-row mb-6 sm:mb-8 space-y-10 sm:space-y-0 sm:space-x-6">
        <div className="flex flex-col w-full sm:w-1/2">
          <div>
            <img src={chevronLeft} alt={chevronLeftAlt} className="w-2 inline-block" />
            <button onClick={goBack} type="button">
              <BodyBold className="pl-2 underline inline-block">{back}</BodyBold>
            </button>
          </div>
          <Heading2 className="mt-2 sm:mt-0">{select}</Heading2>
          <div>
            {header && (
              <RichText
                className="pt-6"
                config={{
                  'unordered-list': {
                    className: 'ml-8 text-copy list-disc list-outside2'
                  },
                  paragraph: {
                    className: 'text-copy font-body text-medium leading-7'
                  },
                  'list-item': {
                    className: 'font-body text-medium leading-7'
                  }
                }}
              >
                {header?.styledContent}
              </RichText>
            )}
          </div>
        </div>
        <div className="w-full sm:w-1/2 relative">
          <div
            className={classNames(
              'border border-primary w-full',
              planSelected() ? 'h-auto' : 'h-36'
            )}
          >
            <div className="absolute rounded-full py-2 z-50 px-8 left-6 -top-4 bg-threeGPromo-background">
              <BodySmallBold textColor="text-threeGPromo-text">{selectedPlanText}</BodySmallBold>
            </div>
            {planSelected() ? (
              <div className="relative flex flex-col pl-8 pr-6 pt-10 pb-8 text-primary">
                <div className="flex flex-row space-x-2">
                  <div className="flex flex-row">
                    {language === 'en' && <div className="text-lg font-h4">$</div>}
                    <span
                      className="text-7xl font-h1 text-primary"
                      data-testid="selectPlan-planCost"
                    >
                      {localSelectedPlan?.value}
                    </span>
                    {language === 'fr' && <span className="ml-1 text-lg font-h4">$</span>}
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-primary leading-7 font-h1">
                      {localSelectedPlan?.name}
                    </span>
                    <div className="rounded-full py-1 z-50 px-3 bg-thirtyDaysDurationPlan-background">
                      <p className="text-center text-copy-inverted text-sm font-body undefined">
                        {localSelectedPlan?.subtitle}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-1 pl-5 pr-5">
                  <BodySmall textColor="text-primary" className="whitespace-pre-wrap leading-tight">
                    {localSelectedPlan?.description}
                  </BodySmall>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center w-full h-full relative">
                {renderHeading(noPlanSelected)}
              </div>
            )}
          </div>
          <button
            disabled={!planSelected()}
            onClick={handlePlanChange}
            type="button"
            className={`w-full text-sm font-h1  px-8 py-4 rounded focus:outline-none 
                focus:shadow-active text-copy-inverted bg-primary hover:bg-hover-background hover:text-hover-text
             my-4 font-h4 disabled:bg-primary disabled:text-white disabled:opacity-30`}
          >
            {continueWithPlan}
          </button>
        </div>
      </div>
      <hr className="w-full border border-silver" />
      <div className="border-b divide-y">
        <Expander
          heading={<Heading3>{planCategory ? planCategory.title : ''}</Heading3>}
          body={
            <PlansGrid
              planErrorRef={planErrorRef}
              showPlanError={showPlanError}
              setShowPlanError={setShowPlanError}
            />
          }
          toggle="block"
        />
        {legalContent && (
          <Expander
            heading={<Heading4>{legalContent.title}</Heading4>}
            body={<RichText>{legalContent.content}</RichText>}
          />
        )}
      </div>
    </div>
  )
}

export default NewPlanPage
