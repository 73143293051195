import React from 'react'
import Button from '@apps/components/Button'
import Modal from '@apps/components/Modal'

const PhoneNumberUnavailablePopup = ({
  isOpen,
  onClose,
  onNewPhoneNumberClick,
  unavailableNumberTitle,
  unavailableNumberMessage,
  selectNewPhoneNumberLabel
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={onClose} title={unavailableNumberTitle}>
      <div className="text-black leading-6 text-sm">
        <div className="mt-6 mb-9">
          <p className="text-sm font-body leading-6">{unavailableNumberMessage}</p>
        </div>
        <div className="flex flex-row md:justify-between">
          <Button
            type="button"
            label={selectNewPhoneNumberLabel}
            className="py-3 rounded-md font-h4"
            onClick={onNewPhoneNumberClick}
          />
        </div>
      </div>
    </Modal>
  )
}

export default PhoneNumberUnavailablePopup
