import Brands from '@brands/index'
import operationProxy from './operationProxy'

const BRAND_CODE = {
  [Brands.public]: 'cHVibGljX3NhbHRlZF9jYXJhbWVsXyAgICAgICAgICA=',
  [Brands.koodo]: 'a29vZG9fc2FsdGVkX2NhcmFtZWxfICAgICAgICAgICA='
}

const activationProxy = async (operation, params) => {
  return operationProxy('activation', operation, params)
}

const accountProxy = async (operation, params) => {
  return operationProxy('account', operation, params)
}

export default (brand) => {
  return {
    //
    // activation domain requests
    //

    isSimValid: async (sim) => {
      const { data, error } = await activationProxy('isSimValid', {
        sim: sim?.replace(/[^0-9]*/g, ''),
        brandId: BRAND_CODE[brand]
      })

      if (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      return data
    },
    getDeviceInfo: async (brandName, imeiNo) => {
      return activationProxy('getDeviceInfo', {
        brandName,
        imeiNo
      })
    },
    getProvinces: async () => {
      const provinces = await activationProxy('getProvinces', { brandId: BRAND_CODE[brand] })

      if (provinces && provinces.length > 0) {
        return provinces.map((province) => {
          return {
            label: province.name,
            shortName: province.shortName,
            value: province.id,
            regionId: province.regionId
          }
        })
      }

      return undefined
    },

    isUsernameActive: async (username) => {
      const { data, error } = await activationProxy('isActiveUsername', {
        username,
        brandId: BRAND_CODE[brand]
      })

      if (error) {
        // eslint-disable-next-line
        console.log(error)
      }

      return data
    },

    getCity: async (id) => {
      const cities = await activationProxy('getProvinceGroups', {
        provinceId: id,
        brandId: BRAND_CODE[brand]
      })

      if (cities && cities.length > 0) {
        return cities.map((city) => {
          return {
            value: city.name,
            id: city.groupId
          }
        })
      }

      return undefined
    },

    getPhoneNumbers: async (cityId, amount = 1) => {
      const numbers = await activationProxy('getPhoneNumbers', {
        groupId: cityId,
        amount,
        brandId: BRAND_CODE[brand]
      })

      if (numbers && numbers.length > 0) {
        return numbers.map((number) => {
          const { id, value } = number

          return {
            id,
            value,
            label: `(${value.substring(1, 4)}) ${value.substring(4, 7)} - ${value.substring(7)}`
          }
        })
      }

      return undefined
    },

    setPhoneNumber: async (number) => {
      if (number && number.id) {
        const { data, error } = await activationProxy('setPhoneNumber', {
          number: number.id,
          brandId: BRAND_CODE[brand]
        })

        if (error) {
          // eslint-disable-next-line
          console.log(error)
        }

        return data
      }

      return false
    },

    isEligibleForPort: async (phoneNumber) => {
      if (phoneNumber) {
        const response = await activationProxy('isEligibleForPort', {
          phoneNumber,
          brandId: BRAND_CODE[brand]
        })

        return response
      }

      return false
    },

    activateAll: async (params) => {
      if (params) {
        const response = await activationProxy('activateAll', {
          ...params,
          brandId: BRAND_CODE[brand],
          provider: brand
        })

        return response
      }

      return false
    },
    
    createCookie : async (params) => {
      if (params) {
        const response = await activationProxy('createCookie', {
          ...params,
          brandId: BRAND_CODE[brand],
          provider: brand
        })

        return response
      }

      return false
    },
    retrieveCookie: async () => {
      return activationProxy('retrieveCookie', {
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },
    deleteCookie : async () => {
        return activationProxy('deleteCookie', {
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },
    isValidReferral: async (referralCode, rafSettings) => {
      if (referralCode) {
        const response = await accountProxy('isValidReferral', {
          referralCode,
          brandId: BRAND_CODE[brand],
          rafSettings
        })
        return response
      }
      return false
    },

    validatePromotion: async (
      planId,
      province,
      language,
      retailChannel = '',
      validationCode = '',
      promoCode = ''
    ) => {
      const result = await accountProxy('validatePromotion', {
        optionIds: planId,
        retailChannel,
        province,
        promoCode,
        validationCode,
        language,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
      return result
    },

    findAndValidatePromotions: async (
      planId,
      province,
      language,
      retailChannel = '',
      promoCode = ''
    ) => {
      const result = await accountProxy('findAndValidatePromotions', {
        optionIds: planId,
        retailChannel,
        province,
        promoCode,
        language,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
      return result
    },

    voucherInfo: async (vouchers) => {
      return accountProxy('voucherInfo', { vouchers, brandId: BRAND_CODE[brand], provider: brand })
    },

    calculateTax: async (phoneNumber, amount) => {
      return accountProxy('calculateTax', {
        phoneNumber,
        amount,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },

   getTax: async (amount, province, phoneNumber) => {
      return accountProxy('getTax', {
        amount,
        province,
        phoneNumber,
        brandId: BRAND_CODE[brand]
      })
    },

    retrievePaymentSession: async () => {
      return accountProxy('retrievePaymentSession', {
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },

    processPayment: async (generatedParams) => {
      return accountProxy('processPayment', {
        params: generatedParams
      })
    },
    getAllPagesData: async (pageContent) => {
      return activationProxy('getAllPagesData', {
        brandId: BRAND_CODE[brand],
        contentType: pageContent
      })
    },
    isVerifyToken: async (captchaToken) => {
      if (captchaToken) {
        return activationProxy('isVerifyToken', { captchaToken })
      }
    },
    retrieveAccountByEmail: async (email) => {
      return activationProxy('retrieveAccountByEmail', {
        email,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },

    retrieveQuoteByEmail: async (input) => {
      return activationProxy('retrieveQuoteByEmail', {
        input,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },

    setPatchQuote: async (input) => {
      return activationProxy('setPatchQuote', {
        input,
        brandId: BRAND_CODE[brand]
      })
    },

    validateUserDetails: async (input) => {
      return activationProxy('validateUserDetails', {
        input,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },

    validateOtp: async (input) => {
      return activationProxy('validateOtp', {
        input,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },

    getPlanList: async (
      selectedProvince = '',
      contentType,
      retrievePromotionsFromContentful = true
    ) => {
      return activationProxy('getPlanList', {
        selectedProvince,
        contentType,
        retrievePromotionsFromContentful
      })
    },

    createQuote: async (input) => {
      return activationProxy('createQuote', {
        input,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    },

    sendEcpEmail: async (input) => {
      return activationProxy('sendEcpEmail', {
        input,
        brandId: BRAND_CODE[brand],
        provider: brand
      })
    }
  }
}
