import { useLocation } from 'react-router'
import Sites from '@sites/index'

function useSite() {
  const location = useLocation()
  const path = location.pathname

  if (!path) {
    return undefined
  }

  // if (path.toLowerCase().includes(Sites.anelletti)) {
  //   return Sites.anelletti
  // }
  //
  // if (path.toLowerCase().includes(Sites.pasta)) {
  //   return Sites.pasta
  // }

  if (path.toLowerCase().includes(Sites.consumer)) {
    return Sites.consumer
  }

  if (path.toLowerCase().includes(Sites.dealer)) {
    return Sites.dealer
  }

  return undefined
}

export default useSite
