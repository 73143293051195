import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import TabGroup from '@apps/components/TabGroup'
import TabItem from '@apps/components/TabGroup/TabItem'
import Heading3 from '@apps/components//Typography/Heading3'
import { PHONE_NUMBER_OPTIONS } from '@apps/utils/constants'
import { getById, getBySlug } from '@apps/utils/contentful'
import { useSelector } from 'react-redux'
import Sites from '@sites/index'

const ChoosePhoneOption = ({ onSelect, selectedOption }) => {
  const {
    userType,
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const isDealer = userType === Sites.dealer
  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const textContent = useMemo(() => {
    const phoneNumberPage = getById(pageContent, `phoneNumberPage${pagePrefix}`)[selectedLanguage]
    if (!phoneNumberPage) return {}
    const { shortTextFields } = phoneNumberPage
    return {
      newPhoneNumberOption: getBySlug(shortTextFields, 'new-phone-number-option')?.value,
      transferExistingNumberOption: getBySlug(shortTextFields, 'transfer-existing-number-option')
        ?.value
    }
  }, [pageContent, selectedLanguage])

  return (
    <TabGroup onSelect={onSelect} selected={selectedOption}>
      <TabItem
        tabindex="0"
        role="tabpanel"
        id={PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER}
        name={PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER}
        selected={selectedOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER}
        data-testid="phoneNumber-createNumber"
      >
        <Heading3
          className={`text-center text-sm md:text-base lg:text-lg ${
            selectedOption === PHONE_NUMBER_OPTIONS.CHOOSE_A_NEW_NUMBER && 'text-copy-inverted'
          }`}
        >
          {textContent?.newPhoneNumberOption}
        </Heading3>
      </TabItem>
      <TabItem
        tabindex="0"
        role="tabpanel"
        id={PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER}
        name={PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER}
        selected={selectedOption === PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER}
        data-testid="phoneNumber-transferNumber"
      >
        <Heading3
          className={`text-center text-sm md:text-base lg:text-lg ${
            selectedOption === PHONE_NUMBER_OPTIONS.TRANSFER_NUMBER && 'text-copy-inverted'
          }`}
        >
          {textContent?.transferExistingNumberOption}
        </Heading3>
      </TabItem>
    </TabGroup>
  )
}

ChoosePhoneOption.propTypes = {
  onSelect: PropTypes.func,
  selectedOption: PropTypes.string
}

export default ChoosePhoneOption
