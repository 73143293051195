/* eslint-disable import/extensions */
import React, { useContext, useState, useMemo, useEffect, useRef } from 'react'
import Heading2 from '@apps/components/Typography/Heading2'
import { useHistory } from 'react-router-dom'
import { APPLICATION_STATE, ApplicationContext, setLandingLogicLoader } from '@apps/contexts/ApplicationContext'
import {
  setSimType,
  updateSimSelectionState
} from '@apps/redux/features/SimSelectionSlice'
import SimSelectionChangeHandlers, {
  SIM_SELECTION_EVENTS
} from '@apps/utils/ChangeHandlers/SimSelectionChangeHandlers'
import { getBySlug, getById } from '@apps/utils/contentful'
import Sites from '@sites/index'
import ConsumerActivationPaths from '@sites/consumer/activation/ConsumerActivationPaths'
import DealerActivationPaths from '@sites/dealer/activation/DealerActivationPaths'
import { CONSUMER_SIM_OPTIONS, SIM_OPTIONS } from '@apps/utils/constants'
import Modal from '@apps/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import SelectSimRadioGroup from '@/apps/components/SelectSimRadioGroup'
import SimSelectionDetails from '@/apps/components/SimSelectionDetails'
import SimSelectionFooter from '@/apps/components/SimSelectionFooter'
import Alert from '@/apps/components/Alert'
import Button from '@/apps/components/Button'

const SimSelectionPage = () => {
  const {
    userType,
    quote: { quoteInfo },
    user: { email }
  } = useSelector((state) => state.common)
  const { pageContent, featureFlags } = useSelector((state) => state.common.content)
  const simSelectionState = useSelector((state) => state.simSelectionPage)

  const history = useHistory()
  const dispatch = useDispatch()

  const dealerActivationPaths = new DealerActivationPaths()
  const consumerActivationPaths = new ConsumerActivationPaths()

  const [showESimCompatibilityWarning, setShowESimCompatibilityWarning] = useState(false)
  const {
    imeiChecked,
    imeiCompatible,
    eid,
    selectSimType,
    simNumber,
    simError,
    isComplete
  } = simSelectionState
  const { simType } = selectSimType

  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.SERVICES]: brandServices
  } = useContext(ApplicationContext)

  const { setPatchQuote, isSimValid } = brandServices

  const simSelectionPageContent = useMemo(() => {
    if (!pageContent || !language) return {}
    const page = getById(pageContent, `haveSimPage`)
    return page[language]
  }, [pageContent, language, userType])

  const { shortTextFields } = simSelectionPageContent
  const enableProceedWithoutCheckingESim = featureFlags && featureFlags[0]?.enableProceedWithoutCheckingESim

  const textContent = useMemo(() => {
    if (!shortTextFields) return {}
    return {
      simSectionHeading: getBySlug(shortTextFields, 'simSectionHeading')?.value,
      nextStepPSIMLabel: getBySlug(shortTextFields, 'nextStepLabel')?.value,
      previousStepLabel: getBySlug(shortTextFields, 'previousStepLabel')?.value,
      haveSim: getBySlug(shortTextFields, 'haveSim')?.value,
      newSim: getBySlug(shortTextFields, 'newSim')?.value,
      haveSimHeading: getBySlug(shortTextFields, 'haveSimHeading')?.value,
      nextStepESIMLink: getBySlug(shortTextFields, 'nextStepESIMLink')?.value,
      nextStepESIMLabel: getBySlug(shortTextFields, 'nextStepESIMLabel')?.value,
      compatibilityWarningHeader: getBySlug(shortTextFields, 'compatibilityWarningHeader')?.value,
      compatibilityWarningTitle: getBySlug(shortTextFields, 'compatibilityWarningTitle')?.value,
      compatibilityWarningMessage: getBySlug(shortTextFields, 'compatibilityWarningMessage')?.value,
      buyPhysicalSIM: getBySlug(shortTextFields, 'buyPhysicalSIM')?.value,
      proceedAnyway: getBySlug(shortTextFields, 'proceedAnyway')?.value,
      simRequiredError: getBySlug(shortTextFields, 'simRequiredError')?.value,
      simLengthError: getBySlug(shortTextFields, 'simLengthError')?.value,
      simUnavailableError: getBySlug(shortTextFields, 'simUnavailableError')?.value,
      eSimCost: getBySlug(shortTextFields, 'eSimCost')?.value,
      pSimCost: getBySlug(shortTextFields, 'pSimCost')?.value
    }
  }, [shortTextFields, userType])

  const { simRequiredError, simLengthError, simUnavailableError, eSimCost, pSimCost } = textContent

  const eSimPrice = parseFloat(eSimCost) ?? 0
  const pSimPrice = parseFloat(pSimCost) ?? 0

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    return history.block((location, action) => {
      if ((action === 'pop' || action === 'POP') && simSelectionState.isComplete === false) {
        return false
      }

      return () => {
        unblock()
      }
    })
  }, [history, simSelectionState.isComplete])

  const simRef = useRef(null)

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const radioOptions = useMemo(() => {
    if (isDealer) {
      return [
        { name: textContent?.haveSim, value: SIM_OPTIONS.P_SIM },
        { name: textContent?.newSim, value: SIM_OPTIONS.E_SIM }
      ]
    }

    return [
      { name: textContent?.haveSim, value: CONSUMER_SIM_OPTIONS.HAVE_SIM },
      { name: textContent?.newSim, value: CONSUMER_SIM_OPTIONS.NEW_SIM }
    ]
  }, [userType, language])

  const defaultSimOption = simType === SIM_OPTIONS.E_SIM ? 1 : 0
  const [haveSim, setHaveSim] = useState(radioOptions[defaultSimOption])

  const updateSimSelectionStore = (newState) => {
    dispatch(updateSimSelectionState(newState))
  }

  const resetSimSelection = (simTypeSelected, cost, pageModule) => {
    updateSimSelectionStore({
      selectSimType: {
        ...simSelectionState.selectSimType,
        currentPageModule: pageModule,
        simType: simTypeSelected,
        cost
      },
      imei: '',
      imeiChecked: false,
      imeiCompatible: false,
      simError: simNumber ? simError : ''
    })
  }

  const resetPSimDetails = (simOption) => {
    if (simOption !== SIM_OPTIONS.P_SIM) {
      updateSimSelectionStore({
        simNumber: '',
        simError: ''
      })
    }
  }

  const resetConsumerPSIMCheck = (pageOption) => {
    resetSimSelection(SIM_OPTIONS.P_SIM, pSimPrice, pageOption)
  }
  const resetConsumerESIMCheck = (pageOption) => {
    resetSimSelection('', 0, pageOption)
  }

  const resetDealerPSIMCheck = (pageOption) => {
    updateSimSelectionStore({
      simError: '',
      selectSimType: {
        currentPageModule: pageOption,
        simType: SIM_OPTIONS.P_SIM,
        cost: pSimPrice
      }
    })
  }

  const resetDealerESIMCheck = (pageOption) => {
    resetSimSelection(SIM_OPTIONS.E_SIM, eSimPrice, pageOption)
  }

  useEffect(() => {
    const pageOption = haveSim?.value
    if (pageOption) {
      if (isDealer) {
        if (pageOption === SIM_OPTIONS.P_SIM) {
          resetDealerPSIMCheck(pageOption)
        } else {
          resetDealerESIMCheck(pageOption)
        }
      } else if (pageOption === CONSUMER_SIM_OPTIONS.HAVE_SIM) {
        resetConsumerPSIMCheck(pageOption)
      } else {
        resetConsumerESIMCheck(pageOption)
      }
    }
  }, [haveSim?.value, userType])

  useEffect(() => {
    if(isDealer && quoteInfo?.SimInformation?.simType  === SIM_OPTIONS.E_SIM.toUpperCase()) {
      setHaveSim(radioOptions[1])
    }
  }, [quoteInfo?.SimInformation?.simType])

  const handleSimBlur = async (setLoading) => {
    const errors = {
      simRequiredError,
      simLengthError,
      simUnavailableError
    }
    const simResult = await SimSelectionChangeHandlers[SIM_SELECTION_EVENTS.SIM].onBlur(
      simSelectionState,
      isSimValid,
      setLoading,
      errors,
      updateSimSelectionStore
    )
    if (simResult) {
      return true
    }
    return false
  }

  const getPlainSimNumber = (simNumberText) => {
    if (!simNumberText) return ''
    return simNumberText.replace(/ - /g, '')
  }

  const updateQuote = async () => {
    const input = { email }
    if (simType === SIM_OPTIONS.P_SIM) {
      input.simType = 'HAVESIM'
      input.simNumber = getPlainSimNumber(simNumber)
    } else {
      input.simType = 'ESIM'
      if (isDealer && eid) {
        input.eid = eid
      }
    }
    try {
      const response = await setPatchQuote(input)
      return response
    } catch (err) {
      console.log('ERROR', err)
      return err
    }
  }

  const proceedToNextStep = async () => {
    try {
      setLandingLogicLoader('IN_PROGRESS')
      if (isComplete) {
        const response = await updateQuote()
        const nextPagePath = isDealer
          ? dealerActivationPaths.ChooseNumber
          : consumerActivationPaths.ChooseNumber
  
        const splashPagePath = isDealer
          ? dealerActivationPaths.Splash
          : consumerActivationPaths.Splash
  
        resetPSimDetails(simType)
  
        if (response?.data?.accountId) {
          setLandingLogicLoader(false)
          history.push(nextPagePath)
        } else {
          setLandingLogicLoader(false)
          history.push(splashPagePath)
        }
      } else {
        let topError
  
        if ((await handleSimBlur(() => {})) && !topError) {
          topError = simRef
        }
  
        if (topError && topError?.current) {
          topError.current.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }
    }
    finally {
      setLandingLogicLoader(false)
    }
  }

  const getPreviousStepLink = () => {
    return isDealer ? dealerActivationPaths.AccountSetup : consumerActivationPaths.AccountSetup
  }

  const handleNextStepClick = async () => {
    const haveSimValue = haveSim.value
    if (haveSimValue === CONSUMER_SIM_OPTIONS.NEW_SIM) {
      if (!imeiChecked) {
        setShowESimCompatibilityWarning(true)
      } else {
        proceedToNextStep()
      }
    } else if (haveSimValue === CONSUMER_SIM_OPTIONS.HAVE_SIM || haveSimValue === SIM_OPTIONS.P_SIM) {
      const hasError = await handleSimBlur(() => {})
      if (!hasError) {
        proceedToNextStep()
      }
    } else {
      proceedToNextStep()
    }
  }

  const handleRadioSelectionClick = (value) => {
    setHaveSim(value)
  }

  const getNextStepLabel = () => {
    if (haveSim.value === CONSUMER_SIM_OPTIONS.NEW_SIM && !imeiCompatible) {
      return textContent?.nextStepESIMLabel
    }

    return textContent?.nextStepPSIMLabel
  }

  const getNextStepIsTextButton = () => {
    return haveSim.value === CONSUMER_SIM_OPTIONS.NEW_SIM && !imeiCompatible
  }

  const getNextStepIsHidden = () => {
    if (isDealer) {
      return haveSim.value === SIM_OPTIONS.E_SIM && !eid
    }
    return (
      !enableProceedWithoutCheckingESim &&
      haveSim.value === CONSUMER_SIM_OPTIONS.NEW_SIM &&
      (simType !== SIM_OPTIONS.E_SIM || (simType === SIM_OPTIONS.E_SIM && !imeiCompatible))
    )
  }

  const closeWarningModal = () => {
    setShowESimCompatibilityWarning(false)
  }

  const handleBuySim = () => {
    closeWarningModal()
    dispatch(
      setSimType({
        simType: SIM_OPTIONS.P_SIM,
        cost: pSimPrice
      })
    )
  }

  const handleProceedWithESim = () => {
    closeWarningModal()
    proceedToNextStep()
    dispatch(
      setSimType({
        simType: SIM_OPTIONS.E_SIM,
        cost: eSimPrice
      })
    )
  }

  return (
    <div className="flex flex-col max-w-4xl px-4 pb-12 m-auto">
      <Heading2>{textContent?.simSectionHeading}</Heading2>
      <SelectSimRadioGroup
        userType={userType}
        onChange={handleRadioSelectionClick}
        heading={textContent.haveSimHeading}
        options={radioOptions}
        selectedOption={haveSim}
      />
      <SimSelectionDetails userType={userType} simSelection={haveSim.value} simRef={simRef} />
      <SimSelectionFooter
        previousStepLabel={textContent?.previousStepLabel}
        previousStepLink={getPreviousStepLink()}
        nextStepLabel={getNextStepLabel()}
        nextStepTextButton={getNextStepIsTextButton()}
        onNextStepClick={handleNextStepClick}
        hideNextStep={getNextStepIsHidden()}
      />
      <Modal
        isOpen={showESimCompatibilityWarning}
        closeModal={closeWarningModal}
        title={textContent?.compatibilityWarningHeader}
      >
        <div className="text-black leading-6 text-sm">
          <Alert
            header={textContent.compatibilityWarningTitle}
            message={<span className="font-body">{textContent?.compatibilityWarningMessage}</span>}
          />
          <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-8">
            <Button
              type="button"
              label={textContent?.buyPhysicalSIM}
              className="w-full rounded-md font-h4"
              onClick={handleBuySim}
              outlined
            />
            <Button
              type="button"
              onClick={handleProceedWithESim}
              label={textContent?.proceedAnyway}
              className="w-full rounded-md font-h4"
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SimSelectionPage
