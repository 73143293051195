import axios from 'axios'
import envConfig from '@platformConfig/index'

const { backendUrl } = envConfig

export const getParams = (id) => {
  return axios
    .post(`${backendUrl}/params`, JSON.stringify({ id }), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .catch((error) => {
      return error
    })
}

export const getHash = (activationNum, timestamp) => {
  return axios
    .post(`${backendUrl}/hash`, JSON.stringify({ activationNum, timestamp }), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .catch(() => {
      return {
        data: ''
      }
    })
}

export const proxy = (service, operation, params) => {
  return axios
    .post(`${backendUrl}/proxy`, JSON.stringify({ service, operation, params }), {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      timeout: envConfig.timeout
    })
    .catch(() => {
      return {
        data: {
          // redirectUrl: '/error-706',
          response: undefined
        }
      }
    })
}


