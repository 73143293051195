import React from 'react'
import PropTypes from 'prop-types'

const CustomTabItem = ({children}) => {

  return (
    <div className="relative w-full focus-visible:outline-none my-2">
    {children}
    </div>
  )
}

CustomTabItem.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool
}

export default CustomTabItem
