/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ModalPopup = ({ children, showPopup = false }) => {
  const content = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    border: 'none',
    overflow: 'auto',
    outline: 'none',
    transform: 'translate(-50%, -50%)'
  }

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showPopup])

  return (
    <>
      {showPopup && (
        <div className="fixed inset-0 mt-0 bg-backdrop" style={{ marginTop: 0, zIndex: 1000 }}>
          <div className="flex">
            <div className="bg-popup w-11/12 lg:w-1/3" style={content}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

ModalPopup.propTypes = {
  children: PropTypes.node.isRequired,
  showPopup: PropTypes.bool
}

export default ModalPopup
