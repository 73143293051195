/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'

const BodyMedium = ({ id, children, className, textColor, font }) => {
  return (
    <p
      className={`${textColor || 'text-copy'} text-medium ${font || 'font-body'} ${className}`}
      id={id}
    >
      {children}
    </p>
  )
}

BodyMedium.propTypes = {
  id: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  textColor: PropTypes.string,
  font: PropTypes.string
}

export default BodyMedium
