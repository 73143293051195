/* eslint-disable import/extensions */
import React, { useContext, useMemo } from 'react'
import SIM from '@apps/components/SIM'
import SIMCardNumber from '@apps/components/SIMCardNumber'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { APPLICATION_STATE, ApplicationContext } from '@apps/contexts/ApplicationContext'
import SimSelectionChangeHandlers, {
  SIM_SELECTION_EVENTS
} from '@apps/utils/ChangeHandlers/SimSelectionChangeHandlers'
import { updateSimSelectionState } from '@apps/redux/features/SimSelectionSlice'
import Box from '@/apps/components/Box'
import { getById, getBySlug } from '@/apps/utils/contentful'

const HaveSimCardDetail = ({ userType, simRef }) => {
  const dispatch = useDispatch()
  const { pageContent } = useSelector((state) => state.common.content)
  const simSelectionState = useSelector((state) => state.simSelectionPage)

  const appState = useContext(ApplicationContext)
  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: language,
    [APPLICATION_STATE.SERVICES]: { isSimValid }
  } = appState

  const haveSimPage = getById(pageContent, 'haveSimPage') || {}
  const haveSimPageContent = haveSimPage[language] || {}
  const { shortTextFields = [] } = haveSimPageContent

  const textContent = useMemo(() => {
    return {
      simRequiredError: getBySlug(shortTextFields, 'simRequiredError')?.value,
      simLengthError: getBySlug(shortTextFields, 'simLengthError')?.value,
      simUnavailableError: getBySlug(shortTextFields, 'simUnavailableError')?.value
    }
  }, [shortTextFields, language])

  const { simRequiredError, simLengthError, simUnavailableError } = textContent

  const updateSimSelectionStore = (newState) => {
    dispatch(updateSimSelectionState(newState))
  }

  const handleSimBlur = async (setLoading) => {
    const errors = {
      simRequiredError,
      simLengthError,
      simUnavailableError
    }
    const simResult = await SimSelectionChangeHandlers[SIM_SELECTION_EVENTS.SIM].onBlur(
      simSelectionState,
      isSimValid,
      setLoading,
      errors,
      updateSimSelectionStore
    )
    if (simResult) {
      return true
    }
    return false
  }

  return (
    <Box border padding={8} className="-mx-7 sm:mx-0">
      <SIM simRef={simRef} handleSimBlur={handleSimBlur} className="pl-2" userType={userType} />
      <SIMCardNumber className="mt-5 pl-2" />
    </Box>
  )
}

HaveSimCardDetail.propTypes = {
  userType: PropTypes.string.isRequired,
  simRef: PropTypes.object.isRequired
}

export default HaveSimCardDetail
